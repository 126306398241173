import React, { useState, useEffect } from 'react'
import withRouter from '../utils/withRouter'
import { NavLink } from "react-router-dom";
import { Select, Popover, Image, Divider, message, Button, Modal, Badge, Spin } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import store from '../utils/redux/index'
import intl from 'react-intl-universal';
import * as Api from '../service/https'
import moment from 'moment'
import toKMB from '../utils/KMB'
import { useRef } from 'react';
import QRCode from 'qrcode.react';

const { Option } = Select;


const Language = (props) => {
    const heightRef = useRef()
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    const [textJson, setTextJson] = useState(intl.get('ReleaseDemand'))
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    const [userMcnInfo, setUserMcnInfo] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [isIndex, setIndex] = useState(false)
    const [visibleVip, setVisibleVip] = useState(false)
    const [visibleUser, setVisibleUser] = useState(false)
    const [list, setList] = useState([])
    const [newHeight, setNewHeight] = useState([])
    const [showIndex, setShowIndex] = useState(-1)
    const [unreadCount, setUnreadCount] = useState(0)
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        // console.log(props)
        setIndex(props.location.pathname.split('/')[1] == 'index')
        if (localStorage.getItem('token') != 'null' && localStorage.getItem('token') != null && props.location.pathname.split('/')[1] != 'login') {
            getusers()
            // informationSearch()
        }
    }, [state.news.unreadCount])
    const getusers = ()=>{
        Api.infoP().then(res => {
            if (res.code == 200) {
                setUserInfo(res.data)
                store.dispatch({ type: 'userInfo', value: res.data })
                // if(res.code == 200){
                //     message.info(intl.get('NewLogin')['请完善信息!'])
                //     props.navigate('/login',{state: {isBind: true}})
                // }

            }
        })
    }
    const [mcnDetail, setMcnDetail] = useState(null);
    // 获取邀请列表
    const getMcn_invitation = () => {
        Api.getMcn_invitation().then(res => {
            if (res.code == 200) {
                setMcnDetail(res.data.length>0?res.data[0]:null)
            }
        })
    };
    // 接受或拒绝邀请
    const getMcn_mark = (bools) => {
        Api.getMcn_mark({ mcnCode: mcnDetail.mcnCode, status: bools ? 2 : 3 }).then(res => {
            if (res.code == 200) {
                setMcnDetail(null)
                getusers()
            }
        })
    }


    const [shareCodes, setShareCodes] = useState()
    const [isShowShare, setIsShowShare] = useState(false)
    const [isShowShareSucc, setIsShowShareSucc] = useState(false)
    const copyText = () => {
        var val = document.getElementById('copyVal');
        window.getSelection().selectAllChildren(val);
        document.execCommand("Copy");
        setIsShowShareSucc(true)
    }
    const generateCode =()=>{
        Api.generateCode().then(res=>{
            if(res.code == 200){
                setShareCodes(res.data)
                setIsShowShare(true)
            }
        })
    }
    const addImg =()=>{
        //先获取要下载的二维码
        let Qr=document.getElementById('qrCode');
        //把canvas的数据改成base64的格式
        let canvasUrl = Qr.toDataURL('image/png')
        let myBlob = dataURLtoBlob(canvasUrl);
        let myUrl = URL.createObjectURL(myBlob) //创建blob下载地址
        downloadFile(myUrl, intl.get('Common')['邀请二维码'])
    }
    //图片下载
    const downloadFile = (url, name)=>{
        //创建a标签用于下载
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', name);
        a.setAttribute('target', '_blank');
        let clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent('click', true, true); //模拟点击
        a.dispatchEvent(clickEvent);
    }
    //base64转文件
    const dataURLtoBlob = (dataurl)=>{
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }


    useEffect(() => {
        if (state.userInfo) setUserInfo(state.userInfo)
    }, [state.userInfo])

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };

    useEffect(() => {
        if (visible) {
            setNewHeight(document.getElementById('notic_box').clientHeight - 10)
        }
    }, [visible])

    const informationSearch = e => {
        Api.informationSearch().then(res => {
            if (res.code === 200) {
                setList(res.data.informationList || [])
                setUnreadCount(res.data.unreadCount || 0)
                store.dispatch({ type: 'news', value: {unreadCount: res.data.unreadCount || 0} })
            }
        })
    }


    //切换中英文
    const changeLang = (val) => {
        store.dispatch({ type: 'language', value: val })
        localStorage.setItem('locale', val)
        window.location.reload()
    }

    const updateStatus = (item, index) => {
        if (index === showIndex)
            setShowIndex(-1)
        else {
            setShowIndex(index)
            if (item.readStatus === 2) {
                let data = {
                    id: item.id,
                    informationType: item.informationType,
                    readStatus: 1
                }
                Api.updateStatus(data).then(res => {
                    informationSearch()
                })
            }
        }

    }

    const updateStatusBatch = e => {
        Api.updateStatusBatch().then(res => {
            informationSearch()
        })
    }

    const notice = <div style={{ width: '450px', padding: '14px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '4px', height: '22px', backgroundColor: '#F7385B', marginRight: '8px' }}></div>
                <div style={{ lineHeight: '20px', color: '#333333', fontWeight: '800', fontSize: '16px' }}>{textJson['消息中心']}</div>
            </div>
            <div style={{ cursor: 'pointer' }} onClick={e => updateStatusBatch()}>{textJson['清除未读']}（{unreadCount ? unreadCount : 0}）</div>
        </div>
        <div id='notic_box' ref={heightRef} style={{ overflow: 'auto', maxHeight: '400px', position: 'relative' }}>
            {list.map((item, index) =>
                <div key={index} style={{ display: 'flex', position: 'relative', padding: '0 2px' }}>
                    <div style={{ width: '117px', color: '#666666' }}>{item.createTime.slice(0, 10)}</div>
                    <div onClick={e => updateStatus(item, index)} style={{ cursor: 'pointer', width: '300px' }}>
                        <div style={{
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#333333',
                            fontWeight: '600'
                        }}>【{item.informationType === 1 ? textJson['系统更新'] : item.informationType === 2 ? textJson['合作申请'] : textJson['合作通知']}】{lang === 'zh' ? item.title : item.titleEn || item.title}</div>
                        <div style={{ display: showIndex === index && item.content ? '' : 'none', backgroundColor: '#F6F6F6', margin: '5px', padding: '10px', borderRadius: '5px' }} dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    </div>
                    <div style={{ backgroundColor: item.readStatus === 1 ? '#CCCCCC' : '#F7385B', width: '7px', height: '7px', borderRadius: '6px', position: 'absolute', top: 9, left: 91, zIndex: '1' }}></div>
                </div>
            )}
            <div style={{ display: 'block', backgroundColor: '#CCCCCC', width: '1px', height: newHeight, position: 'fixed', top: 180, left: userInfo?.userType === 1 ? 1322 : 1378 }}></div>
        </div>
    </div >

    const McnContent = () => {
        return <div style={{ width: '225px', height: '70px', padding: '15px 24px' }}>
            <div style={{
                width: '192px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                color: '#666', margin: '0px auto', border: '1px solid #EEEEEE', fontWeight: 'blod',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
                onClick={() => logouts()}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/tuichudenglu.png'} style={{ width: '16px', marginRight: '8px' }} alt="" />
                {intl.get('ReleaseDemand')['退出账号登录']}
            </div>
        </div>
    }
    const VipContent = () => {
        return <div style={{ width: '325px', height: '510px', padding: '15px 24px' }}>
            <div>
                <Image src={state.userInfo.avatar || userInfo?.avatar || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} preview={false}
                    style={{ width: '54px', height: '54px', borderRadius: '27px', marginRight: '14px', cursor: 'pointer' }} />
                <span style={{ fontWeight: 'bold' }}>{userInfo?.phone}</span>
            </div>
            {userInfo?.memberStatus == 0 ? <div style={{ width: '100%', position: 'relative', marginTop: '20px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/weiB.png'} alt=""
                    style={{ width: '100%', height: '86px' }} />
                <div style={{
                    position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 17px'
                }}>
                    <span style={{ color: '#666666' }}>{textJson['暂未开通会员']}</span>
                    <div style={{
                        width: '86px', height: '28px', lineHeight: '28px', fontWeight: 500, borderRadius: '14px',
                        background: '#FFF', textAlign: 'center', cursor: 'pointer'
                    }}
                        onClick={() => props.navigate('/index/member')}>
                        {textJson['升级会员']}
                    </div>
                </div>
            </div> :
                <div style={{ width: '100%', position: 'relative', marginTop: '20px' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/' + (userInfo?.memberDetail?.memberType == '领航版' ? 'qjB' : userInfo?.memberDetail?.memberType == '远航版' ? 'gjB' : userInfo?.memberDetail?.memberType == '起航版' ? 'cxB' : 'cxB') + '.png'} alt=""
                        style={{ width: '100%', height: '86px' }} />
                    <div style={{
                        position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
                        display: 'flex', alignItems: 'center',
                    }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/' + (userInfo?.memberDetail?.memberType == '领航版' ? 'v3' : userInfo?.memberDetail?.memberType == '远航版' ? 'v2' : userInfo?.memberDetail?.memberType == '起航版' ? 'v1' : 'v1') + '.png'} alt=""
                            style={{ width: '48px', marginLeft: '14px', marginRight: '9px' }} />
                        <div style={{ fontWeight: 'bold' }}>{intl.get('member')[userInfo?.memberDetail?.memberType]}
                            <div style={{ color: '#666666', fontSize: '12px', fontWeight: 400 }}>{intl.get('member').expiryDate}{moment(userInfo?.memberDetail.date).format('YYYY-MM-DD')}</div>
                        </div>
                    </div>
                </div>}
            <div style={{ width: '100%', height: '66px', borderTop: '1px solid #EEEEEE', display: 'flex', alignItems: 'center', marginTop: '25px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hz.png'} style={{ width: '16px' }} alt="" />
                <NavLink to={'/index/videoDemand'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px', marginRight: '25px' }}>{intl.get('ReleaseDemand')['短视频需求']}</NavLink>
                <NavLink to={'/index/LivingDemand'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500 }}>{intl.get('ReleaseDemand')['直播需求']}</NavLink>
            </div>
            <div style={{ width: '100%', height: '100px', borderTop: '1px solid #EEEEEE', display: 'flex', alignItems: 'center' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scd.png'} style={{ width: '16px', marginTop: '-38px' }} alt="" />
                <div>
                    <NavLink to={'/index/myCollection'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px', marginRight: '26px' }}>{intl.get('Workbench').MyBlogger}</NavLink>
                    <NavLink to={'/index/myCollection'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500 }}>{intl.get('Workbench').MyVideos}</NavLink>
                    <div style={{ marginTop: '15px' }}>
                        <NavLink to={'/index/myCollection'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px' }}>{intl.get('Workbench').MyTopic}</NavLink>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%', height: '66px', borderTop: '1px solid #EEEEEE', borderBottom: '1px solid #EEEEEE',
                display: 'flex', alignItems: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user.png'} style={{ width: '16px' }} alt="" />
                <NavLink to={'/index/Account'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px', marginRight: '40px' }}>{intl.get('Workbench').AccountManagement}</NavLink>
                <NavLink to={'/index/EnterpriseCertification'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500 }}>{intl.get('Workbench').EnterpriseCertification}</NavLink>
            </div>
            <div style={{
                width: '192px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                color: '#666', margin: '23px auto', border: '1px solid #EEEEEE', fontWeight: 'blod',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
                onClick={() => logouts()}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/tuichudenglu.png'} style={{ width: '16px', marginRight: '8px' }} alt="" />
                {intl.get('ReleaseDemand')['退出账号登录']}
            </div>
        </div>
    }
    const BloggerContent = () => {
        return <div style={{ width: '325px', height: '610px', padding: '15px 24px' }}>
            <div style={{ textAlign: 'center' }}>
                <Image src={userInfo?.avatar || userInfo?.redAccountDetail?.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} preview={false}
                    style={{ width: '73px', height: '73px', borderRadius: '36.6px', cursor: 'pointer' }} />
                <div>
                    <div className='over' style={{ fontWeight: 'bold', fontSize: '16px' }}>{userInfo?.redAccountDetail?.nickname}</div>
                    <div style={{ fontSize: '12px', color: '#666666' }}>TikTok: {userInfo?.redAccountDetail?.tiktok}</div>
                </div>
            </div>
            <div style={{ fontSize: '12px', color: '#F7385B', display: 'flex', marginTop: '13px', justifyContent: 'center' }}>
                {
                    (lang == 'zh' ? userInfo?.redAccountDetail?.cargoClassificationInterpretation : userInfo?.redAccountDetail?.cargoClassificationInterpretationEn)?.split(',')?.map((val, index) => {
                        return <span className='over1' key={index} style={{ background: '#FFE7E9', padding: '1px 10px', borderRadius: '15px', marginRight: '6px' }}>{val}</span>
                    })
                }
            </div>
            <div style={{ width: '100%', marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{toKMB(userInfo?.redAccountDetail?.followerCount, 2)}</div>
                    <div style={{ textAlign: 'center', color: '#888888', marginTop: '2px' }}>
                        {intl.get('BloggerRanking').FansNum}
                    </div>
                </div>
                <Divider type="vertical" style={{ height: 38 }} />
                <div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>${userInfo?.redAccountDetail?.videoQuotationDollar}/60s</div>
                    <div className='over1' style={{ textAlign: 'center', color: '#888888', marginTop: '2px' }}>
                        {intl.get('Index')['视频单价']}
                    </div>
                </div>
                <Divider type="vertical" style={{ height: 38 }} />
                <div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>${userInfo?.redAccountDetail?.liveBroadcastQuotationDollar}/h</div>
                    <div className='over1' style={{ textAlign: 'center', color: '#888888', marginTop: '2px' }}>
                        {intl.get('Index')['直播单价']}
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '100px', borderTop: '1px solid #EEEEEE', display: 'flex', alignItems: 'center', marginTop: '25px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hz.png'} style={{ width: '16px', marginTop: '-38px' }} alt="" />
                <div>
                    <NavLink to={'/index/demandHall'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px', marginRight: '25px' }}>{intl.get('ReleaseDemand')['需求大厅']}</NavLink>
                    <NavLink to={'/index/videoDemand'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500 }}>{intl.get('ReleaseDemand')['短视频需求']}</NavLink>
                    <div style={{ marginTop: '15px' }}>
                        <NavLink to={'/index/LivingDemand'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px' }}>{intl.get('ReleaseDemand')['直播需求']}</NavLink>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '100px', borderTop: '1px solid #EEEEEE', display: 'flex', alignItems: 'center' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/scd.png'} style={{ width: '16px', marginTop: '-38px' }} alt="" />
                <div>
                    <NavLink to={'/index/myCollection'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px', marginRight: '26px' }}>{intl.get('Workbench').MyBlogger}</NavLink>
                    <NavLink to={'/index/myCollection'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500 }}>{intl.get('Workbench').MyVideos}</NavLink>
                    <div style={{ marginTop: '15px' }}>
                        <NavLink to={'/index/myCollection'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px' }}>{intl.get('Workbench').MyTopic}</NavLink>
                    </div>
                </div>
            </div>
            <div style={{
                width: '100%', height: '66px', borderTop: '1px solid #EEEEEE', borderBottom: '1px solid #EEEEEE',
                display: 'flex', alignItems: 'center'
            }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/user.png'} style={{ width: '16px' }} alt="" />
                <NavLink to={'/index/influencerAccount'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500, marginLeft: '34px', marginRight: '40px' }}>{intl.get('Workbench').AccountManagement}</NavLink>
                <NavLink to={state.userInfo?.redAccountDetail.mcnCode ? '/index/myMcnWallet':'/index/myWallet'} onClick={e => setVisibleUser(false)} style={{ color: '#333', fontWeight: 500 }}>{intl.get('Wallet')['我的钱包']}</NavLink>
            </div>
            <div style={{
                width: '192px', height: '38px', borderRadius: '4px', cursor: 'pointer',
                color: '#666', margin: '28px auto', border: '1px solid #EEEEEE', fontWeight: 'blod',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
                onClick={() => logouts()}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/tuichudenglu.png'} style={{ width: '16px', marginRight: '8px' }} alt="" />
                {intl.get('ReleaseDemand')['退出账号登录']}
            </div>
        </div>
    }

    const logouts = () => {
        Api.logout().then(res => {
            if (res.code == 200) {
                props.navigate('/login')
                window.location.reload()
                localStorage.setItem("token", null)
                store.dispatch({ type: 'token', value: null })
                store.dispatch({ type: 'userInfo', value: null })
            }
        })
    }


    const goToRelese = () => {
        props.navigate('/index/releaseDemand')
        // if(state.userInfo?.enterprise){
        //     props.navigate('/index/releaseDemand')
        // }else{
        //     message.warn("请先进行企业认证！")
        //     props.navigate('/index/EnterpriseCertification')
        // }
    }
    const [isFlys, setIsFlys] = useState(false)

    return <div id='language' style={{ display: 'flex', alignItems: 'center', letterSpacing: '1px', fontWeight: 500 }}>
        {/*<div onClick={()=>props.navigate('/loginTab')} style={{width:'98px', height:'34px',*/}
        {/*    border:'1px solid #F7F7F7',*/}
        {/*    borderRadius:'6px', color: '#FFFFFF', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}>*/}
        {/*    卖家课堂*/}
        {/*</div>*/}
        {/*<div style={{ height: '30px', borderRight: '1.3px solid #888888', marginRight: '36px', marginLeft:'36px' }}></div>*/}
        <div style={{ maxWidth: '235px', minWidth: '142px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Popover
                getPopupContainer={() => document.getElementById('language')} placement="bottom"
                content={
                    userInfo?.userType == 2 ? <BloggerContent /> : userInfo?.userType == 1 ? <VipContent /> : <McnContent/>
                }
                title=""
                trigger="click"
                visible={visibleUser && userInfo}
                onVisibleChange={e => setVisibleUser(e)}
            >
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color:'#FFFFFF' }} onClick={() => userInfo ? '' : props.navigate('/login')}>
                    <Image src={userInfo?.avatar || userInfo?.redAccountDetail?.avatarMedium || userMcnInfo?.logo || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} preview={false}
                        style={{ width: '38px', height: '38px', borderRadius: '19px', marginRight: '10px' }} />
                    {userInfo || userMcnInfo ?<span>{userInfo.phone}</span>:''}
                    {userInfo || userMcnInfo ?<CaretDownOutlined style={{ marginRight: '36px', marginLeft: '10px' }} />:''}
                </div>
            </Popover>
            {!userInfo ? <span style={{ color: '#F7385B', textDecoration: 'underline', marginRight: '7px', cursor: 'pointer', letterSpacing: '0', width: '50px', }}
                onClick={() => props.navigate('/login')}>{intl.get('Header').Login}</span> : ''}
        </div>
        {/*<div style={{ height: '30px', borderRight: '1.3px solid #888888', marginRight: '25px' }}></div>*/}
        {/*<Select style={{ width: '110px' }} value={store.getState().language === 'zh' ? intl.get('Chinese') : intl.get('English')} onChange={e => changeLang(e)} bordered={false}*/}
        {/*    getPopupContainer={() => document.getElementById('language')}>*/}
        {/*    {*/}
        {/*        store.getState().language === 'en' ?*/}
        {/*            <Option value="zh">{intl.get('Chinese')}</Option>*/}
        {/*            :*/}
        {/*            <Option value="en">{intl.get('English')}</Option>*/}
        {/*    }*/}
        {/*</Select>*/}
        {/*<CaretDownOutlined style={{ marginLeft: '-32px' }} />*/}


        <div id='login' style={{position:'relative', zIndex:10000}}>
            <Modal centered visible={mcnDetail && mcnDetail?.status == 1} footer={null} closable={false}
                bodyStyle={{
                    padding: '0', backgroundColor: '#FFFFFF',
                    padding: '70px 90px', width: 557
                }}
            >
                <div style={{ fontSize: '20px', fontWeight: 'bold', lineHeight:'20px', textAlign: 'center' }}>{['机构绑定提示']}</div>
                <div style={{fontSize:'16px', color:'#666666', margin:'50px 0', textAlign:'center'}}>MCN机构“{mcnDetail?.realName}”正在尝试与你的账号绑定，请你确认!</div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div onClick={()=>getMcn_mark(false)} style={{
                        width: '168px', height: '40px', border:'1px solid #F7385B',
                        textAlign: 'center', lineHeight: '40px', color: '#F7385B', borderRadius: '4px',
                        cursor: 'pointer', letterSpacing: '1px', marginRight:'11px'
                    }}>{intl.get('ReleaseProducts')['拒绝绑定']}</div>
                    <Button onClick={()=>getMcn_mark(true)} style={{
                        width: '168px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                        color: '#FFF', borderRadius: '4px', borderRight:'4px', letterSpacing: '0px', border:0
                    }}>{intl.get('ReleaseProducts')['同意绑定']}</Button>
                </div>
            </Modal>

            <Modal visible={isShowShare} centered={true} footer={null} onCancel={e => { setIsShowShare(false); setIsShowShareSucc(false) }} maskClosable={false}
                width={590}>
                    <div style={{width:'540px', textAlign:'center'}}>
                        <div style={{fontSize:'20px', fontWeight:'bold', marginTop:'20px'}}>{intl.get('Common')['邀请好友注册']}</div>
                        <div style={{display:'flex', height:'40px', marginTop:'37px'}}>
                            <div id="copyVal" style={{width:'100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
                            borderRadius:'4px 0 0 4px', color:'#333333', padding:'0 10px', border: '1px solid #EEEEEE',}}
                            >
                                https://www.brandgogo.com?shareCode={shareCodes}
                            </div>
                            <div style={{width:'130px', background:isShowShareSucc?'linear-gradient(180deg, #4FDA2F, #A2EB87)':'linear-gradient(180deg, #F7385B 0%, #FF716E 100%)',
                            borderRadius:'0 4px 4px 0', lineHeight:'40px', color:'#FFF', cursor:'pointer'}}
                            onClick={copyText}>{isShowShareSucc ? intl.get('Common')['复制成功'] : intl.get('Common')['复制邀请链接']}</div>
                        </div>
                        <div style={{width:'100%', textAlign:'center', marginTop:'30px'}}>
                            <Spin tip={intl.get('Common')['加载中']} spinning={!shareCodes}>
                                {shareCodes?<QRCode id="qrCode"
                                    value={`https://www.brandgogo.com?shareCode=${shareCodes}`}
                                    size={144}
                                    fgColor="#000000"
                                />:<div style={{height:'144px'}}></div>}
                            </Spin>
                            {/* <img id='addImg' src="" style={{width:'144px', height:'144px'}} /> */}
                            <div style={{display:'inline-block', padding:'0 20px', background:'linear-gradient(180deg, #F7385B 0%, #FF716E 100%)',
                            borderRadius:'4px', lineHeight:'40px', color:'#FFF', cursor:'pointer', margin:'10px auto'}}
                            onClick={addImg}>{intl.get('Common')['保存邀请二维码']}</div>
                        </div>
                        <p style={{color: '#333333', fontSize:'12px', marginTop:'30px'}}>{intl.get('Common')['邀请好友注册，复制邀请链接或保存邀请二维码，二选一即可']}</p>
                    </div>
            </Modal>
        </div>
    </div>
}
export default withRouter(Language);
