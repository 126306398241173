//1、引入axios
import axios from 'axios'
import qs from 'qs';
import {
    message
} from 'antd';

// 正式和测试接口不可修改
// const baseUrl = true ? '/api' : 'https://www.iyw360.com'
const baseUrl = 'https://www.brandgogo.com/api'   // 正式环境的接口
// const baseUrl = 'http://192.168.20.231:9600/api'
// const baseUrl = 'http://192.168.1.211:9800/api'
// const baseUrl = 'http://192.168.1.241:9605/api'      // 测试环境的接口
// const baseUrl = 'http://192.168.1.241:9604/api'
// const baseUrl = 'http://192.168.1.241:9603/api'
// const baseUrl = 'http://192.168.10.9:9600/api'
// const baseUrl = 'http://192.168.1.140:9800/api'

const tkBaseUrl = 'https://www.brandgogo.com/api'
// const tkBaseUrl = 'http://192.168.1.140:9600/tiktok'

//2、创建axios的实例
let httpService = axios.create({
    baseURL: baseUrl,
    timeout: 300000
})

//3、axios的拦截--request
httpService.interceptors.request.use(config => {
    // 请求成功处理
    if (localStorage.getItem('token')) { //判断浏览器中的cookie中是否存在项目的token
        const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
        config.headers['x-token'] = localStorage.getItem('token')
        config.headers['language'] = lang == 'zh' ? 'zh-cn' : 'en-us'
    }
    return config;
}, err => {
    Promise.reject(err); // 请求错误处理
})

//4、axios的拦截--response
httpService.interceptors.response.use(response => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") || 'zh'
    // TODO:具体的code对应的处理可继续添加修改
    if (response.data.code != 200 && response.data.code != 20007
        && response.data.code != 40008 && response.data.code != 40009 && response.data.code != 40010
        && response.data.code != 40021 && response.data.code != 20014) {
        console.log(response.config.url.includes('market/blogger/list/detail'));
        if(response.config.url && response.config.url.includes('market/blogger/list/detail')) message.info(lang == 'zh' ? '数据不存在！' : 'Data does not exist!')
        else message.info(lang == 'zh' ? response.data.message : response.data.message_en)
    }
    if (response.data.code === 401 ) {
        window.location.href = '/login'
        message.info(lang == 'zh' ? '已用其他身份登录！' : 'Logged in with another identity!')
    }
    if (response.data.code === 20007 ) {
        let t = window.location.href.split('/')
        localStorage.setItem('token', 'null'); //清除token
        if (t[t.length - 1] && t[t.length - 1] !== 'login')
            window.location.href = '/login'
    }
    return response;
}, err => {
    // console.log(err)
    // TODO:具体的code对应的处理可继续添加修改
    if (err.response.code === 301) {
        console.log('登录过期');
        //登录过期跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 ---主页(index) 或者 退出到登录前的浏览的页面
        //这样登录页面登录接口成功之后可以进行跳转 主页(index) 或者 退出到登录前的页面： let path = this.$route.query.redirect || '/index.js';   this.$router.push(path);

        setTimeout(() => {
            window.location.href = '/login'
        }, 1000);
        localStorage.setItem('token', null); //清除token
    }
    if (err.response.code === 500) {
        console.log('请联系管理员');
    }
    return Promise.reject(err);
})

//5、get请求的封装
const get = (url, params = {}, headers = {
    'Content-Type': 'application/json'
}) => {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'get',
            params: params,
            headers: headers
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

//6、post请求封装  默认json格式:'Content-Type':'application/json',如果是文件上传，可以修改headers为 headers: { 'Content-Type': 'multipart/form-data' }
const post = (url, datas = {}, params = {}, headers = {
    'Content-Type': 'application/json'
}) => {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: datas,
            params: params,
            headers: headers
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}
//put
const put = (url, datas = {}, headers = {
    'Content-Type': 'application/json'
}) => {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'put',
            data: datas,
            headers: headers
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

const del = (url, datas = {}, headers = {
    'Content-Type': 'application/json'
}) => {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'delete',
            data: datas,
            headers: headers
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    })
}

const DownloadFile = (url, data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            baseURL: baseUrl,
            url,
            responseType: 'blob',
            data: data,
            headers: {
                'x-token': localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const tkGet = (url, data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            baseURL: tkBaseUrl,
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const tkPost = (url, data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            baseURL: tkBaseUrl,
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

//7、将模块暴露
export {
    get,
    post,
    put,
    del,
    DownloadFile,
    tkGet,
    tkPost,
    baseUrl
}
