import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import { Anchor, Input, Checkbox, Radio, DatePicker, Popover, Upload, Image, message, Modal, Button, Select, Drawer, Spin} from 'antd';
import { DeleteOutlined, PlusCircleFilled, CloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Api from '../../../service/https'
import intl from 'react-intl-universal';
import {baseUrl} from '../../../service/request'
// import geolocation from '../../../utils/geolocation'
import three from '../../../utils/three'
import {debounce} from '../../../utils/debounce'
import Enum from '../../../utils/Enum/Enum'

const { Option } = Select;
const { Link } = Anchor;
const { TextArea } = Input;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const VideoDemand = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('ReleaseDemand'))
    const [textJsonE, setTextJsonE] = useState(intl.get('EnterpriseCertification'))
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    useEffect(()=>{
        // console.log(props)
        getCargo()
        getCountry()
        if(props.location?.state?.blogger){
            setBloggerItem(props.location.state.blogger)
            changeObj('sendWay', 1)
        }
    },[])

    const [classList, setClassList] = useState([])
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                setClassList(all)
            }
        })
    }
    useEffect(()=>{
        if(props.location?.state?.target) getMarketDetail()
    },[classList])

    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                setGeolocation(all)
            }
        })
    }
    
    
    const [videoTime, setVideoTime] = useState([60, 120, 180, 240, 300])
    const videoTimeChange = (value, e)=>{
        // console.log(value)
        changeReq('duration', value)
    }
    const [liveTime, setLiveTime] = useState([1,2,3,4,5])
    const liveTimeChange = (value, e)=>{
        // console.log(value)
        changeReq('durationH', value)
    }
    



    const [links, setLinks] = useState([textJson['需求目标'], textJson['媒体传播'], textJson['内容形式'], textJson['发单模式'], textJson['结算方式'], textJson['基本信息'], textJson['制作要求'], textJson['提交需求']])
    const [linkId, setLinkId] = useState('')
    const onChangeLink = e =>{
        setLinkId(e)
    }
    const [isRelease, setRelease] = useState(false)
    const [isHotR, setIsHotR] = useState(false)
    const [isLive, setLive] = useState(false)
    const [otherList, setOtherList] = useState([''])
    const otherPut = (e, index)=>{
        let list = JSON.parse(JSON.stringify(otherList))
        list[index] = e.target.value
        setOtherList(list)
    }
    const addOther = index=>{
        setOtherList([...otherList, ...['']])
    }
    const delOther = index=>{
        let v = JSON.parse(JSON.stringify(otherList))
        v.splice(index, 1)
        setOtherList(v)
    }
    const [forcesList, setForcesList] = useState([])
    const putForces = (e, key)=>{
        // console.log(e.target.checked, key)
        let v = JSON.parse(JSON.stringify(forcesList))
        if(e.target.checked){
            v.push(key)
            setForcesList(v)
        }else{
            let index = v.indexOf(key)
            // console.log(index)
            if(index > -1){
                v.splice(index, 1)
            }
        }
        setForcesList(v)
    }
    
    const [isHave, setHave] = useState(true)
    
    const [startEndTime, setStartEndTime] = useState([])
    useEffect(()=>{
        if(startEndTime.length>0)marketSubmit(false)
    },[startEndTime])
    const changeTime = value=>{
        // console.log(value)
        if(value){
            var from = moment(value[0]._d).format("YYYY-MM-DD HH:mm:ss")
            var to = moment(value[1]._d).format("YYYY-MM-DD HH:mm:ss")
            // console.log(from, to)
            setStartEndTime([from, to])
        }else{
            setStartEndTime([])
        }
    }
    const changePostTime = value=>{
        var time = ''
        if(value){
            time = moment(value).format("YYYY-MM-DD")
        }
        objSub.cooperationWay == 1 ? 
        changeReq('bloggerPubDeadline', time ? time+' 23:59:59' : '') :
        changeReq('bloggerPubTime', time ? time+' 23:59:59' : '')
    }
    const changeValidTime = value=>{
        var time = ''
        if(value){
            time = moment(value).format("YYYY-MM-DD")
        }
        changeReq('bloggerApplicationTimeTo', time ? time+' 23:59:59' : '')
    }


    const inputValue = e=>{
        console.log(e.target.value.split('\n'))
        changeReq('demos', e.target.value)
    }
    
    
    const timeList = [-1, 7, 15, 30, 60, 0]
    const [timeIndex, setTimeIndex] = useState(-1)
    const [timeNum, setTimeNum] = useState('')
    const [visible, setVisible] = useState(false)
    const [checkedTime, setCheckedTime] = useState(false)
    const handleVisibleChange = (visible, index) => {
        setVisible(visible)
        setTimeIndex(index)
    };
    const funCheckedTime = (bool) => {
        setCheckedTime(bool)
        setVisible(false)
        if(bool){
            setTimeIndex(5)
            changeReq('retainDay', timeNum)
        }
    }
    useEffect(()=>{
        if(timeIndex > -1){
            changeReq('retainDay', timeIndex==5 ? timeNum: timeList[timeIndex])
        }
    },[timeIndex])


    const [loading, setLoading] = useState(true)
    const fileListsLogo = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type.indexOf('image') > -1
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange({ file, fileList }) {
            if (file.status === 'uploading') {
                if(loading){
                    message.loading(`上传中...`, 0);
                    // console.log(file, fileList);
                }
                setLoading(false)
            }
            if (file.status === 'done') {
                // setTimeout(() => {
                    changeInfo('logo', file.response.data)
                    message.destroy()
                    setLoading(true)
                    message.success(`${file.name}上传成功！`);
                // }, 1000);
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                message.error(`${file.name}上传失败！`);
            }
        }
    };
    const [imageList, setImageList] = useState([])
    const fileLists = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type.indexOf('image') > -1
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange({file, fileList}) {
            if (file.status === 'uploading') {
                if(loading){
                    message.loading(`上传中...`, 0);
                }
                setLoading(false)
            }
            if (file.status === 'done') {
                // console.log(file, fileList);
                var list = information.imgs
                list.push(file.response.data)
                
                // setTimeout(() => {
                    changeInfo('imgs', list)
                    message.destroy()
                    setLoading(true)
                    message.success(`${file.name}上传成功！`);
                // }, 1000);/
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                message.error(`${file.name}上传失败！`);
            }
        }
    };
    const delImg = (e,index)=>{
        e.stopPropagation();
        let obj = JSON.parse(JSON.stringify(information))
        // console.log(obj)
        obj.imgs.splice(index, 1)
        setInformation(obj)
    }
    const delLogo = (e)=>{
        e.stopPropagation();
        let obj = JSON.parse(JSON.stringify(information))
        // console.log(obj)
        obj.logo = ''
        setInformation(obj)
    }
    const [videoList, setVideoList] = useState([])
    const [videoIndex, setVideoIndex] = useState(-1)
    const videoLists = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/video/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type == 'video/mp4'
            if (!isJpgOrPng) {
                message.error(['请上传正确的视频！']);
            }
            const isLt2M = file.size / 1024 / 1024 < 200;
            if (!isLt2M) {
                message.error(['视频过大，请上传200MB以下的视频!']);
            }
            return isLt2M && isJpgOrPng;
        },
        onChange({ file, fileList }) {
            if (file.status === 'uploading') {
                if(loading){
                    message.loading(`上传中...`, 0);
                }
                setLoading(false)
            }else if (file.status === 'done') {
                console.log(file, fileList);
                var list = information.videos
                list.push(file.response.data)
                // setTimeout(() => {
                    changeInfo('videos', list)
                    message.destroy()
                    setLoading(true)
                    message.success(`${file.name}上传成功！`);
                // }, 2000);
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                message.error(`${file.name}上传失败！`);
            }
        }
    };
    const delVideo = (e,index)=>{
        e.stopPropagation();
        let obj = JSON.parse(JSON.stringify(information))
        obj.videos?.splice(index, 1)
        setInformation(obj)
    }
    const [defaultFileList, setDefaultFileList] = useState(null)
    const fileListsTip = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/file/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        defaultFileList: defaultFileList,
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 50;
            if (!isLt2M) {
                message.error(['文件过大，请上传50MB以下的文件!']);
            }
            return isLt2M;
        },
        onChange({ file, fileList }) {
            fileFun(file, fileList)
        }
    };
    const fileListsTips = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/file/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 50;
            if (!isLt2M) {
                message.error(['文件过大，请上传50MB以下的文件!']);
            }
            return isLt2M;
        },
        onChange({ file, fileList }) {
            fileFun(file, fileList)
        }
    };
    const fileFun = (file, fileList)=>{
        if (file.status === 'uploading') {
            if(loading){
                message.loading(`上传中...`, 0);
                // console.log(file, fileList);
            }
            setLoading(false)
        }
        if (file.status === 'done') {
            // {name:'', url:''}
            // console.log(fileList)
            var list = []
            fileList?.map(item=>{
                list.push({name: item.name, url: item.response.data})
            })
            setTimeout(() => {
                changeReq('tipFiles', list)
                message.destroy()
                setLoading(true)
                message.success(`${file.name}上传成功！`);
            }, 1000);
        }
    }
    
    // 
    let obj_Sub = {
        target: 3, media: 1, cooperationWay: 1, sendWay: 2, asettleWay: 0,
        costFrom: '', costTo: '', operationCode: 0,
        listId: '', demandId: ''
    }
    const [objSub, setObjSub] = useState(obj_Sub)
    const changeObj = (key, val) =>{
        let obj = JSON.parse(JSON.stringify(objSub))
        obj[key] = val
        setObjSub(obj)
        // if(key == 'sendWay'){
        //     let tab = JSON.parse(JSON.stringify(links))
        //     // tab[4] = val ==1 ? textJson['指定红人'] : textJson['红人要求']
        //     setLinks(tab)
        // }
    }
    // 基本信息
    let information_s = {
        brand: '', biz: '', intro: '', imgs: [], videos: []
    }
    const [information, setInformation] = useState(information_s)
    const changeInfo = (key, val) =>{
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInformation(obj)
    }
    // 制作要求
    let requirement_s = {
        tips: '', tipFiles: [], sendSample: 1, bloggerApplicationTimeFrom: '',
        bloggerApplicationTimeTo: '', 
    }
    const [requirement, setRequirement] = useState(requirement_s)
    const changeReq = (key, val) =>{
        let obj = JSON.parse(JSON.stringify(requirement))
        obj[key] = val
        setRequirement(obj)
    }
    useEffect(()=>{
        if(objSub.asettleWay == 1) changeReq('sendSample', 1)
    },[objSub.asettleWay])
    // 红人要求
    let red_info = {
        cargoClassification: '', nationality: undefined, followerCountFrom: '', followerCountTo: '', intelligent: 1,
        cargoClassificationInterpretation: []
    }
    const [redInfo, setRedInfo] = useState(red_info)
    const changeRedInfo = (key, val) =>{
        console.log(key, val)
        let obj = JSON.parse(JSON.stringify(redInfo))
        obj[key] = val
        setRedInfo(obj)
    }


    const [result, setResult] = useState()
    const marketSubmit = (bools = true)=>{
        // if(objSub.target == 2){
        //     if(delete objSub.goods){
        //         objSub.brand = information
        //         // objSub.brand.logo = ''
        //     }
        // }else if(objSub.target == 1){
        //     if(delete objSub.brand){
        //         objSub.goods = information
        //         // objSub.goods.title = ''
        //     }
        // }if(objSub.target == 3){
        //     if(delete objSub.goods && delete objSub.brand){
        //         objSub.goodsPlant = information
        //     }
        // }
        objSub.marketList = information

        
        if(objSub.cooperationWay == 2){
            if(delete objSub.item){
                objSub.live = requirement
                // objSub.live.liveDemand = ''
                // objSub.live.bloggerPubTime = ''
                // objSub.live.bloggerApplicationTimeFrom = startEndTime[0]
                // objSub.live.bloggerApplicationTimeTo = startEndTime[1]
            }

        }else{
            if(delete objSub.live){
                objSub.item = requirement
                // objSub.item.viewDemand = ''
                // objSub.item.mouthDemand = ''
                // objSub.item.titleDemand = ''
                // objSub.item.demos = ''
                // objSub.item.bloggerPubDeadline = ''
                // objSub.item.retainDay = ''
                objSub.item.forces = forcesList
                // objSub.item.bloggerApplicationTimeFrom = startEndTime[0]
                // objSub.item.bloggerApplicationTimeTo = startEndTime[1]
                // objSub.item.other1Demand = ''
            }
        }

        if(objSub.target !=2 && objSub.cooperationWay == 1){
            otherList?.map((e,index)=>{
                objSub.item['other'+(index+1)+'Demand'] = e
            })
        }

        if(objSub.sendWay == 2){
            if(delete objSub.uidBlogger){
                // 是否授权智能派发  红人的筛选都是可以传的
                // if(redInfo.intelligent == 1){
                //     // let cn = redInfo.nationality?.split('（')[1]
                //     // if(cn != undefined){
                //     //     redInfo.nationality = cn.substring(0, cn.length - 1)
                //     // }
                //     objSub.cargoClassification = redInfo.cargoClassification
                //     objSub.nationality = {id: redInfo.nationality}
                //     objSub.followerCountFrom = redInfo.followerCountFrom
                //     objSub.followerCountTo = redInfo.followerCountTo
                // }else{
                //     objSub.cargoClassification = ''
                //     objSub.nationality = {id: ''}
                //     objSub.followerCountFrom = ''
                //     objSub.followerCountTo = ''
                // }
                objSub.cargoClassification = redInfo.cargoClassification
                objSub.nationality = {id: redInfo.nationality}
                objSub.followerCountFrom = redInfo.followerCountFrom
                objSub.followerCountTo = redInfo.followerCountTo
                objSub.intelligent = redInfo.intelligent
            }
        }else{
            objSub.userIdBlogger = bloggerItem?.uid
        }

        console.log(objSub)
        if(bools){
            Api.marketSubmit(objSub).then(res=>{
                if(res.code == 200){
                    if(res.data.isSuccess ==1){
                        setRelease(true)
                        let datas = res.data
                        datas.target = objSub.target
                        datas.cooperationWay = objSub.cooperationWay
                        datas.media = objSub.media
                        setResult(datas)
                        
                    }
                }
            })
        }
    }

    const [marketDetail, setMarketDetail] = useState()
    const getMarketDetail = (item)=>{
        Api.marketDetail(props.location.state).then(res=>{
            // console.log(res)
            if(res.code == 200){
                let dataDetail = res.data
                res.data.operationCode = 1
                setObjSub(res.data)
                setInformation(dataDetail.marketList)
                // console.log(dataDetail.target, dataDetail.target == 1 ? res.data.goods : dataDetail.target == 3 ? res.data.goodsPlant : res.data.brand)
                setRequirement(dataDetail.cooperationWay == 1 ? res.data.item : res.data.live)
                if(dataDetail.cooperationWay == 1){
                    setForcesList(res.data?.item?.forces || [])
                    let list = []
                    for (let index = 0; index < 6; index++) {
                        if(res.data.item['other'+(index+1)+'Demand']) list.push(res.data.item['other'+(index+1)+'Demand'])
                    }
                    setOtherList(list)
                    if(timeList.indexOf(res.data.item.retainDay) > -1){
                        setTimeIndex(timeList.indexOf(res.data.item.retainDay))
                    }else{
                        // console.log(timeList.indexOf(res.data.item.retainDay) > -1)
                        setTimeNum(res.data.item.retainDay)
                        setTimeIndex(5)
                        setCheckedTime(true)
                    }
                } 
                let listD = dataDetail.cooperationWay == 1 ? res.data?.item?.tipFiles || [] : res.data?.live?.tipFiles || []
                listD.forEach((item,index)=>{
                    listD[index] = {
                        uid: (index+1).toString(),
                        name: item.name,
                        status: 'done',
                        url: item.url,
                        response: item
                    }
                })
                setDefaultFileList(listD)
                // let fromTo = dataDetail.cooperationWay == 1 ? res.data.item : res.data.live
                // setStartEndTime([fromTo.bloggerApplicationTimeFrom, fromTo.bloggerApplicationTimeTo])
                if(res.data.sendWay == 2){
                    let obj = {
                        nationality: res.data.nationality?.id, followerCountFrom: res.data.followerCountFrom,
                        followerCountTo: res.data.followerCountTo, cargoClassification: res.data.cargoClassification,
                        intelligent: res.data.intelligent, cargoClassificationInterpretation: []
                    }
                    res.data.cargoClassification.split(',').forEach(itemS=>{
                        for (let index = 0; index < classList.length; index++) {
                            const item = classList[index];
                            if(item.id == itemS){
                                obj.cargoClassificationInterpretation.push(item.goodsEnglishName)
                                break
                            }
                        }
                    })
                    // console.log(obj)
                    setRedInfo(obj)
                }
                setMarketDetail(res.data)
            }
        })
    }
    useEffect(()=>{
        if(marketDetail) marketSubmit(false)
    },[marketDetail])

    // 要回显指定红人数据

    const isSub = ()=>{
        var isInfor = information?.brand!= ''&& information?.biz!= ''&& information?.intro!= ''&& (information?.imgs.length > 0 || information?.videos?.length > 0)
        // var isDemand = requirement.tips!= ''
        // var amount = objSub.costFrom!='' && objSub.costTo!='' && objSub.costFrom < objSub.costTo
        var isRed = false
        if(objSub.sendWay == 2){
            if(redInfo.intelligent == 1){
                isRed = redInfo.cargoClassification!='' && redInfo.followerCountFrom >= 0 && redInfo.followerCountTo > 0
                && redInfo.nationality!=undefined && parseInt(redInfo.followerCountTo) > parseInt(redInfo.followerCountFrom)
            }else{
                isRed = true
            }
        }else{
            isRed = bloggerItem != null
        }
        if(objSub.target !=2 && objSub.cooperationWay == 1){
            return isInfor && information?.title!= '' && requirement?.duration &&
                requirement?.bloggerPubDeadline!='' &&
                timeIndex > -1
                && isRed
        }
        if(objSub.target !=2 && objSub.cooperationWay == 2){
            return isInfor && information?.title!= '' && requirement?.durationH &&
                requirement?.bloggerPubTime!='' 
                && isRed
        }
        if(objSub.target == 2 && objSub.cooperationWay == 1){
            return isInfor && information?.logo && requirement?.duration &&
                requirement?.bloggerPubDeadline!='' && 
                timeIndex > -1
                && isRed
        }
        if(objSub.target == 2 && objSub.cooperationWay == 2){
            return isInfor && information?.logo && requirement?.durationH && 
                requirement?.bloggerPubTime!=''
                && isRed
        }
    }



    const [icon, setIcon] = useState('')
    const handleChange =(value, e)=> {
        // console.log(`selected ${value}`);
        changeRedInfo('nationality', value)
        // setIcon(e.icon)
    }
    useEffect(()=>{
        changeRedInfo('nationalFlag', icon)
    },[icon])

    const [classId, setClassId] = useState('')
    const handleChangeFlei =(value, e)=> {
        // console.log(`selected ${value}`);
        if(value.length < 4) {
            changeRedInfo('cargoClassificationInterpretation', value == undefined?[]:value)
            let idList = []
            e?.map(item=>{
                return idList.push(item.id)
            })
            setClassId(idList.join(','))
        }
        console.log(redInfo);
    }
    useEffect(()=>{
        changeRedInfo('cargoClassification', classId)
    },[classId])




    const [bloggerItem, setBloggerItem] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [valueSearch, setValueSearch] = useState(null)
    const [searchList, setSearchList] = useState([])
    const handleSearch = (value) => {
        setValueSearch(value==''?null:value)
    }
    
    useEffect(()=>{
        if (valueSearch) {
            getSearch()
        } else {
            setSearchList([])
        }
    }, [valueSearch])
    const getSearch = () => {
        setFetching(true)
        Api.getRedkeyword({keyword: valueSearch}).then(res=>{
            setFetching(false)
            if(res.code == 200){
                setSearchList(res.data)
            }
        })
    }
    const addBloggerS = (item,index) => {
        setBloggerItem(item)
        setIsHotR(false)
    }
    
    return <div id='videoDemand'>
        <nav><img style={{width:'100%'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/demandBack.png'} alt="" /></nav>
        <div style={{width:'100%', display:'flex', marginTop:'14px'}}>
            <div style={{width:'174px'}}>
                <div style={{width:'150px', backgroundColor:'#FFF', padding:'20px 17px 14px 17px',
                    position: linkId!='' ? 'fixed' : '', top: '66px'}}>
                <Anchor affix={false} offsetTop={66} onChange={e=>onChangeLink(e)}>
                    {
                        links?.map((item, index)=>{
                            return <div className='over1' key={index} style={{width:'100%', height:'34px', 
                                background: (linkId == ("#id"+index)) || (index==0 && linkId=='') ?'linear-gradient(180deg, #F7385B, #FF716E)' : '#FFF',
                                marginBottom:'8px', position:'relative', textAlign:'center', lineHeight:'34px', 
                                color: (linkId == ("#id"+index)) || (index==0 && linkId=='') ? '#FFF' : '#333',
                                fontWeight:'bold', borderRadius:'4px'}}>
                                {item}
                                <div style={{width:'calc(100% + 14px)', height:'100%', position:'absolute', top:0, left: '-14px',
                                    opacity:0}}>
                                    <Link href={"#id"+index} title={item} />
                                </div>
                            </div>
                        })
                    }
                </Anchor>
                </div>
            </div>
            <div style={{width:'calc(100% - 174px)', backgroundColor:'#FFF'}}>
                <div style={{width:'100%', position:'relative'}}>
                    <img style={{width:'100%'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/demandBack1.png'} alt="" />
                    <div className='gotoQY' style={{position:'absolute', top:0, height:'100%', display:'flex', alignItems:'center',
                        paddingLeft:'20%'}}>
                        {textJson['完成企业认证即可开启发布视频创意需求功能，助力品牌形象传播，提升商品销售量']}</div>
                        {/* 完成<span style={{color:'#F7385B', cursor:'pointer'}}>企业认证</span>即可开启发布视频创意需求功能，助力品牌形象传播，提升商品销售量</div> */}
                </div>
                <div id='id0' style={{width:'100%', padding: '40px 70px 30px 70px'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['您的需求目标是什么？']}
                    </div> 
                    <div style={{display:'flex', flexWrap:'wrap', paddingLeft:'16px'}}>
                        <div onClick={()=>changeObj('target', 3)} style={{width:'280px', height:'100px', border: objSub.target==3?'1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            color:'#888888', fontSize:'13px', padding:'10px 24px', marginTop:'20px', marginRight:'24px',
                            cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.target==3?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', lineHeight:'20px', paddingTop:lang=='zh'?'5px':0}}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/zhongcao'+(objSub.target==3?'s':'')+'.png'} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                    {textJson[Enum.propertiesTarget[3].val]}
                            </div>
                            <div style={{lineHeight:lang=='zh'?'20px':'12px', marginTop:'5px'}}>{textJson['让红人为您的商品进行种草推荐，提升商品销售量']}</div>
                            {objSub.target==3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> : ''} 
                        </div>
                        <div onClick={()=>changeObj('target', 2)} style={{width:'280px', height:'100px', border: objSub.target==2?'1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'10px 24px', marginTop:'20px',
                            cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.target==2?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', lineHeight:'20px', paddingTop:lang=='zh'?'5px':0}}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pinpai'+(objSub.target==2?'s':'')+'.png'} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                {textJson[Enum.propertiesTarget[2].val]}
                            </div>
                            <div style={{lineHeight:lang=='zh'?'20px':'12px', marginTop:'5px'}}>{textJson['让红人为您的品牌发声，提升品牌美誉度']}</div>
                            {objSub.target==2 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> : ''} 
                        </div>
                        <div onClick={()=>changeObj('target', 4)} style={{width:'280px', height:'100px', border: objSub.target==4?'1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'10px 24px', marginTop:'20px',
                            cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.target==4?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', lineHeight:'20px', paddingTop:lang=='zh'?'5px':0}}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/shangpin'+(objSub.target==4?'s':'')+'.png'} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                    {Enum.propertiesTarget[4].val}
                            </div>
                            <div style={{lineHeight:lang=='zh'?'20px':'12px', marginTop:'5px'}}>{textJson['让红人为您的商品或品牌直播带货，提升商品销售量']}</div>
                            {objSub.target==4 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> : ''} 
                        </div>
                        <div onClick={()=>changeObj('target', 5)} style={{width:'280px', height:'100px', border: objSub.target==5?'1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'10px 24px', marginTop:'20px',
                            cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.target==5?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', lineHeight:'20px', paddingTop:lang=='zh'?'5px':0}}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/live'+(objSub.target==5?'s':'')+'.png'} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                    {Enum.propertiesTarget[5].val}
                            </div>
                            <div style={{lineHeight:lang=='zh'?'20px':'12px', marginTop:'5px'}}>让红人为您的商品进行好评推荐，提升商品销售量</div>
                            {objSub.target==5 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> : ''} 
                        </div>
                        <div onClick={()=>changeObj('target', 6)} style={{width:'280px', height:'100px', border: objSub.target==6?'1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'10px 24px', marginTop:'20px',
                            cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.target==6?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', lineHeight:'20px', paddingTop:lang=='zh'?'5px':0}}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/product/open'+(objSub.target==6?'s':'')+'.png'} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                    {Enum.propertiesTarget[6].val}
                            </div>
                            <div style={{lineHeight:lang=='zh'?'20px':'12px', marginTop:'5px'}}>让红人为您的商品进行开箱推荐，提升商品销售量</div>
                            {objSub.target==6 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> : ''} 
                        </div>
                    </div>
                </div>
                <div id='id1' style={{width:'100%', padding: '30px 70px', borderTop:'1px dashed #CCCCCC'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['您想在哪个媒体传播？']}
                    </div> 
                    <div style={{display:'flex',flexWrap:'wrap', paddingLeft:'16px'}}>
                        <div onClick={()=>changeObj('media', 1)} style={{width:'280px', height:'100px', border: objSub.media == 1 ? '1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px', cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.media == 1 ? '#F7385B' : '#333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                    <img src={Enum.propertiesMedia[1].icon} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                {Enum.propertiesMedia[1].val}
                            </div>
                            {textJson['Tiktok媒体强势锁定新生代消费主力']}
                            {objSub.media == 1 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''} 
                        </div>
                        <div onClick={()=>changeObj('media', 2)} style={{width:'280px', height:'100px', border: objSub.media == 2 ? '1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px', cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.media == 2 ? '#F7385B' : '#333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                    <img src={Enum.propertiesMedia[2].icon} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                {Enum.propertiesMedia[2].val}
                            </div>
                            Youtube做专业内容的社媒
                            {objSub.media == 2 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                        <div onClick={()=>changeObj('media', 3)} style={{width:'280px', height:'100px', border: objSub.media == 3 ? '1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px', cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.media == 3 ? '#F7385B' : '#333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                    <img src={Enum.propertiesMedia[3].icon} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                {Enum.propertiesMedia[3].val}
                            </div>
                            Facebook在全球的渗透率是非常大
                            {objSub.media == 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                        <div onClick={()=>changeObj('media', 4)} style={{width:'280px', height:'100px', border: objSub.media == 4 ? '1px solid #F7385B':'1px solid #EEEEEE', borderRadius:'4px', position:'relative',
                            marginRight:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px', cursor:'pointer'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.media == 4 ? '#F7385B' : '#333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                    <img src={Enum.propertiesMedia[4].icon} style={{width:'18px', marginRight:'10px'}} alt="" /> 
                                {Enum.propertiesMedia[4].val}
                            </div>
                            Instagram是当今最大的社交网络之一
                            {objSub.media == 4 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                    </div>
                </div>
                <div id='id2' style={{width:'100%', padding: '30px 70px', borderTop:'1px dashed #CCCCCC'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['您要使用哪种内容形式？']}
                    </div> 
                    <div style={{display:'flex'}}>
                        <div onClick={()=>changeObj('cooperationWay', 1)} style={{width:'280px', height:'100px', border: objSub.cooperationWay==1?'1px solid #F7385B':'1px solid #EEEEEE', 
                            borderRadius:'4px', position:'relative', cursor:'pointer',
                            marginLeft:'16px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.cooperationWay==1?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                {textJson['短视频']}
                            </div>
                            {textJson['发布短视频内容营销']}
                            {objSub.cooperationWay==1? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                        <div onClick={()=>changeObj('cooperationWay', 2)} style={{width:'280px', height:'100px', border: objSub.cooperationWay==2?'1px solid #F7385B':'1px solid #EEEEEE', 
                            borderRadius:'4px', position:'relative', cursor:'pointer',
                            marginLeft:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.cooperationWay==2?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                {textJson['直播']}
                            </div>
                            {textJson['通过直播宣传带货']}
                            {objSub.cooperationWay==2? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                    </div>
                </div>
                <div id='id3' style={{width:'100%', padding: '30px 70px', borderTop:'1px dashed #CCCCCC'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['您要使用哪种发单模式？']}
                    </div> 
                    <div style={{display:'flex'}}>
                        <div onClick={()=>changeObj('sendWay', 1)} style={{width:'280px', height:'100px', border: objSub.sendWay==1?'1px solid #F7385B':'1px solid #EEEEEE', 
                            borderRadius:'4px', position:'relative', cursor:'pointer',
                            marginLeft:'16px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.sendWay==1?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                {textJson['指定红人']}
                            </div>
                            {textJson['我有明确红人，或者想指定某红人接单']}
                            {objSub.sendWay==1? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                        <div onClick={()=>changeObj('sendWay', 2)} style={{width:'280px', height:'100px', border: objSub.sendWay==2?'1px solid #F7385B':'1px solid #EEEEEE', 
                            borderRadius:'4px', position:'relative', cursor:'pointer',
                            marginLeft:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.sendWay==2?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                {textJson['筛选红人']}
                            </div>
                            {textJson['我无明确红人，想设置条件筛选红人']}
                            {objSub.sendWay==2? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                    </div>
                    {objSub.sendWay == 2 ? <div style={{margin:'0 auto', padding: '30px 0'}}>
                        <div style={{paddingLeft:'40px', marginTop:'23px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div id='flei' style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'115px', minWidth:'115px', fontWeight: 600, paddingRight:'16px'}}><span style={{color: redInfo.intelligent==1?'#F7385B':'#FFF'}}>*</span>{textJson['红人擅长分类']}</div>
                                    <Select
                                        mode="multiple" size='large' maxTagCount={3}
                                        style={{ width: '100%', minWidth:'450px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                        placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择红人擅长的带货分类']}</span>} allowClear={true} showArrow={true}
                                        onChange={handleChangeFlei} value={redInfo.cargoClassificationInterpretation}
                                        optionLabelProp="label" getPopupContainer={() => document.getElementById('flei')}
                                    >
                                        {
                                            classList?.map((item,index)=>{
                                                return <Option key={index} id={item.id} value={item.goodsEnglishName} label={item.goodsEnglishName}>
                                                    <div style={{textAlign:'left'}}>
                                                        {item.goodsEnglishName}（{item.goodsName}）
                                                    </div>
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div style={{fontSize:'12px', color:'#CCCCCC', paddingLeft:'115px', marginTop:'5px'}}>{textJson['最多可选择3个红人擅长的带货分类']}</div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'23px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div id='guojia' style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'115px', fontWeight: 600, paddingRight:'16px'}}><span style={{color: redInfo.intelligent==1?'#F7385B':'#FFF'}}>*</span>{textJson['红人所属国籍']}</div>
                                    <Select
                                        size='large' showSearch
                                        style={{ width: '450px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                        placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择红人所属国籍']}</span>} allowClear={true}
                                        onChange={handleChange} value={redInfo.nationality}
                                        optionLabelProp="label" getPopupContainer={() => document.getElementById('guojia')}
                                    >
                                        {
                                            geolocation?.map((item,index)=>{
                                                let itemS = `${item.enName}（${item.name}）`
                                                return <Option key={index} icon={item.imgUrl} cn={item.name} value={item.id} label={itemS}>
                                                    <div style={{textAlign:'left'}}>
                                                        <img src={item.imgUrl} style={{width:'14px', marginRight:'6px'}} />
                                                        {item.enName} ({item.name})
                                                    </div>
                                                </Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'23px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'115px', fontWeight: 600}}><span style={{color: redInfo.intelligent==1?'#F7385B':'#FFF'}}>*</span>{textJson['红人粉丝范围']}</div>
                                <Input onChange={e=>changeRedInfo('followerCountFrom', e.target.value.replace(/[^\d]/g,''))} value={redInfo.followerCountFrom < 0 ? '' : redInfo.followerCountFrom}
                                    style={{width:'142px', height:'48px', borderRadius:'4px', textAlign:'center', 
                                    borderColor: parseInt(redInfo.followerCountFrom) > 0 && parseInt(redInfo.followerCountTo) > 0 && parseInt(redInfo.followerCountTo) <= parseInt(redInfo.followerCountFrom)?'#F7385B':'#d9d9d9'}} 
                                    placeholder={textJson['请输入最低粉丝量']} />
                                <span style={{color:'#CCCCCC', marginLeft:'4px'}}> &nbsp;～ </span>
                                <Input onChange={e=>changeRedInfo('followerCountTo', e.target.value.replace(/[^\d]/g,''))} value={redInfo.followerCountTo == 0 ? '' : redInfo.followerCountTo}
                                    style={{width:'142px', height:'48px', borderRadius:'4px', textAlign:'center', marginLeft:'10px',
                                    borderColor: parseInt(redInfo.followerCountFrom) > 0 && parseInt(redInfo.followerCountTo) > 0 && parseInt(redInfo.followerCountTo) <= parseInt(redInfo.followerCountFrom)?'#F7385B':'#d9d9d9'}} 
                                    placeholder={textJson['请输入最高粉丝量']} />
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'26px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'115px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['智能派发']}</div>
                                <div>
                                    <Radio checked={redInfo.intelligent==0} onChange={e=>changeRedInfo('intelligent', 0)}>{textJson['授权智能派发']}</Radio>
                                    <div style={{width:'280px', fontSize:'12px', color:'#CCCCCC', paddingLeft:'22px'}}>{textJson['勾选此选项，系统将根据筛选条件智能调度更多博主参与，可能扩招到圈选范围外的博主']}</div>
                                </div>
                                <div style={{marginLeft:'60px'}}>
                                    <Radio checked={redInfo.intelligent==1} onChange={e=>changeRedInfo('intelligent', 1)}>{textJson['不授权智能派发']}</Radio>
                                    <div style={{width:'280px', fontSize:'12px', color:'#CCCCCC', paddingLeft:'22px'}}>{textJson['勾选此选项，系统将严格遵守您圈选范围内的博主参与，不会智能调度其他博主']}</div>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div style={{width:'100%', padding: '30px 0'}}>
                        <div style={{paddingLeft:'40px', marginTop:'23px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', fontWeight: 600, marginTop:'13px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['添加指定红人']}</div>
                                <div style={{width:'600px'}}>
                                    {bloggerItem ? <div style={{width:'100%', height:'85px', display:'flex', alignItems:'center', justifyContent:'space-between',
                                        marginTop:'-18px'}}>
                                        <Image src={bloggerItem.avatarMedium} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                        style={{ width: '44px', height: '44px', borderRadius: '22px' }} />
                                        <div style={{width:'calc(100% - 54px)', height:'100%', display:'flex', alignItems:'center',
                                            }}>
                                            <div style={{width:'170px'}}>
                                                <div className='over1' style={{fontWeight:600, color:'#333'}}>{bloggerItem.nickname}</div>
                                                <div style={{fontSize:'12px', color:'#888888', marginTop:'-4px'}}>TikTok: {bloggerItem.tiktok}</div>
                                            </div>  
                                            {objSub.cooperationWay == 1 ? <div style={{textAlign:'center'}}>
                                                <div style={{fontWeight:600, color:'#F7385B'}}>${three(bloggerItem.videoQuotationDollar)}/m</div>
                                                <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['视频制作单价']}</div>
                                            </div> :
                                            <div style={{textAlign:'center'}}>
                                                <div style={{fontWeight:600, color:'#F7385B'}}>${three(bloggerItem.liveBroadcastQuotationDollar)}/h</div>
                                                <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['直播制作单价']}</div>
                                            </div>}
                                            <div style={{width:'1px', height:'44px', background:'#CCCCCC', margin:'0 70px'}}></div>
                                            {objSub.asettleWay == 1 ? <div style={{textAlign:'center'}}>
                                                <div style={{fontWeight:600, color:'#F7385B'}}>$0({textJson['寄样抵佣金']})</div>
                                                <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['此合作需支付总价']}</div>
                                            </div> : 
                                            (objSub.cooperationWay == 1 && requirement?.duration)||(objSub.cooperationWay == 2 && requirement?.durationH)?<div style={{textAlign:'center'}}>
                                                <div style={{fontWeight:600, color:'#F7385B'}}>${three(objSub.cooperationWay == 1 ? (bloggerItem.videoQuotationDollar * 100 * (requirement?.duration / 60) / 100) : (bloggerItem.liveBroadcastQuotationDollar * 100 * requirement?.durationH / 100))}</div>
                                                <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['此合作需支付总价']}</div>
                                            </div>:
                                            <a href={'#id6'} style={{fontSize:'12px', color:'#F7385B', cursor:'pointer'}}>{objSub.cooperationWay == 1?textJson['请先选择视频时长']:textJson['请先选择直播时长']}</a>}
                                        </div>
                                    </div>:''}
                                    <div onClick={()=>setIsHotR(true)} style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                        textAlign:'center', lineHeight:'38px', color: '#FFF', marginRight:'13px', marginTop:'6px',
                                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                        {bloggerItem!=null ? textJson['更换红人'] : textJson['添加红人']}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> }
                </div>
                <div id='id4' style={{width:'100%', padding: '30px 70px', borderTop:'1px dashed #CCCCCC'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['您要使用哪种结算方式？']}
                    </div> 
                    <div style={{display:'flex'}}>
                        <div onClick={()=>changeObj('asettleWay', 0)} style={{width:'280px', height:'100px', border: objSub.asettleWay==0?'1px solid #F7385B':'1px solid #EEEEEE', 
                            borderRadius:'4px', position:'relative', cursor:'pointer',
                            marginLeft:'16px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.asettleWay==0?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                {textJson['支付佣金']}
                            </div>
                            {textJson['我选择向红人支付相应的佣金']}
                            {objSub.asettleWay==0? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                        <div onClick={()=>changeObj('asettleWay', 1)} style={{width:'280px', height:'100px', border: objSub.asettleWay==1?'1px solid #F7385B':'1px solid #EEEEEE', 
                            borderRadius:'4px', position:'relative', cursor:'pointer',
                            marginLeft:'24px', color:'#888888', fontSize:'13px', padding:'20px 24px', marginTop:'20px'}}>
                            <div style={{fontSize:'18px', fontWeight:'bold', color: objSub.asettleWay==1?'#F7385B':'#333333',
                                display:'flex', alignItems:'center', marginBottom:'5px'}}>
                                {textJson['寄样品抵佣金']}
                            </div>
                            {textJson['我选择寄样品送给红人来抵佣金']}
                            {objSub.asettleWay==1? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/buyVip/select.png'} 
                                style={{width:'25px', position:'absolute', right:0, bottom:0}} alt="" /> :''}
                        </div>
                    </div>
                </div>
                <div id='id5' style={{width:'100%', padding: '30px 70px', borderTop:'1px dashed #CCCCCC'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['基本信息']}<span style={{color:'#888', fontSize:'14px', fontWeight:'500'}}>（{textJson['建议英文输入最佳']}）</span>
                    </div> 
                    {objSub.target != 2 ? <div style={{paddingLeft:'40px', marginTop:'23px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['商品名称']}</div>
                            <Input onChange={e=>changeInfo('title', e.target.value)} value={information?.title}
                                style={{width:'657px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入商品名称']}
                                maxLength={40} showCount required />
                        </div>
                    </div>:
                    <div style={{paddingLeft:'40px', marginTop:'23px'}}>
                        <div style={{display:'flex'}}>
                            <div style={{width:'105px', fontWeight: 600, marginTop:'13px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['品牌LOGO']}</div>
                            {!information?.logo ? <div style={{width:'calc(100% - 105px)', position:'relative'}}>
                                <div style={{width:'300px'}}>
                                    <Upload {...fileListsLogo}>
                                        <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                            textAlign:'center', lineHeight:'38px', color: '#FFF', marginRight:'13px', marginTop:'6px',
                                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                            {textJson['上传LOGO']}
                                        </div>
                                    </Upload>
                                </div>
                                <span style={{color:'#B3B3B3', fontSize:'12px',position:'absolute',
                                    top:'15px', left:'118px'}}>（{textJson['LOGO的尺寸为1:1，大小不超过1M，格式为JPG或PNG']}）</span>
                            </div>:
                            <div style={{position:'relative', padding:'4px', border:'1px solid #EEE'}}>
                                <Image src={information?.logo} style={{borderRadius:'4px', width:'130px', height:'130px'}}/>
                                <div style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                    background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                    textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                    <CloseOutlined onClick={(e)=>delLogo(e)} />
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                    <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['品牌名称']}</div>
                            <Input onChange={e=>changeInfo('brand', e.target.value)} value={information?.brand}
                                style={{width:'450px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请输入品牌名称']} />
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', marginTop:'16px'}}>
                        <div id='flei' className='jiben' style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['所属行业']}</div>
                            {/* <Input onChange={e=>changeInfo('biz', e.target.value)} value={information.biz}
                                style={{width:'450px', height:'48px', borderRadius:'4px'}} placeholder={textJson['请选择所属行业']} /> */}
                            <Select
                                size='large'
                                style={{ width:'450px', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择所属行业']}</span>} allowClear={true} showArrow={true}
                                onChange={(e)=>changeInfo('biz', {id: e})} value={information?.biz?.id==''?undefined:information?.biz.id}
                                optionLabelProp="label" getPopupContainer={() => document.getElementById('flei')}
                            >
                                {
                                    classList?.map((item,index)=>{
                                        return <Option key={index} id={item.id} value={item.id} label={item.goodsEnglishName}>
                                            <div style={{textAlign:'left'}}>
                                                {item.goodsEnglishName}（{item.goodsName}）
                                            </div>
                                        </Option>
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', marginTop:'16px'}}>
                        <div style={{display:'flex'}}>
                            <div style={{width:'105px', fontWeight: 600, marginTop:'16px'}}><span style={{color:'#F7385B'}}>*</span>{(objSub.target == 1 || objSub.target == 3) ?textJson['商品介绍']:textJson['品牌介绍']}</div>
                            <TextArea showCount maxLength={1000} value={information?.intro} 
                                onChange={e=>changeInfo('intro', e.target.value)}
                                style={{width:'657px', height:'109px', borderRadius:'4px'}} placeholder={
                                    (objSub.target == 1 || objSub.target == 3) ?textJson['请输入商品的介绍，详细描述如商品的规则参数、颜色、尺寸、功能、优势等等，让博主能更深入的了解该商品']
                                    : textJson['请输入品牌的介绍，详细描述品牌故事，品牌商品等等，让博主能更深入的了解品牌']} />
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                        <div style={{display:'flex'}}>
                            <div style={{width:'105px', fontWeight: 600, marginTop:'13px'}}><span style={{color:'#F7385B'}}>*</span>{textJson['图片与视频']}</div>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div>
                                    <Upload {...fileLists} showUploadList={false}>
                                        <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                            textAlign:'center', lineHeight:'38px', color: '#FFF', marginRight:'13px', marginTop:'6px',
                                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                            {textJson['上传照片']}
                                        </div>
                                    </Upload>
                                </div>
                                <div>
                                    <Upload {...videoLists} showUploadList={false}>
                                        <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                            textAlign:'center', lineHeight:'38px', color: '#FFF', marginRight:'13px', marginTop:'6px',
                                            background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                            {intl.get('EnterpriseCertification')['上传视频']}
                                        </div>
                                    </Upload>
                                </div>
                                <span style={{color:'#B3B3B3', fontSize:'12px', lineHeight:'15px', marginTop:'4px'}}>
                                    {textJson['单张照片不超过1M，格式为PNG或JPG']} <br/>{textJson['单个视频不超过50M，格式为MP4、MKV、AVI、FLV、MPG']}
                                </span>
                            </div>
                        </div>
                        <div style={{marginLeft:'105px', height: (information?.videos?.length>0 || information?.imgs.length>0)?'200px':'auto', display:'flex', marginTop:'20px', width:'calc(100% - 105px)',
                            overflowX:'auto', paddingTop:'10px'}}>
                            {
                                information?.videos?.map((item,index)=>{
                                    return <div onClick={()=>setVideoIndex(index)} key={index} style={{width:'130px', height:'180px', position:'relative', marginRight:'10px'}}>
                                        <Image src={item+'?spm=qipa250&x-oss-process=video/snapshot,t_7000,f_jpg,m_fast'} width={130} height={180} style={{borderRadius:'4px'}}/>
                                        <div style={{position:'absolute', top:0,left:0, width:'100%', height:'100%',
                                            background:'rgba(0,0,0,0.22)', borderRadius:'4px', display:'flex',
                                            justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
                                            <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/player_red.png'} alt="" 
                                            style={{width:'30px'}} />
                                        </div>
                                        {/* <div style={{width:'100%', height:'30px', textAlign:'center', position:'absolute',
                                            bottom:0, left:0, color:'#FFF'}}>0:59</div> */}
                                        <div style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                        background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                        textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                            <CloseOutlined onClick={(e)=>delVideo(e,index)} />
                                        </div>
                                    </div>
                                })
                            }
                            {
                                information?.imgs?.map((item,index)=>{
                                    return <div key={index} style={{width:'130px', height:'180px', position:'relative', marginRight:'10px'}}>
                                        <Image src={item} width={130} height={180} style={{borderRadius:'4px'}}/>
                                        <div style={{width:'16px', height:'16px', borderRadius:'50%', fontSize:'8px',
                                            background:'#FFEEF1', boxShadow:'0px 0px 6px 0px #A9A9A9', color:'#F7385B', cursor:'pointer',
                                            textAlign:'center', lineHeight:'12px', position:'absolute', top:'-6px', right:'-6px'}}>
                                            <CloseOutlined onClick={(e)=>delImg(e,index)} />
                                        </div>
                                    </div>
                                })
                            }
                            
                        </div>
                    </div>
                </div>
                <div id='id6' style={{width:'100%', padding: '30px 70px', borderTop:'1px dashed #CCCCCC'}}>
                    <div style={{display:'flex', alignItems:'center',
                        fontSize:'16px', fontWeight:'bold'}}>
                        <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                            marginRight:'16px'}}></div> 
                        {textJson['制作要求']}<span style={{color:'#888', fontSize:'14px', fontWeight:'500'}}>（{textJson['建议英文输入最佳']}）</span>
                    </div> 
                    { objSub.cooperationWay == 1 ? <div>
                        {/* 短视频 */}
                        <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                            <div id='guojia' style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['短视频时长']}</div>
                                <Select
                                    style={{ width: 'calc(100% - 105px - 130px - 80px)', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                    placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择短视频制作时长']}</span>} allowClear={true}
                                    onChange={videoTimeChange} value={requirement.duration}
                                >
                                    {
                                        videoTime?.map((item,index)=>{
                                            return <Option key={index} value={item} label={item}>
                                                <div>
                                                    <span style={{marginRight:'6px'}}>{item.icon}</span>
                                                    小于{item}s
                                                </div>
                                            </Option>
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>{textJson['例如商品实物出镜、近景特写、博主出镜、镜头时长、指定画面等等']}</div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', fontWeight: 600}}>{textJson['镜头要求']}</div>
                                <Input onChange={e=>changeReq('viewDemand', e.target.value)} value={requirement.viewDemand}
                                    style={{width:'calc(100% - 105px - 130px - 80px)', height:'48px', borderRadius:'4px'}} 
                                    placeholder={textJson['请输入您对镜头的要求']} maxLength={100} showCount />
                                <div style={{width:'130px', textAlign:'right'}}>
                                    <Checkbox className='active' onChange={e=>putForces(e, 'viewDemand')}
                                        checked={forcesList.indexOf('viewDemand') > -1}>
                                        <span>{textJson['硬性要求']}</span>
                                    </Checkbox>
                                </div>
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>{textJson['例如商品实物出镜、近景特写、博主出镜、镜头时长、指定画面等等']}</div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', fontWeight: 600}}>{textJson['口播要求']}</div>
                                <Input onChange={e=>changeReq('mouthDemand', e.target.value)} value={requirement.mouthDemand}
                                    style={{width:'calc(100% - 105px - 130px - 80px)', height:'48px', borderRadius:'4px'}} 
                                    placeholder={textJson['请输入您对口播的要求']} maxLength={100} showCount />
                                <div className='over1' style={{width:'130px', textAlign:'right', height:'20px'}}>
                                    <Checkbox className='active' onChange={e=>putForces(e, 'mouthDemand')}
                                        checked={forcesList.indexOf('mouthDemand') > -1}>
                                        <span>{textJson['硬性要求']}</span>
                                    </Checkbox>
                                </div>
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>{textJson['例如口播介绍指定文字、品牌、slogan、商品卖点，是否添加口播字幕等等']}</div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', fontWeight: 600}}>{textJson['标题要求']}</div>
                                <Input onChange={e=>changeReq('titleDemand', e.target.value)} value={requirement.titleDemand} 
                                    style={{width:'calc(100% - 105px - 130px - 80px)', height:'48px', borderRadius:'4px'}} 
                                    placeholder={textJson['请输入您对标题的要求']} maxLength={100} showCount />
                                <div style={{width:'130px', textAlign:'right'}}>
                                    <Checkbox className='active' onChange={e=>putForces(e, 'titleDemand')}
                                        checked={forcesList.indexOf('titleDemand') > -1}>
                                        <span>{textJson['硬性要求']}</span>
                                    </Checkbox>
                                </div>
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>{textJson['例如标题包含商品名称、商品性能/特点关键词、关联话题#xxx等等']}</div>
                        </div>
                        {
                            otherList?.map((item,index)=>{
                                return <div key={index} style={{paddingLeft:'40px', marginTop: index==0?'24px':'16px'}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{width:'105px', fontWeight: 600}}>{textJson['其他要求']}{index+1}</div>
                                        <Input value={item} onChange={e=>otherPut(e, index)} style={{width:'calc(100% - 105px - 130px - 80px)', height:'48px', borderRadius:'4px'}} 
                                            placeholder={textJson['请输入您的要求']} maxLength={100} showCount />
                                        <div style={{width:'130px', textAlign:'right'}}>
                                            <Checkbox className='active' onChange={e=>putForces(e, 'other'+(index+1)+'Demand')}
                                                checked={forcesList.indexOf('other'+(index+1)+'Demand') > -1}>
                                                <span>{textJson['硬性要求']}</span>
                                            </Checkbox>
                                        </div>
                                        <div onClick={()=>delOther(index)}
                                            style={{width:'80px', textAlign:'right', color:'#F7385B', cursor:'pointer'}}>
                                            <DeleteOutlined style={{fontSize:'16px', marginRight:'3px'}} />
                                            <span>{textJson['删除']}</span>
                                        </div>
                                    </div>
                                    {index==otherList.length-1 && otherList.length<6 ?<div onClick={()=>addOther(index)}
                                        style={{width:'420px', color:'#F7385B', marginLeft:'118px', marginTop:'5px', cursor:'pointer'}}>
                                        <PlusCircleFilled style={{marginRight:'7px'}} />{textJson['继续添加其他要求']}（{otherList.length}/6）
                                    </div>:''}
                                </div>
                            })
                        }
                        <div style={{paddingLeft:'40px', marginTop:'45px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', fontWeight: 600, marginTop:'16px'}}>{textJson['示例视频']}</div>
                                <TextArea onChange={e=>changeReq('demos', e.target.value)} value={requirement.demos}
                                    style={{width:'657px', height:'109px', borderRadius:'4px'}} 
                                    placeholder='请输入抖音视频链接，如http://v.douyin.com/xxxxx/，一行一个链接' />
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>
                                {textJson['请输入抖音视频链接，如填写，则最少1个视频链接，最多添加3个视频链接，换行隔开']}
                            </div>
                        </div>
                    </div> :
                        <div>
                            <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                            <div id='guojia' style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['直播时长']}</div>
                                <Select
                                    style={{ width: 'calc(100% - 105px - 130px - 80px)', border:'1px solid #d9d9d9', textAlign:'left', borderRadius:'4px' }}
                                    placeholder={<span style={{fontSize:'14px'}}>{textJson['请选择直播时长']}</span>} allowClear={true}
                                    onChange={liveTimeChange} value={requirement?.durationH}
                                >
                                    {
                                        liveTime?.map((item,index)=>{
                                            return <Option key={index} value={item} label={item}>
                                                <div>
                                                    <span style={{marginRight:'6px'}}>{item.icon}</span>
                                                    小于{item}h
                                                </div>
                                            </Option>
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>{textJson['例如商品实物出镜、近景特写、博主出镜、镜头时长、指定画面等等']}</div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'22px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', fontWeight: 600, marginTop:'16px'}}>{textJson['直播要求']}</div>
                                <TextArea onChange={e=>changeReq('liveDemand', e.target.value)} value={requirement?.liveDemand}
                                    showCount maxLength={1000} style={{width:'657px', height:'109px', borderRadius:'4px'}} 
                                    placeholder={textJson['请输入您对直播的要求']} />
                            </div>
                            <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>
                                {textJson['例如指定话题，指定音乐，场景要求，道具要求，其他要求等等']}
                            </div>
                        </div>
                    </div>
                    }
                    <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                        <div style={{display:'flex'}}>
                            <div style={{width:'105px', fontWeight: 600, marginTop:'16px'}}>{textJson['补充说明']}</div>
                            <TextArea onChange={e=>changeReq('tips', e.target.value)} value={requirement?.tips} 
                                showCount maxLength={3000} style={{width:'657px', height:'109px', borderRadius:'4px'}} 
                                placeholder={textJson['可以通过文案描述进行补充说明，或者点击上传附件，上传资料说明']} />
                        </div>
                        <div style={{paddingLeft:'103px', position:'relative', marginTop:'2px'}}>
                            <div style={{width:'300px'}}>
                                {defaultFileList ? <Upload {...fileListsTip}>
                                    <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                        textAlign:'center', lineHeight:'38px', color: '#FFF', marginRight:'13px', marginTop:'6px',
                                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                        {textJson['上传附件']}
                                    </div>
                                </Upload>:''}
                                {defaultFileList == null ? <Upload {...fileListsTips}>
                                    <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                        textAlign:'center', lineHeight:'38px', color: '#FFF', marginRight:'13px', marginTop:'6px',
                                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                        {textJson['上传附件']}
                                    </div>
                                </Upload>:''}
                            </div>
                            <span style={{color:'#B3B3B3', fontSize:'12px',position:'absolute',
                                top:'15px', left:'230px'}}>{textJson['只能上传word、excel、ppt、pdf格式的文件，且不超过50M']}</span>
                        </div>
                    </div>
                    <div style={{paddingLeft:'40px', marginTop:'64px'}}>
                        <div style={{display:'flex'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['样品寄送']}</div>
                            <div>
                                <Radio checked={requirement?.sendSample==1 || objSub.asettleWay == 1} onChange={e=>changeReq('sendSample', 1)}>{textJson['给博主提供样品']}</Radio>
                                <div style={{fontSize:'12px', color:'#CCCCCC', paddingLeft:'22px'}}>{textJson['博主在投稿时需提交收货地址方便寄送样品']}</div>
                            </div>
                            <div style={{marginLeft:'60px'}}>
                                <Radio disabled={objSub.asettleWay == 1} checked={requirement?.sendSample==2 && objSub.asettleWay != 1} onChange={e=>changeReq('sendSample', 2)}>{textJson['不给博主提供样品']}</Radio>
                                <div style={{fontSize:'12px', color:'#CCCCCC', paddingLeft:'22px'}}>{textJson['博主自行寻找或购买商品素材']}</div>
                            </div>
                        </div>
                    </div>
                    {objSub.sendWay == 2 ? <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['需求有效日期']}</div>
                            {/* <RangePicker onChange={changeTime} value={startEndTime.length>0?[moment(startEndTime[0], 'YYYY/MM/DD'), moment(startEndTime[1], 'YYYY/MM/DD HH:mm:ss')]:[]}
                                showTime
                                format="YYYY/MM/DD"
                                style={{width:'346px',height:'48px', borderRadius:'4px'}} /> */}
                            <DatePicker onChange={changeValidTime} value={
                                requirement?.bloggerApplicationTimeTo ?moment(requirement?.bloggerApplicationTimeTo , 'YYYY-MM-DD'):''}
                                // showTime allowClear
                                disabledDate={e=>e.format() < moment().startOf('day').format()}
                                format="YYYY-MM-DD"
                                placeholder={intl.get('SmallShop')['请选择需求有效日期']}
                                style={{width:'346px', height:'48px', borderRadius:'4px'}} />
                            <div style={{margin:'0 30px'}}>{intl.get('SmallShop')['或']}</div>
                            <div>
                                <Radio checked={!requirement?.bloggerApplicationTimeTo} onChange={e=>changeReq('bloggerApplicationTimeTo', '')}><span style={{color: !requirement?.bloggerApplicationTimeTo?'#333':'#CCC'}}>{intl.get('SmallShop')['需求永久有效']}</span></Radio>
                            </div>
                        </div>
                    </div> : ''}
                    {objSub.cooperationWay == 1 ? <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['红人截稿日期']}</div>
                            <DatePicker onChange={changePostTime} value={
                                requirement?.bloggerPubDeadline?moment(requirement?.bloggerPubDeadline, 'YYYY-MM-DD'):''}
                                // showTime allowClear
                                disabledDate={e=>e.format() < moment().startOf('day').format()}
                                format="YYYY-MM-DD"
                                placeholder={textJson['请选择期望视频完成且发布的日期']}
                                style={{width:'346px', height:'48px', borderRadius:'4px'}} />
                        </div>
                    </div> :
                    <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['期望开播日期']}</div>
                            <DatePicker onChange={changePostTime} value={
                                requirement?.bloggerPubTime?moment(requirement?.bloggerPubTime, 'YYYY-MM-DD'):''}
                                // showTime
                                disabledDate={e=>e.format() < moment().startOf('day').format()}
                                format="YYYY-MM-DD"
                                placeholder={textJson['请选择期望开直播的日期']}
                                style={{width:'346px', height:'48px', borderRadius:'4px'}} />
                        </div>
                    </div>}
                    {objSub.cooperationWay == 1 ? <div style={{paddingLeft:'40px', marginTop:'24px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'105px', fontWeight: 600}}><span style={{color:'#F7385B'}}>*</span>{textJson['期望保留时长']}</div>
                            <div style={{display:'flex', width:'362px', height:'48px', border:'1px solid #EEEEEE', borderRadius:'4px',
                                textAlign:'center', alignItems:'center', borderRight:0, lineHeight:'46px', cursor:'pointer'}}>
                                {
                                    timeList?.map((item,index)=>{
                                        return index < 5 ? <div key={index} style={{width:'72.4px', height:'100%', border: index==timeIndex?'1.5px solid #F7385B':0,
                                            borderRight: index==timeIndex?'1.5px solid #F7385B':'1px solid #EEEEEE', lineHeight:index==timeIndex?'43px':'46px',
                                            borderTopLeftRadius: index==0?'4px':0, borderBottomLeftRadius: index==0?'4px':0,
                                            background: index==timeIndex?'#FFEEF1':'', color: index==timeIndex?'#F7385B':'#333'}}
                                            onClick={()=>setTimeIndex(index)}>{index==0 ? textJson['不要求'] :`${item}${textJson['天']}`}
                                        </div> : 
                                        <Popover
                                            getPopupContainer={() => document.getElementById('videoDemand')}
                                            placement="bottom" key={index}
                                            content={
                                                <div>
                                                    <div style={{fontWeight:500, textAlign:'center'}}>{textJson['请输入自定义保留时长']}</div>
                                                    <Input onChange={e=>setTimeNum(e.target.value)} value={timeNum}
                                                        style={{width:'184px', height:'36px', borderRadius:'4px', margin:'3px 0'}} 
                                                        placeholder='' suffix="天"/>
                                                    <div style={{fontSize:'12px', color:'#666666'}}>{textJson['最多365天']}</div>
                                                    <div style={{display:'flex', justifyContent:'flex-end', margin:'6px 0 8px 0'}}>
                                                        <div style={{width:'60px', height:'26px', lineHeight:'24px', borderRadius:'13px',
                                                            border:'1px solid #EEEEEE', textAlign:'center', fontSize:'12px', cursor:'pointer'}}
                                                            onClick={()=>funCheckedTime(false)}
                                                            >{textJson['取消']}</div>
                                                        <div style={{width:'60px', height:'26px', lineHeight:'24px', borderRadius:'13px',
                                                            border:'1px solid #EEEEEE', textAlign:'center', fontSize:'12px',
                                                            background:'#F7385B', color:'#FFF', marginLeft:'10px', cursor:'pointer'}}
                                                            onClick={()=>funCheckedTime(timeNum!=''&&timeNum<366)}>{intl.get('Index')['确定']}</div>
                                                    </div>
                                                </div>
                                            }
                                            title=""
                                            trigger="click"
                                            visible={visible}
                                            onVisibleChange={e=>handleVisibleChange(e, index)}
                                        >
                                            <div style={{width:'72.4px', height:'100%',borderTopRightRadius: '4px', borderBottomRightRadius: '4px',
                                                lineHeight: index==timeIndex && checkedTime?'43px':'46px', 
                                                background: index==timeIndex && checkedTime?'#FFEEF1':'', 
                                                color: index==timeIndex && checkedTime?'#F7385B':'#333',
                                                border: index==timeIndex && checkedTime?'1.5px solid #F7385B':'', 
                                                borderRight: index==timeIndex && checkedTime?'1.5px solid #F7385B':'1px solid #EEEEEE'}}>
                                                {timeNum !='' && checkedTime ? timeNum+textJson['天'] : textJson['自定义']}
                                            </div>
                                        </Popover>
                                    })
                                }
                            </div>
                        </div>
                        <div style={{marginLeft:'118px', color:'#CCCCCC', fontSize:'12px', marginTop:'5px'}}>
                            {textJson['期望红人将该合作的短视频保留在TikTok上的时间，如红人再约定保留时间内主动操作删除，则会对红人信用分进行扣罚']}
                        </div>
                    </div> : ''}
                </div>
                
                <div id='id7' style={{width:'100%', height:'90px', background:'#333333', display:'flex', alignItems:'center',
                    justifyContent:'center', marginTop:'60px'}}>
                    {/* <Button disabled={false} style={{width:'150px', height:'50px', borderRadius:'25px', */}
                    <Button disabled={!isSub()} style={{width:'150px', height:'50px', borderRadius:'25px',
                        textAlign:'center', color: '#FFF', border:0,
                        background: isSub() ? 'linear-gradient(180deg, #F7385B, #FF716E)' : '#EEEEEE', fontSize:'16px', fontWeight:'blod' }} 
                        onClick={()=>marketSubmit()}>
                        {textJson['确认发布需求']}
                    </Button>
                </div>
            </div>
        </div>


        <Drawer placement="right" visible={isHotR} closable={false} width={550}>
            <div id='login' style={{padding:'30px 10px'}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <span style={{fontSize:'18px', color:'#333'}}>{textJson['指定红人']}</span>
                    <CloseCircleOutlined onClick={()=>setIsHotR(false)}
                        style={{color:'#888888', fontWeight:700, fontSize:'20px', cursor:'pointer'}} />
                </div>
                <div style={{width:'345px', display:'flex', margin:'0 auto', marginTop:'70px'}}>
                    <Input onChange={e=>handleSearch(e.target.value)}
                        style={{width:'294px', height:'48px', borderTopLeftRadius:'4px', borderBottomLeftRadius:'4px'}} 
                        placeholder={intl.get('SmallShop')['请输入红人昵称或账号搜索全网红人']} />
                    <div style={{width:'50px', height:'48px', borderTopRightRadius:'4px', borderBottomRightRadius:'4px', cursor:'pointer',
                        textAlign:'center', lineHeight:'45px', color: '#FFF',
                        background: '#F7385B', fontWeight:'blod' }} >
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sousuo.png'} style={{width:'16px'}} alt="" />
                    </div>
                </div>    
                <Spin spinning={fetching}>
                {searchList.length > 0 ? <div style={{width:'100%', marginTop:'25px' }}>
                    {
                        searchList?.map((item,index)=>{
                            return <div key={index} style={{width:'100%', height:'85px', display:'flex', alignItems:'center', justifyContent:'space-between'}}
                            >
                                <Image src={item.avatarMedium || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                                style={{ width: '44px', height: '44px', borderRadius: '22px' }} />
                                <div style={{width:'calc(100% - 54px)', height:'100%', display:'flex', alignItems:'center', justifyContent:'space-between',
                                    borderBottom:'1px dashed #CCCCCC'}}>
                                    <div style={{width:'160px'}}>
                                        <div className='over1' style={{fontWeight:600, color:'#333'}}>{item.nickname}</div>
                                        <div style={{fontSize:'12px', color:'#888888', marginTop:'-4px'}}>TikTok: {item.tiktok}</div>
                                    </div>  
                                    {objSub.cooperationWay == 1 ? <div style={{textAlign:'center'}}>
                                        <div style={{fontWeight:600, color:'#F7385B'}}>${three(item.videoQuotationDollar)}/m</div>
                                        <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['视频制作单价']}</div>
                                    </div>:
                                        <div style={{textAlign:'center'}}>
                                        <div style={{fontWeight:600, color:'#F7385B'}}>${three(item.liveBroadcastQuotationDollar)}/h</div>
                                        <div style={{fontSize:'12px', color:'#888888', marginTop:'-3px'}}>{textJson['直播制作单价']}</div>
                                    </div>}
                                    <div onClick={()=>addBloggerS(item,index)} style={{width:'85px', height:'28px', borderRadius:'4px', cursor:'pointer',
                                        textAlign:'center', lineHeight:'28px', color: '#FFF', fontSize:'12px',
                                        background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                        {textJson['确认添加']}
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div> :          
                <div style={{width:'345px', textAlign:'center', margin:'0 auto', marginTop:'100px' }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png'} style={{ width: 105 }} alt="" />
                    <div style={{marginTop:'24px', textAlign: 'center', color:'#333' }}>{textJson['暂无数据，请搜索添加']}</div>
                </div>  }        
                </Spin>           
            </div>
        </Drawer>                            







        <Modal centered visible={videoIndex > -1} footer={null}  closable={false}
            getContainer={() => document.getElementById('videoDemand')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF', 
                // width: 443, 
            }}
        >
            <div style={{textAlign:'center', position:'relative'}}>
                <video style={{width:'100%', height:'auto', display:'block'}} 
                    src={information?.videos[0]} controls="controls" autoPlay>
                    您的浏览器不支持 video 标签。
                </video>
                <div onClick={()=>setVideoIndex(-1)} style={{
                    width: '118px', height: '40px', background: '#FFF',
                    textAlign: 'center', lineHeight: '40px', color: '#666', borderRadius: '4px', margin: '0 auto',
                    cursor: 'pointer', marginTop: '26px', position:'absolute', right:'-144px', top:'50px'
                }}>关闭</div>
            </div>
        </Modal>



        <Modal centered visible={isRelease} footer={null} closable={false}
            getContainer={() => document.getElementById('videoDemand')}
            bodyStyle={{
                padding: '0', backgroundColor: '#FFFFFF', 
                height:466,
            }}
        >
            <div style={{textAlign:'center', paddingTop:'55px'}}>
                <h3 style={{fontSize:'18px', fontWeight:'bold'}}>{textJson['需求发布成功']}</h3>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} alt=""
                    style={{width:'173px', marginTop:'40px'}} />
                <div style={{padding:'0 80px'}}>{textJson['恭喜您需求发布成功，请耐心等待博主们的投稿申请，并选择心仪的博主进行合作']}</div>
                <div style={{width:'210px', height:'40px', borderRadius:'4px', cursor:'pointer',
                    textAlign:'center', lineHeight:'40px', color: '#FFF', margin:'38px auto',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }}
                    onClick={()=>objSub.listId !='' ? props.navigate(-2) :props.navigate('/index/videoDemandDetail', {replace: true , state: result})} >
                    {textJson['前往我发布的需求']}
                </div>
            </div>
        </Modal>
    </div>
}
export default withRouter(VideoDemand);