import React, { useState, useEffect, useRef } from 'react'
import { NavLink, Outlet } from "react-router-dom";
import Header from '../../components/Header'
import withRouter from '../../utils/withRouter'
import { Image, Input, Checkbox, Select, Carousel, notification, Form, Anchor } from 'antd';
import Slider from "react-slick";
import moment from 'moment';
import intl, { init } from 'react-intl-universal';
import * as Api from '../../service/https'
import store from "../../utils/redux";

const { Link } = Anchor;


const Home = (props)=>{
    const [textJson, setTextJson] = useState(intl.get('Index'))
    const intervalRef = useRef(null);
    const videos = useRef(null);
    const imgUrl = 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front'
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    useEffect(()=>{
        console.log(props)
        let code = props.location.search?.split('shareCode')[1]?.substring(1,)
        // console.log(code)
        if(code){
            Api.doRecord(code).then(res=>{
                if(res.code == 200){

                }
            })
        }
        // changeTime(0)
    },[])

    const [shiIndex, setShiIndex] = useState(-1)
    const [tabListIndex, setTabIndex] = useState(-1)
    const tabList = [
        {title: textJson['海量TK红人资源'], tip:textJson['智能匹配优质海外红人']},
        {title: textJson['热门短视频'], tip:textJson['发现TK热门爆款内容趋势']},
        {title:textJson['短视频创作与营销'], tip:textJson['品牌商家短视频创作撮合与推广']},
        {title:textJson['海外直播带货'], tip:textJson['海外直播代播，享受出海红利']},
    ]
    const [highlightsIndex, sethighlightsIndex] = useState(-1)
    const highlightsList = [
        {title:textJson['AI大数据分析'], tip:textJson['通过大数据智能筛选、推荐，品牌商&红人可以更高效、精准地相互匹配！']},
        {title:textJson['海量红人免费对接'], tip:textJson['红人库内现有红人已突破300万，品牌商可以自定义免费发布短视频&直播创作需求！']},
        {title:textJson['低门槛入驻'], tip:textJson['低广告投入，无需搭建专属团队，1v1专家 辅导，无忧开启TikTok变现之路！']},
        // {title:'纯佣带货模式 ', tip:'没有三方平台高昂流量成本，短视频&直播 带货按效果返佣，不花冤枉钱！'},
    ]
    const changeTime = (index)=>{
        intervalRef.current.slickGoTo(index)
        sethighlightsIndex(index)
    }
    const [painPoint, setPainPoint] = useState(false)

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        beforeChange: function(currentSlide, nextSlide) {
            //   console.log("before change", currentSlide, nextSlide);
            sethighlightsIndex(nextSlide)
        },
        afterChange: function(currentSlide) {
            //   console.log("after change", currentSlide);
        }
    };


    const [hotIndex, setHotIndex] = useState(0)
    const [hotItemIndex, setHotItemIndex] = useState(0)
    useEffect(()=>{
        setHotItemIndex(0)
    },[hotIndex])
    const hotList = [
        {title: intl.get('Workbench').BloggerRanking, list: [
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-useast5-avt-0068-tx/5919c31d26416a1a795bb264549a3e14~c5_720x720.jpeg.jpg', name:'charli d’amelio', tikId: 'charlidamelio', fans: '140.40M', videos: '2.17K', dig: '10.90B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-maliva-avt-0068/30107337091e90a33da10d6ce1815bb1~c5_720x720.jpeg.jpg', name:'Khabane lame', tikId: 'khaby.lame', fans: '137.40M', videos: '1.05K', dig: '2.20B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-maliva-avt-0068/86fdd9ca882015440001a66fe397a572~c5_720x720.jpeg.jpg', name:'Bella Poarch', tikId: 'bellapoarch', fans: '89.50M', videos: 465, dig: '2.10B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-useast5-avt-0068-tx/96b163655816acaca78b0d70a6de7939~c5_720x720.jpeg.jpg', name:'Addison Rae', tikId: 'addisonre', fans: '87.50M', videos: '1.66K', dig: '1.40B'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/musically-maliva-obj/1646315618666501~c5_720x720.jpeg.jpg', name:'Will Smith', tikId: 'willsmith', fans: '71.80M', videos: 136, dig: '487.60M'},
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/aweme/720x720/tos-alisg-avt-0068/smgf5f369c884044a8df770614bbfd64717.jpeg.jpg', name:'Zach King', tikId: 'zachking', fans: '68.40M', videos: 334, dig: '867.90M'},
        ]},
        {title: intl.get('Workbench').VideoLeaderboard, list: [
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-alisg-p-0037/7294cf8bc1d6487f852a1dfc248eed7b_1652015600.jpg', name:"I'm trying new things❤ Lisa💖 ib@_taekookiey_ #blink #blackpink #bp #blackpink_blinke #lisa #lalisamanoban #la", palys: '6.29K', dig: '1.17K', comments: 44, time:'0:26' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/17276996b934456f8622eec495c02740.jpg', name:'😳This is one of my best drawings #dlaciebie #tiktokart #drawing #fyp', palys: '277.70M', dig: '50.00M', comments: '834.90K', time:'0:31' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/a796b541712c44fb987c119250cb7db1.jpg', name:'Ig:@khaby00', palys: '275.10M', dig: '37.80M', comments: '408.60K', time:'0:25' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/0f1b16b79efa43a8993872a50415a715_1625066209.jpg', name:'Cm on bro 🤲🏾🤲🏾🤲🏾🤲🏾🤲🏾!! #learnfromkhaby #learnwithtiktok #imparacontiktok #khabylame', palys: '350.00M', dig: '36.10M', comments: '338.70K', time:'0:17' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/abe2b9fc9e7b459a96ac5ce857e8d81d_1623247635.jpg', name:'Lol 🤦🏿‍♂️ #learnfromkhaby #LearnWithTikTok #ImparaConTikTok', palys: '297.70M', dig: '35.40M', comments: '220.30K', time:'0:26' },
            {logo: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/tos-maliva-p-0068/aabf9583b2d54de088dcd6daafce35a2~tplv-dmt-logom:tos-maliva-p-0000/aa3948f3b5594186a782b3ab1301c48b.image.jpg', name:'Spirited Away', palys: '292.90M', dig: '34.90M', comments: '230.30K', time:'0:25' },
        ]},
        {title: intl.get('Workbench').TopicRanking, list: [
            {logo: '', name:'foryou', upDate:'2022-05-05 09:05:15', videos: '1.20B', palys: '15196.80B', dig: '30.75K' },
            {logo: '', name:'foryoupage', upDate:'2022-04-27 04:53:45', videos: '897.20M', palys: '9623.00B', dig: '20.08K' },
            {logo: '', name:'fypシ', upDate:'2022-04-24 06:00:59', videos: '592.90M', palys: '7358.00B', dig: '9.68K' },
            {logo: '', name:'parati', upDate:'2022-05-05 09:39:51', videos: '342.90M', palys: '3537.60B', dig: '27.62K' },
            {logo: '', name:'tiktok', upDate:'2022.03.22 18:19:30', videos: '330.90M', palys: '2950.70B', dig: '11.54K'},
            {logo: '', name:'fy', upDate:'2022-04-26 01:17:39', videos: '216.20M', palys: '2899.40B', dig: '23.33K' },
        ]},
    ]


    const [tikAllIndex, setTikALlIndex] = useState(-1)
    const tikALlList = [
        {title: textJson['TikTok网红收录'], tip:textJson['强大的数据采集能力，覆盖图文、视频、直播等有效数据反馈及实时存储与查询']},
        {title: textJson['网红数据对比分析'], tip:textJson['10万+博主收录，一键对比查看各维度数据分析，高效筛选你心仪的带货达人']},
        {title: textJson['视频话题内容趋势'], tip:textJson['针对流量红利视频和话题进行深度挖掘梳理，形成多维透视体系，同步进行排序']},
        {title: textJson['创意短视频营销'], tip:textJson['针对商家提出的需求，博主基于流量历史趋势、 营销模型、品牌渗透力等进行短视频创作']},
        {title: textJson['直播带货助力推广'], tip:textJson['基于在库品牌和商品，博主利用直播高效转化私域流量，助力品牌商品内容推广']},
        {title: textJson['1对1专属服务'], tip:textJson['为每一位商家或博主配置专属客服，进行精准的服务，针对给出可落地的解决方案']},
    ]



    const [majorIndex, setMajorIndex] = useState(0)
    const majorList = [
        {title: textJson['电子数码'], imgB: imgUrl+'/img/homeImg/dianzishumas.jpg',
            video: imgUrl+'/img/videos/dianzishuma.mp4'},
        {title: textJson['游戏电竞'], imgB: imgUrl+'/img/homeImg/youxidianjins.jpg',
            video: imgUrl+'/img/videos/youxidianjin.mp4'},
        {title: textJson['时尚生活'], imgB: imgUrl+'/img/homeImg/shishangshenhuos.jpg',
            video: imgUrl+'/img/videos/shishangshenhuo.mp4'},
        {title: textJson['美妆首饰'], imgB: imgUrl+'/img/homeImg/meizhuangshoushis.jpg',
            video: imgUrl+'/img/videos/meizhuangshoushi.mp4'},
        {title: textJson['服装服饰'], imgB: imgUrl+'/img/homeImg/fuzhuangfushis.jpg',
            video: imgUrl+'/img/videos/fuzhuangfushi.mp4'},
        {title: textJson['运动健康'], imgB: imgUrl+'/img/homeImg/yundongjiankangs.jpg',
            video: imgUrl+'/img/videos/yundongjiankang.mp4'},
    ]


    const gotoIndex = (index)=>{
        if (localStorage.getItem('token') != 'null' && localStorage.getItem('token') != null) {
            if(index==0)props.navigate('/index/bloggerRanking', {state: '/rangKing'})
            if(index==1)props.navigate('/index/videoRanking', {state: '/rangKing'})
            if(index==2)props.navigate('/index/topicList', {state: '/rangKing'})
        }else{
            props.navigate('/login')
        }
    }



    const [isPlay, setIsPlay] = useState(false)
    const onChange = (link) => {
        // console.log('Anchor:OnChange', link);
        if(link && link!='') {
            setIsPlay(true)
        }else{
            setIsPlay(false)
        }
        // setMajorIndex(majorIndex)
    };




    return <div style={{minWidth:'1420px', letterSpacing:'1px'}}>
        {
            props.location.pathname!='/drainage' && props.location.pathname!='/perfect' &&
            props.location.pathname!='/login' ?
            <Header /> :''
        }

        {props.location.pathname =='/' ? <div id='home'>

            <nav style={{width:'100%', position:'relative'}}>
                <img style={{width:'100%', height:'calc(100vh - 66px)'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/homeBack.jpg'} />
                {/* <img style={{width:'100%', height:'calc(100vh - 66px)'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/homeMian.png'} /> */}
                <div style={{width:'100%', height:'calc(100vh - 66px)', position:'absolute', top:'0', left: '0', 
                textAlign:'center', paddingTop:'20vh'}}>
                    <h1 style={{color: 'white', fontSize:'60px', lineHeight:'58px'}}>跨境兴趣电商供应链服务平台</h1>
                    {
                        1 ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '80px'}}>
                        <div style={{
                            boxShadow: '0px 0px 10px 0px #111111',
                            borderRadius: '6px',
                            fontSize: '16px',
                            fontFamily: 'PingFang SC',
                            width: '150px',
                            height: '50px',
                            background: 'rgba(255,255,255,0.5)',
                            color: '#FFF',
                            fontWeight: '500',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                             onClick={() => props.navigate('/login')}>
                            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shangjia.png" alt=""
                                 style={{width: '25px', marginRight: '9px'}}/> 商家登录
                        </div>
                        <div style={{width: '66px'}}></div>
                        <div style={{
                            boxShadow: '0px 0px 10px 0px #111111',
                            borderRadius: '6px',
                            fontSize: '16px',
                            fontFamily: 'PingFang SC',
                            width: '150px',
                            height: '50px',
                            background: 'rgba(255,255,255,0.5)',
                            color: '#FFF',
                            fontWeight: '500',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                             onClick={() => window.open('https://master.brandgogo.com/login')}>
                            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/daren.png" alt=""
                                 style={{width: '29px', marginRight: '7px'}}/> 达人登录
                        </div>
                    </div> : ''
                    }
                    <img style={{width:'308px', marginTop:'26vh'}} src={imgUrl+'/img/logo.png'} />
                    <p style={{color:'white', fontWeight: 500, fontSize:'16px', marginTop:'5px'}}>链接优质创作者，帮助中国品牌货通全球</p>
                </div>
            </nav>

        </div> :
        <Outlet />}

        {props.location.pathname=='/' || props.location.pathname=='/starSelection' ? <div>
            <div style={{width:'100%', height:'322px', background:'#111'}}>
                <div style={{width:'1280px', margin:'0 auto', paddingTop:'50px', color:"#FFF"}}>
                    <div style={{display:'flex', justifyContent:'space-between', padding: lang=='zh'?0:'0 70px'}}>
                        <div style={{width:'360px'}}>
                            <div style={{fontSize:'16px', fontWeight:'bold', marginBottom:'20px'}}>{textJson['关于我们']}</div>
                            <div style={{color:'#ABABAB', lineHeight:lang == 'en'?'13px':'18px'}}>{textJson['desc']}</div>
                        </div>
                        <div style={{width:'150px'}}>
                            <div style={{fontSize:'16px', fontWeight:'bold', marginBottom:'20px'}}>{textJson['产品功能']}</div>
                            <div style={{color:'#ABABAB'}}>
                                <div>{intl.get('Header')['Home']}</div>
                                <div>{intl.get('Header')['博主']}</div>
                                <div>{intl.get('Header')['视频']}</div>
                                <div>{intl.get('Header')['话题']}</div>
                                <div>{intl.get('Workbench')['CooperativeLibrary']}</div>
                                <div>{intl.get('Workbench')['HoldAll']}</div>
                            </div>
                        </div>
                        {lang == 'zh' ? <div style={{width:'280px'}}>
                            <div style={{fontSize:'16px', fontWeight:'bold', marginBottom:'20px'}}>{textJson['合作咨询']}</div>
                            <div style={{color:'#ABABAB'}}>
                                <div style={{display:'flex', alignItems:'center'}}>{lang == 'en' ? 'phone' : <div><span style={{marginRight:'30px'}}>电</span>话</div>}：18621685028</div>
                                <div style={{display:'flex', alignItems:'center'}}>{lang == 'en' ? 'email': <div><span style={{marginRight:'30px'}}>邮</span>箱</div>}：xuwenjie88@dingtalk.com</div>
                                <div>{textJson['商务合作']}：13295120166</div>
                                <div>{textJson['渠道合作']}：lihui6100@126.com</div>
                                <div>{textJson['箴创学园']}：www.ikj360.com</div>
                            </div>
                        </div> :
                        <div style={{width:'290px'}}>
                            <div style={{fontSize:'16px', fontWeight:'bold', marginBottom:'20px'}}>{textJson['合作咨询']}</div>
                            <div style={{color:'#ABABAB'}}>
                                <div style={{display:'flex', alignItems:'center'}}>Whatsapp: +86 19951025256</div>
                                <div style={{display:'flex', alignItems:'center'}}><div style={{opacity:0, marginRight:'6px'}}>Whatsapp: </div> +86 19906232856</div>
                                <div style={{display:'flex', alignItems:'center'}}>Email: jerelinn@brandgogo.com</div>
                                <div style={{display:'flex', alignItems:'center'}}><div style={{opacity:0, marginRight:'4px'}}>Email:</div>chenjiayi@brandgogo.com</div>
                            </div>
                        </div>}
                        {lang == 'zh' ? <div style={{width:'330px', height:'145px', display:'flex', justifyContent:'space-between', textAlign:'center'}}>
                            <section style={{width:'125px', background:'rgba(255,255,255,0.1)', paddingTop:'6px', borderRadius:'8px'}}>
                                <img style={{width:'109px', borderRadius:'8px'}} src={'https://zcxy.oss-cn-beijing.aliyuncs.com/rts/img/zcxygzh.png'} />
                                <div style={{marginTop:'8px', lineHeight:'13px'}}>{textJson['微信公众号']}</div>
                            </section>
                            <section style={{width:'125px', background:'rgba(255,255,255,0.1)', paddingTop:'6px', borderRadius:'8px'}}>
                                <img style={{width:'109px', borderRadius:'8px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/homeImg/BrandGoGOcode.png'} />
                                <div style={{marginTop:'8px 0', borderRadius:'8px', lineHeight:'13px'}}>官方小二</div>
                            </section>
                        </div>:''}
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'50px'}}>
                        <span style={{fontSize:'20px', fontWeight:500}}>友情连接：</span>
                        <a href="https://www.brandgogo.com/"><div style={{width:'133px', height:'30px', color:'#FFF', background:'rgba(255,255,255,0.14)', borderRadius:'5px',
                        textAlign:'center', lineHeight:'30px', fontWeight:500, marginRight:'15px'}}>BrandGoGo</div></a>
                        <a href="https://www.ikj360.com/"><div style={{width:'133px', height:'30px', color:'#FFF', background:'rgba(255,255,255,0.14)', borderRadius:'5px',
                        textAlign:'center', lineHeight:'30px', fontWeight:500, marginRight:'15px'}}>箴创学园</div></a>
                        <a href="https://www.proseer.com/"><div style={{width:'133px', height:'30px', color:'#FFF', background:'rgba(255,255,255,0.14)', borderRadius:'5px',
                        textAlign:'center', lineHeight:'30px', fontWeight:500, marginRight:'15px'}}>选品大师</div></a>
                        <a href="https://www.hubstudio.cn/"><div style={{width:'133px', height:'30px', color:'#FFF', background:'rgba(255,255,255,0.14)', borderRadius:'5px',
                        textAlign:'center', lineHeight:'30px', fontWeight:500, marginRight:'15px'}}>HubStudio</div></a>
                        <a href="https://www.ziniao.com/"><div style={{width:'133px', height:'30px', color:'#FFF', background:'rgba(255,255,255,0.14)', borderRadius:'5px',
                        textAlign:'center', lineHeight:'30px', fontWeight:500, marginRight:'15px'}}>紫鸟浏览器</div></a>
                    </div>
                </div>
            </div>


            <div style={{width:'100%', height:'116px', paddingTop:'50px', textAlign:'center', color:'rgba(255,255,255,0.6)',
                backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/beian.png)', backgroundSize:'100% 100%', background:'#111',
                backgroundRepeat:'no-repeat'}}>
                Copyright ©2021 <a style={{color:'rgba(255,255,255,0.6)'}} href={'https://beian.miit.gov.cn/#/Integrated/index'} target='_blank'>苏ICP备19001576号</a>
                苏州箴创信息科技有限公司版权所有
                <br/>增值电信业务经营许可证编号：<a style={{color:'rgba(255,255,255,0.6)'}} href={'https://beian.miit.gov.cn/#/Integrated/index'} target='_blank'>苏B2-20211086 苏公网安备 32050111125255号</a>
            </div>
        </div>:''}

    </div>
}

export default withRouter(Home);
