import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Input, message, Select, Switch, Image, ImageProps, Modal, Upload, DatePicker, Button } from 'antd';
import {CloseCircleOutlined, CloseOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import store from '../../../utils/redux/index'
import { debounce } from '../../../utils/debounce'
import {editMyGoods} from "../../../service/https";
import openNotificationWithIcon from "../../../components/Notification";
import { color } from 'echarts';
import moment from 'moment';
import { baseUrl } from '../../../service/request'
import ImgCrop from 'antd-img-crop';
const { RangePicker } = DatePicker;

const { Option } = Select

const ShopAccount = (props) => {

    useEffect(()=>{
        infoShop()
        shopType()
        infoCompany()
    },[])
    const [shopInfo, setShopInfo] = useState()
    const infoShop = e => {
        Api.infoShop().then(res=>{
            if(res.code == 200){
                setShopInfo(res.data)
            }
        })
    }
    const [shopTypeList, setShopTypeList] = useState([]);
    const shopType = ()=>{
        Api.shopType().then(res=>{
            if(res.code == 200){
                setShopTypeList(res.data)
            }
        })
    }
    const [companyInfo, setCompanyInfo] = useState();
    const infoCompany = ()=>{
        Api.infoCompany().then(res=>{
            if(res.code == 200){
                setCompanyInfo(res.data)
            }
        })
    }

    const [active_index, active_indexSet] = useState(-1)
    const tabsChange = e => {
        active_indexSet(e)
    }


    const uploadProps = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        showUploadList: false,
        headers: {
            'x-token': localStorage.getItem('token')
        },
        async beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setTimeout(() => {
                    // let t = JSON.parse(JSON.stringify(userInfo))
                    // t.avatar = info.file.response.data
                    // setUserInfo(t)
                    // redSetup(5, info.file.response.data)
                    Api.addAvatar({avatar: info.file.response.data}).then(res=>{
                        if(res.code == 200){
                            infoShop()
                        }
                    })
                }, 1000);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };


    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
    };

    const [isApply, setApply] = useState(false)
    const onChangeInfo = (key, val) => {
        let obj = JSON.parse(JSON.stringify(shopInfo))
        obj[key] = val
        setShopInfo(obj)
    };
    const putShop = () => {
        Api.putShop(shopInfo).then(res=>{
            if(res.code == 200){
                setApply(false)
                openNotificationWithIcon('success', '修改成功！')
                shopInfo()
            }
        })
    };
    const [isPayApply, setPayApply] = useState(false)
    const [payoneerID, setPayoneerID] = useState()
    const postPay = (key, val) => {
        Api.setPay({payType:1, account: payoneerID}).then(res=>{
            if(res.code == 200){
                setPayApply(false)
                openNotificationWithIcon('success', '修改成功！')
                shopInfo()
            }
        })
    };

    return (
        <div id='MyGoods'>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>店铺资料</div>
            </div>
            <div className='tabs' style={{marginTop:'12px'}}>
                <div className={active_index === -1 ? 'active' : ''} onClick={e => tabsChange(-1)}>店铺信息</div>
                <div className={active_index === 0 ? 'active' : ''} onClick={e => tabsChange(0)}>支付设置</div>
                {/* <div className={active_index === 1 ? 'active' : ''} onClick={e => tabsChange(1)}>账号安全</div> */}
            </div>

            {active_index == -1 ? <div>
                <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
                    <div style={{fontSize:'20px', fontWeight:600}}>基础信息</div>
                    {shopInfo ? <div style={{marginTop:'30px', display:'flex'}}>
                        <div>
                            <div style={{fontWeight:500}}>店铺头像</div>
                            <div style={{width:'100px', textAlign:'center', marginTop:'10px'}}>
                                <div>
                                    <Image src={shopInfo.avatar||''} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'}
                                    style={{ width: '100px', height: '100px', borderRadius: '50px', objectFit:'cover' }} />
                                </div>
                                <ImgCrop aspect={1/1} rotate >
                                    <Upload {...uploadProps}>
                                        <div style={{ color: '#F7385B', cursor: 'pointer', border:'1px solid #F7385B',
                                        marginTop:'10px', padding:'3px 10px', borderRadius:'4px' }}>
                                            {["上传头像"]}
                                        </div>
                                    </Upload>
                                </ImgCrop>
                            </div>
                        </div>
                        <div style={{marginLeft:'80px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'300px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'250px', color:'#666'}}>店铺编码：<span style={{color:'#111'}}>{shopInfo.code}</span></div>
                                </div>
                                <div style={{width:'300px', display:'flex', alignItems:'center', marginLeft:'100px'}}>
                                    <div style={{width:'250px', color:'#666'}}>邮箱地址：<span style={{color:'#111'}}>{shopInfo.email||'--'}</span></div>
                                    <span style={{color:'#F7385B', cursor:'pointer'}} onClick={()=>setApply(true)}>更改</span>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
                                <div style={{width:'300px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'250px', color:'#666'}}>店铺名称：<span style={{color:'#111'}}>{shopInfo.name||'--'}</span></div>
                                    <span style={{color:'#F7385B', cursor:'pointer'}} onClick={()=>setApply(true)}>更改</span>
                                </div>
                                <div style={{width:'300px', display:'flex', alignItems:'center', marginLeft:'100px'}}>
                                    <div style={{width:'250px', color:'#666'}}>电话号码：<span style={{color:'#111'}}>{shopInfo.phone||'--'}</span></div>
                                    <span style={{color:'#F7385B', cursor:'pointer'}} onClick={()=>setApply(true)}>更改</span>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
                                <div style={{width:'300px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'250px', color:'#666'}}>经营类型：<span style={{color:'#111'}}>跨境卖家</span></div>
                                </div>
                                <div style={{width:'300px', display:'flex', alignItems:'center', marginLeft:'100px'}}>
                                    <div style={{width:'250px', color:'#666'}}>商家类型：<span style={{color:'#111'}}>{shopTypeList.filter(item=>item.code == shopInfo.shopType)[0]?.desc || ''}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>:''}
                </div>

                <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
                    <div style={{fontSize:'20px', fontWeight:600}}>公司信息</div>
                    {companyInfo ? <div style={{marginTop:'30px', display:'flex'}}>
                        <div style={{marginLeft:'0px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'400px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'100%', color:'#666'}}>公司名称：<span style={{color:'#111'}}>{companyInfo.company}</span></div>
                                </div>
                                <div style={{width:'400px', display:'flex', alignItems:'center', marginLeft:'100px'}}>
                                    <div style={{width:'100%', color:'#666'}}>社会统一信用代码：<span style={{color:'#111'}}>{companyInfo.creditCode}</span></div>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
                                <div style={{width:'400px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'100%', color:'#666'}}>法人姓名：<span style={{color:'#111'}}>{companyInfo.legalPerson}</span></div>
                                </div>
                                <div style={{width:'400px', display:'flex', alignItems:'center', marginLeft:'100px'}}>
                                    <div style={{width:'100%', color:'#666'}}>公司主体所在地：<span style={{color:'#111'}}>{companyInfo.mainLocation}</span></div>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
                                <div style={{width:'400px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'100%', color:'#666'}}>法人证据类型：<span style={{color:'#111'}}>身份证</span></div>
                                </div>
                                <div style={{width:'400px', display:'flex', alignItems:'center', marginLeft:'100px'}}>
                                    <div style={{width:'100%', color:'#666'}}>法人身份证号：<span style={{color:'#111'}}>{companyInfo.legalPersonCard}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>:''}
                </div>
            </div> :
            active_index == 0 ? <div>
                <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{fontSize:'20px', fontWeight:600}}>结算账号信息</div>
                        <div style={{width:'98px', height:'34px',
                            border:'1px solid #F7385B',
                            borderRadius:'6px', color: '#F7385B', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}
                            onClick={()=>setPayApply(true)}>
                            更改账号
                        </div>
                    </div>
                    <div style={{marginTop:'30px'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'300px', display:'flex', alignItems:'center'}}>
                                <div style={{width:'250px', color:'#666', display:'flex', alignItems:'center'}}>
                                    状态：<div style={{width:'6px', height:'6px', background:'#40D782', borderRadius:'50%', marginRight:'5px'}}></div>
                                    <span style={{color:'#111'}}>绑定成功</span>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
                            <div style={{width:'300px', display:'flex', alignItems:'center'}}>
                                <div style={{width:'250px', color:'#666'}}>支付方式：<span style={{color:'#111'}}>PAYONEER</span></div>
                            </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginTop:'20px'}}>
                            <div style={{width:'300px', display:'flex', alignItems:'center'}}>
                                <div style={{width:'250px', color:'#666'}}>Payoneer ID：<span style={{color:'#111'}}>{shopInfo.shopPayList[0].account}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
            // active_index == 1 ? <div>
            //     <div style={{width:'100%', background:'#FFF', padding:'20px 24px', marginTop:'10px'}}>
            //         <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            //             <div style={{fontSize:'20px', fontWeight:600}}>结算账号信息</div>
            //         </div>
            //         <div style={{marginTop:'30px'}}>
            //             <div style={{width:'100%', padding:'15px 20px', background:'#f6f6f6', borderRadius:'5px',
            //             display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            //                 <div>
            //                     <span style={{fontWeight:500, fontSize:'16px'}}>短信</span>
            //                     <div style={{fontSize:'12px', fontWeight:500, color:'#666'}}>通过手机短信接收验证码</div>
            //                 </div>
            //                 <Switch defaultChecked={false} onChange={onChange} />
            //             </div>
            //             <div style={{width:'100%', padding:'15px 20px', background:'#f6f6f6', borderRadius:'5px',
            //             display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'10px'}}>
            //                 <div>
            //                     <span style={{fontWeight:500, fontSize:'16px'}}>邮件</span>
            //                     <div style={{fontSize:'12px', fontWeight:500, color:'#666'}}>通过邮件接收验证码</div>
            //                 </div>
            //                 <Switch defaultChecked={false} onChange={onChange} />
            //             </div>
            //         </div>
            //     </div>
            // </div> :
            ''}



            <div id='wallet'>
                <Modal centered visible={isApply} footer={null} closable={false}
                    getContainer={() => document.getElementById('wallet')}
                    bodyStyle={{
                        padding: '0', backgroundColor: '#FFFFFF',
                        // width: 554,
                    }}
                >
                    {shopInfo?<div style={{ textAlign: 'center', padding: '57px 55px' }}>
                        <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{['修改资料']}</h3>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '30px', fontWeight: 500
                        }}>
                            {['店铺名称']}
                        </div>
                        <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Input style={{ width:'410px',  height: '48px', borderRadius: '4px' }} placeholder={['请输入店铺名称']}
                                onChange={e => onChangeInfo('name',e.target.value.trim())} value={shopInfo.name} />
                        </div>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '10px', fontWeight: 500
                        }}>
                            {['邮箱地址']}
                        </div>
                        <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={['请输入邮箱地址']}
                                onChange={e => onChangeInfo('email',e.target.value.trim())} value={shopInfo.email} />
                        </div>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '10px', fontWeight: 500
                        }}>
                            {['电话号码']}
                        </div>
                        <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Input style={{ height: '48px', borderRadius: '4px' }} placeholder={['请输入电话号码']}
                                onChange={e => onChangeInfo('phone',e.target.value.trim())} value={shopInfo.phone} />
                        </div>
                        <Button style={{
                            width: '210px', height: '40px', borderRadius: '4px', border: 0,
                            textAlign: 'center', color: '#FFF', margin: '0 auto', marginBottom: '12px',
                            background: false ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod', marginTop: '48px'
                        }}
                            onClick={()=>putShop()}>
                            {['确定修改']}
                        </Button>
                        <CloseCircleOutlined style={{
                            position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                            cursor: 'pointer'
                        }}
                            onClick={() => setApply(false)} />
                    </div>:''}
                </Modal>
                <Modal centered visible={isPayApply} footer={null} closable={false}
                    getContainer={() => document.getElementById('wallet')}
                    bodyStyle={{
                        padding: '0', backgroundColor: '#FFFFFF',
                        // width: 554,
                    }}
                >
                    {shopInfo?<div style={{ textAlign: 'center', padding: '57px 55px' }}>
                        <h3 style={{ fontSize: '18px', fontWeight: 'bold' }}>{['支付修改']}</h3>
                        <div style={{
                            color: '#666666', display: 'flex', alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginTop: '30px', fontWeight: 500
                        }}>
                            {['Payoneer账号']}
                        </div>
                        <div style={{ marginTop: '2px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Input style={{ width:'410px',  height: '48px', borderRadius: '4px' }} placeholder={['请输入Payoneer账号']}
                                onChange={e => setPayoneerID(e.target.value.trim())} value={payoneerID} />
                        </div>
                        <Button style={{
                            width: '210px', height: '40px', borderRadius: '4px', border: 0,
                            textAlign: 'center', color: '#FFF', margin: '0 auto', marginBottom: '12px',
                            background: false ? '#EEEEEE' : 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight: 'blod', marginTop: '48px'
                        }}
                            onClick={()=>postPay()}>
                            {['确定修改']}
                        </Button>
                        <CloseCircleOutlined style={{
                            position: 'absolute', right: '29px', top: '29px', fontSize: '20px', color: '#888888',
                            cursor: 'pointer'
                        }}
                            onClick={() => setPayApply(false)} />
                    </div>:''}
                </Modal>
            </div>

        </div>
    )
}
export default withRouter(ShopAccount);
