import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import {message, Checkbox, Image, Spin, Divider, List, InputNumber } from 'antd';
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import * as Api from '../../service/https';
import '../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import InfiniteScroll from 'react-infinite-scroll-component';
import store from '../../utils/redux/index'
import toKMB from "../../utils/KMB";
import {getGoodsRankingList} from "../../service/https";
import moment from 'moment';
import three from "../../utils/three";
import openNotificationWithIcon from '../../components/Notification'
import ShareCommonModel from "../../components/Share/ShareCommonModel";
import Header from "../../components/Header";

const GoodsLists = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [state, setSate] = useState(store.getState())
    const storeChange = () => setSate(store.getState())
    store.subscribe(storeChange)
    const shareObj = {
        shareName:'GoodsRanking',
        topTitle: intl.get('ShareModel')['销量'],
        topDayType: intl.get('ShareModel')['日榜'],
        itemImg:'cover',
        itemTitle:'productName',
        itemLeftTag: intl.get('ShareModel')['销量'],
        itemLeftValue: 'soldCount',
        itemLeftType:'number',
        itemLeftImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoliang.png?1111',
        itemRightTag:intl.get('ShareModel')['销售额'],
        itemRightValue:'soldTotal',
        itemRightType:'number',
        itemRightImg:'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/goods/xiaoshoue.png?1111',
    }
    const [goods_lists, goods_listsSet] = useState([])
    const [tabShow, setTabShow] = useState(false)
    const [goodsType, goodsTypeSet] = useState([])
    const [tabIndex, setTabIndex] = useState(-1)
    const [sortList] = useState([{title:intl.get('GoodsRanking')['价格'],value:'minPrice'}, {title:intl.get('GoodsRanking')['销量'],value:'soldCount'}, {title:intl.get('GoodsRanking')['销售额'],value:'soldTotal'}]);
    const [sortIndex, setSortIndex] = useState(1)
    const [pageIndex, setPageIndex] = useState(1)
    const [total, setTotal] = useState(0)
    const [sortWay, sortWaySet] = useState(2)
    const [loading, setLoading] = useState(false)
    const [isBig, setBig] = useState(false)
    const [widths, setWidths] = useState(0)
    const [price_index, price_indexSet] = useState(-1)
    const [time_index, time_indexSet] = useState(-1)
    const [priceMin, priceMinSet] = useState()
    const [priceMax, priceMaxSet] = useState()
    const [classIndex, setClassIndex] = useState(-1)

    const price_list = [{
        priceMin: '0',
        priceMax: '100',
        name: '≤100'
    }, {
        priceMin: '100',
        priceMax: '200',
        name: '100~200'
    }, {
        priceMin: '200',
        priceMax: '500',
        name: '200~500'
    }, {
        priceMin: '500',
        priceMax: '1000',
        name: '500~1000'
    }, {
        priceMin: '1000',
        priceMax: '',
        name: '≥1000'
    }]

    const time_list = [{name:intl.get('GoodsRanking')['近6小时'], val:-6, type:'h'},
        {name:intl.get('GoodsRanking')['近12小时'], val:-12, type:'h'},
        {name:intl.get('GoodsRanking')['近24小时'], val:-24, type:'h'},
        {name:intl.get('GoodsRanking')['近3天'], val:-3, type:'d'},
        {name:intl.get('GoodsRanking')['近7天'], val:-7, type:'d'},
        {name:intl.get('GoodsRanking')['近15天'], val:-15, type:'d'},
        {name:intl.get('GoodsRanking')['近30天'], val:-30, type:'d'},
        {name:intl.get('GoodsRanking')['近90天'], val:-90, type:'d'}
    ]

    // 生成图片自动下载为png格式（将dom转为二进制再编译下载）
    const [isShare, setIsShare] = useState(false)
    const getBlobPng = () => {
        setIsShare(!isShare)
    }

    useEffect(() => {
        getCountry()
        // getCategoryAll()
        heightFun()
        window.addEventListener('resize', heightFun)
        return () => {
            window.removeEventListener('resize', heightFun)
        }
    }, [])

    useEffect(() => {
        getGoodsList()
    }, [tabIndex, classIndex, sortIndex, sortWay, price_index, time_index])

    const heightFun = () => {
        let width = document.documentElement.clientWidth
        let num = Number(state.collapsed) + (width > 1890 ? 6 : width > 1650 ? 5 : 4)
        setWidths(num)
    }

    const getCategoryAll = () => {
        Api.getCategoryAll().then(res => {
            if (res.code === 200) goodsTypeSet(res.data)
        })
    }
    // soldCount soldTotal minPrice
    const getGoodsList = () => {
        if(loading) return
        setLoading(true)
        let data = {
            countryCode:tabIndex == -1 ? '' : geolocation[tabIndex].code,
            // categoryId1: classIndex === -1 ? 0 : goodsType[classIndex]?.id,
            minPrice: price_index === -1 ? '' : price_index <= -2 ? priceMin || '' : price_list[price_index].priceMin,
            maxPrice: price_index === -1 ? '' : price_index <= -2 ? priceMax || '' : price_list[price_index].priceMax,
            time: time_index > -1 ? moment().add(time_list[time_index].val, time_list[time_index].type).format("YYYY-MM-DD HH:mm:ss") :'',
            // timeScope:3,
            [sortWay == 2 ? 'orderByDesc' : 'orderByAsc']: sortIndex > -1 ? sortList[sortIndex].value : ''
        }
        console.log(price_index,price_index === -1 ? '' : price_index <= -2 ? priceMax || '' : price_list[price_index].priceMax || '');
        let params = {current: pageIndex, size: 20}
        Api.getGoodsRankingList(data,params).then(res => {
            if (res.code === 200) {
                setTotal(res.data.total)
                goods_listsSet(pageIndex <= 1 ? res.data.records : [...goods_lists, ...res.data.records])
                if (shareData.length === 0) setShareData(res.data.records)
                setPageIndex(pageIndex + 1)
            }
            setLoading(false)
        })
    }
    const [geolocation, setGeolocation] = useState([])
    const getCountry = ()=>{
        Api.getShopCountry().then(res=>{
            if(res.code == 200){
                let all = res.data
                setGeolocation(all)
            }
        })
    }
    const changeTab = (index) => {
        setPageIndex(1)
        setTabIndex(index)
    }

    const changeClass = (index) => {
        setPageIndex(1)
        setClassIndex(index)
    }

    const price_indexChange = (e, index) => {
        setPageIndex(1)
        if (index === -2) {
            if (price_index === -2) {
                // getGoodsList()
                price_indexSet(-3)
            } else {
                price_indexSet(-2)
            }
        }
        else {
            price_indexSet(e.target.checked ? index : -1)
        }
    }

    const time_indexChange = (e, index) => {
        setPageIndex(1)
        time_indexSet(e.target.checked ? index : -1)
    }

    const sortChange = e => {
        setPageIndex(1)
        setSortIndex(e);
        if(e === sortIndex) sortWaySet(sortWay === 1 ? 2 : 1)
    }

    const toGoodsDetail = item => {
        window.open(`/index/GoodsRankingDetail/${item.id}/${item.productId}`)
    }

    const [shareData, setShareData] = useState([]);

    const addCollection = (e, item, index) => {
        e.stopPropagation()
        Api.collectGoodsRanking({id: item.id || ''}).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', intl.get('GoodsRanking')[item.collect ? '取消成功！' : '收藏成功！'])
                let t = JSON.parse(JSON.stringify(goods_lists))
                t[index].collect = !t[index].collect
                goods_listsSet(t)
            }
        })
    }

    const loadMoreData = () => {
        getGoodsList()
    }

    // 别表
    const SmallDiv = (item, index) => {
        return (
            <div key={index} style={{
                width: '100%', height: '100px', display: 'flex', alignItems: 'center',
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F6F6'
            }}>
                <div style={{ width: '10%', paddingLeft: '19px' }}>
                    {
                        index < 3 ? <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/' + (index + 1) + '.png'} alt="" />
                            : <div style={{ width: '32px', textAlign: 'center', fontWeight: 500 }}>{index + 1}</div>
                    }
                </div>
                <div style={{ width: '30%', display: 'flex', alignItems: 'center', paddingRight: '70px' }}>
                    <Image src={item.cover || ''} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                        style={{ width: '60px', height: '60px', marginRight: '16px' }} preview={false} />
                    <div>
                        <div className='over' style={{ height: '25px', fontWeight: 'bold', fontSize: '16px', lineHeight: '25px' }}>{item.productName}</div>
                        {/*<span style={{display : 'inline-block',marginTop : '12px', fontWeight: '400', fontSize: '12px', padding: '3px 12px', color: '#F7385B', backgroundColor : 'rgba(247,56,91,.1)', borderRadius : '10px'}}>商品标签</span>*/}
                    </div>
                </div>
                <div className="flex-ac" style={{ width: '10%' }}><img src={item.countryUrl} alt="" style={{width:'20px',height:'14px',marginRight:'6px'}} />{lang === 'zh' ? item.country : item.countryCode}</div>
                <div style={{ width: '10%' }}>{item.currencyStr}{three(item.minPrice)}</div>
                {/*<div style={{ width: '7.5%' }}></div>*/}
                <div style={{ width: '10%' }}>{toKMB(item.soldCount,2)}</div>
                <div style={{ width: '14%' }}>{item.currencyStr}{three(item.soldTotal)}</div>
                <div style={{ width: '10%' }}>{item?.bloggerCount}</div>
                {
                    state.userInfo?.userType == 1 ? <div style={{width: '10%'}}>
                        <img onClick={(e) => addCollection(e, item, index)}
                             src={`https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/sc${item.collect ? 's' : ''}.png`}
                             alt=""
                        />
                    </div> : ''
                }
            </div>
        )
    }

    const BigDiv = (item, index) => {
        return (
            <div className='BigDivs' style={{
                width: '240px', backgroundColor: '#FFF', margin: '0 auto',
                marginBottom: widths === 6 ? '8px' : widths === 5 ? '12px' : widths === 4 ? '18px' : '', borderRadius: '4px',
            }}>
                <div style={{
                    width: '100%', position: 'relative', backgroundColor: 'rgb(246,246,246)',
                    borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                }}>
                    <Image src={item.cover} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}
                        style={{ width: '240px', height: '240px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }} onClick={e => e.stopPropagation()} />
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgb(0,0,0,0.22)',
                        display: 'flex', flexFlow: 'column', justifyContent: 'space-between', position: 'absolute', top: 0,
                        borderTopLeftRadius: '4px', borderTopRightRadius: '4px',
                    }}>
                        <div style={{ textAlign: 'right', paddingTop: '14px', paddingRight: '10px' }}>
                            {
                                item.collect ?
                                    <img
                                        onClick={(e) => addCollection(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCBS.png'}
                                        alt=""
                                    />
                                    : <img
                                        onClick={(e) => addCollection(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/SCB.png'}
                                        alt=""
                                    />
                            }
                        </div>
                        <div style={{ width: '100%', padding: '0 14px 38px' }}>
                            <div style={{ width: '100%', display: 'flex', color: '#FFF', alignItems: 'center' }}>
                                <div className='over1' style={{fontWeight: 'bold', fontSize: '14px', }}>{item.productName}</div>
                            </div>
                            {/*<div style={{ color: '#F6F6F6', opacity: '0.7', fontWeight: 400 }}>{item.productName}</div>*/}
                        </div>
                    </div>
                </div>
                <div className="flex-acjb"  style={{ width: '100%', height: '44px', padding: '16px 14px' }}>
                    <div style={{ color: '#F7385B', fontWeight: '800', fontSize: '14px' }}>{item.currencyStr}{three(item.minPrice)}</div>
                    <div className="flex-ac"><img src={item.countryUrl} alt="" style={{width:'20px',height:'14px',marginRight:'6px'}}/>{lang === 'zh' ? item.country : item.countryCode}</div>
                </div>
                <div style={{
                    width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    textAlign: 'center',borderTop: '1px dashed #EEEEEE',
                }}>
                    {/*<div style={{ width: '100px' }}>*/}
                    {/*    <strong style={{ fontSize: '16px' }}>{toKMB(item.minPrice, 2)}</strong>*/}
                    {/*    <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('GoodsRanking')['佣金率']}</p>*/}
                    {/*</div>*/}
                    <div style={{ width: '100px' }}>
                        <strong style={{ fontSize: '16px' }}>{toKMB(item.soldCount, 2)}</strong>
                        <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('GoodsRanking')['销量']}</p>
                    </div>
                    <div style={{ width: '100px' }}>
                        <strong style={{ fontSize: '16px' }}>{item.currencyStr}{three(item.soldTotal, 2)}</strong>
                        <p style={{ fontSize: '12px', color: '#888888', marginBottom: '4px' }}>{intl.get('GoodsRanking')['销售额']}</p>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div id='GoodsRanking'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                        style={{ width: '190px' }} />
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{intl.get('Workbench').GoodsRanking}</span>
                    <div style={{
                        width: '166px', height: '48px', textAlign: 'center', lineHeight: '48px',
                        backgroundImage: 'url(' + 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/back.png' + ')', backgroundSize: '100% 100%',
                        color: '#FFF', marginLeft: '34px', paddingRight: '10px', cursor: 'pointer'
                    }}
                         onClick={getBlobPng}>{intl.get('GoodsRanking')['分享商品榜单']}</div>
                </div>
            </nav>

            <div style={{
                width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '25px',
                fontWeight: 500
            }}>
                {/* 筛选国家 */}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px', textAlign: 'right',paddingRight:'20px'}}>{intl.get('BloggerRanking').Country}：</label>
                    <div style={{
                        width: 'calc(100% - 70px)', height: tabShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                        overflow: tabShow ? '' : 'hidden'
                    }}>
                    <span className='tabItem' style={{
                        padding: '6px 10px',
                        background: -1 == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                        color: -1 == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                        cursor: 'pointer'
                    }}
                          onClick={() => changeTab(-1)}>{lang=='zh'?'全部':'All'}</span>
                        {
                            geolocation?.map((item, index) => {
                                return <span key={index} className='tabItem' style={{
                                    padding: '6px 10px',
                                    background: index == tabIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                    color: index == tabIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                    cursor: 'pointer'
                                }}
                                             onClick={() => changeTab(index)}>{lang == 'zh' ?item.name:item.enName}</span>
                            })
                        }
                    </div>
                    <div style={{ width: '45px' }}>
                        {!tabShow ?
                            <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                               onClick={() => { setTabShow(true) }} />
                            :
                            <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                             onClick={() => setTabShow(false)} />}
                    </div>
                </div>
                {/*筛选商品类型 目前没有的*/}
                {/*<div style={{ width: '100%', display: 'flex' }}>*/}
                {/*    <label style={{ width: state.language == 'zh' ? '102px' : '90px', marginTop: '6px',textAlign: 'right',paddingRight:'26px' }}>*/}
                {/*        {intl.get('GoodsRanking')['商品类型：']}*/}
                {/*    </label>*/}
                {/*    <div style={{*/}
                {/*        width: 'calc(100% - 70px)', height: tabShow ? 'auto' : '34px', display: 'flex', flexWrap: 'wrap',*/}
                {/*        overflow: tabShow ? '' : 'hidden'*/}
                {/*    }}>*/}
                {/*        <span*/}
                {/*            className='tabItem'*/}
                {/*            style={{*/}
                {/*                padding: '6px 10px',*/}
                {/*                marginBottom: '5px',*/}
                {/*                borderRadius: '4px',*/}
                {/*                marginRight: '10px',*/}
                {/*                cursor: 'pointer',*/}
                {/*                color: classIndex === -1 ? '#E94753' : '#333',*/}
                {/*                background: classIndex === -1 ? '#FFE7E9' : 'none',*/}
                {/*            }}*/}
                {/*            onClick={() => changeClass(-1)}*/}
                {/*        >*/}
                {/*            {intl.get('GoodsRanking')['全部']}*/}
                {/*        </span>*/}
                {/*        {*/}
                {/*            goodsType.map((item, index) => (*/}
                {/*                <span*/}
                {/*                    key={index}*/}
                {/*                    className='tabItem'*/}
                {/*                    style={{*/}
                {/*                        padding: '6px 10px',*/}
                {/*                        marginBottom: '5px',*/}
                {/*                        borderRadius: '4px',*/}
                {/*                        marginRight: '10px',*/}
                {/*                        cursor: 'pointer',*/}
                {/*                        color: index === classIndex ? '#E94753' : '#333',*/}
                {/*                        background: index === classIndex ? '#FFE7E9' : 'none',*/}
                {/*                    }}*/}
                {/*                    onClick={() => changeClass(index)}*/}
                {/*                >*/}
                {/*                    {lang === 'zh' ? item.name: item.enName}*/}
                {/*                </span>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*    <div style={{ width: '45px', paddingTop: '8px' }}>*/}
                {/*        {*/}
                {/*            !tabShow ?*/}
                {/*                <DownSquareTwoTone style={{ fontSize: '18px', cursor: 'pointer' }} twoToneColor='#CCCCCC'*/}
                {/*                    onClick={() => setTabShow(true)} />*/}
                {/*                :*/}
                {/*                <UpSquareTwoTone style={{ fontSize: '18px', cursor: 'pointer' }} twoToneColor='#CCCCCC'*/}
                {/*                    onClick={() => setTabShow(false)} />*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*筛选商品价格*/}
                <div style={{ width: '100%', lineHeight: '30px', display: 'flex', marginTop: '15px' }}>
                    <label style={{ width: state.language == 'zh' ? '112px' : '90px', textAlign: 'right',paddingRight:'30px'}}>
                        {intl.get('GoodsRanking')['商品价格：']}
                    </label>
                    <div style={{
                        width: 'calc(100% - 70px)', display: 'flex', flexWrap: 'wrap',
                        overflow: tabShow ? '' : 'hidden'
                    }}>
                        {
                            price_list?.map((item, index) => (
                                <Checkbox key={index} style={{ marginRight: '30px', width: '110px' }} checked={index === price_index} onChange={e => price_indexChange(e, index)}>
                                    {item.name}
                                </Checkbox>
                            ))
                        }
                        <div style={{ display: 'flex', height:'32px' }}>
                            <div style={{ marginRight: '20px' }}>{intl.get('GoodsRanking')['自定义']}</div>
                            <InputNumber controls={false} placeholder={intl.get('GoodsRanking')['最小值']} style={{ width: 80 }} value={priceMin} onChange={e => priceMinSet(e)} />
                            <div style={{ margin: '0 5px' }}>~</div>
                            <InputNumber controls={false} placeholder={intl.get('GoodsRanking')['最大值']} style={{ width: 80 }} value={priceMax} onChange={e => priceMaxSet(e)} />
                            <div
                                style={{
                                    lineHeight: '32px',
                                    padding: '0 10px',
                                    fontSize: '12px',
                                    marginLeft: '10px',
                                    borderRadius: '3px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    color: '#FFF',
                                    background: 'linear-gradient(180deg, #F7385B, #FF716E)'
                                }}
                                onClick={e => price_indexChange(e, -2)}
                            >{intl.get('GoodsRanking')['确定']}</div>
                        </div>
                    </div>
                </div>
                {/*筛选时间*/}
                <div style={{ width: '100%', lineHeight: '30px', display: 'flex', marginTop: '15px' }}>
                    <label style={{ width: state.language == 'zh' ? '102px' : '90px', textAlign: 'right',paddingRight:'20px'}}>
                        {intl.get('GoodsRanking')['时间：']}
                    </label>
                    <div style={{
                        width: 'calc(100% - 70px)', display: 'flex', flexWrap: 'wrap',
                        overflow: tabShow ? '' : 'hidden'
                    }}>
                        {
                            time_list?.map((item, index) => (
                                <Checkbox key={index} style={{ marginRight: '30px', width: '110px' }} checked={index === time_index} onChange={e => time_indexChange(e, index)}>
                                    {item.name}
                                </Checkbox>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/*排序*/}
            <div style={{
                width: '100%', height: '60px', backgroundColor: '#FFF', marginTop: '10px',
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '47px',
                paddingRight: '24px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {

                        sortList?.map((item, index) => {
                            return <div key={index} className='active' style={{
                                display: 'flex', alignItems: 'center', marginRight: '36px',
                                color: sortIndex === index ? '#F7385B' : '#888888', cursor: 'pointer'
                            }} onClick={() => sortChange(index)}>
                                {item.title}
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex === index && sortWay === 1 ? 's' : '') + '.png'} alt=""
                                     style={{ marginLeft: '6px', marginTop: '2px', transform: 'rotate(180deg)' }} />
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortIndex === index && sortWay === 2 ? 's' : '') + '.png'} alt=""
                                     style={{ marginLeft: '2px', marginTop: '2px' }} />
                            </div>
                        })
                    }
                </div>
                <div>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/datu' + (isBig ? 's' : '') + '.png'} alt=""
                        style={{ cursor: 'pointer' }} onClick={() => setBig(true)} />
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/lb' + (!isBig ? 's' : '') + '.png'} alt=""
                        style={{ marginLeft: '6px', cursor: 'pointer' }}
                        onClick={() => setBig(false)} />
                </div>
            </div>
            {/*列表*/}
            <div style={{ width: '100%', backgroundColor: isBig ? '' : '#FFFFFF', padding: isBig ? '0 10px' : '24px 24px 24px 30px', marginTop: isBig ? 0 : '10px' }}>
                <div style={{ width: '100%', height: '50px', display: isBig ? 'none' : 'flex', alignItems: 'center', backgroundColor: '#F6F6F6', fontWeight: 500 }}>
                    <div style={{ width: '10%', paddingLeft: '19px' }}>{intl.get('GoodsRanking')['排名']}</div>
                    <div style={{ width: '30%', paddingLeft: '14px',paddingRight: '70px' }}>{intl.get('GoodsRanking')['商品']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['国家']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['价格']}</div>
                    {/*<div style={{ width: '7.5%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['佣金率']}</div>*/}
                    <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['销量']}</div>
                    <div style={{ width: '14%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['销售额']}</div>
                    <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['关联博主数']}</div>
                    {/*<div style={{ width: '7.5%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['关联视频数']}</div>*/}
                    {state.userInfo?.userType == 1 ? <div style={{ width: '10%', paddingRight: '10px' }}>{intl.get('GoodsRanking')['操作']}</div> : ''}
                </div>
                <Spin tip="数据加载中..." spinning={loading}>
                    <InfiniteScroll
                        dataLength={goods_lists.length}
                        scrollThreshold={'20px'}
                        next={loadMoreData}
                        hasMore={total > 0 && goods_lists.length < total}
                        endMessage={state.userInfo?.userType == 1 && state.userInfo?.memberDetail?.memberType === '领航版' ? <Divider plain>{intl.get('BloggerRanking').Nothing} 🤐</Divider> : ''}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            dataSource={goods_lists}
                            grid={{gutter: 0, column: isBig ? widths : 1}}
                            renderItem={
                                (item, index) =>
                                    <div style={{ paddingTop: isBig ? '10px' : 0 }}>
                                        {isBig ? BigDiv(item, index) : SmallDiv(item, index)}
                                    </div>
                            }
                        />
                    </InfiniteScroll>
                </Spin>
                {
                    state.userInfo?.userType == 1 && state.userInfo?.memberStatus != 0 ?
                        state.userInfo?.memberDetail?.memberType === '起航版' && total <= goods_lists.length ?
                            <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                     style={{ width: '24px', marginRight: '7px' }} />
                                {intl.get('TopicList')['起航版员目前仅支持查看前200条数据']}
                                {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                            {intl.get('BloggerRanking').ViewMoreData} */}
                            </div>
                            :
                            state.userInfo?.memberDetail?.memberType === '远航版' && total <= goods_lists.length ?
                                <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                         style={{ width: '24px', marginRight: '7px' }} />
                                    {intl.get('TopicList')['远航版员目前仅支持查看前500条数据']}
                                    {/* <span style={{ color: '#F7385B', cursor: 'pointer' }}>{intl.get('BloggerRanking').OpenMember}</span>
                                {intl.get('BloggerRanking').ViewMoreData} */}
                                </div>
                                :
                                ""
                        :
                        state.userInfo?.userType == 1 ?
                            <div style={{ justifyContent: 'center', fontWeight: '500', display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suo.png'} alt="" onClick={() => props.navigate('/index/member')}
                                     style={{ width: '24px', marginRight: '7px' }} />
                                {intl.get('BloggerRanking').NonMembers} <span style={{ color: '#F7385B', cursor: 'pointer' }} onClick={() => props.navigate('/index/member')}>{intl.get('BloggerRanking').OpenMember}</span>
                                {intl.get('BloggerRanking').ViewMoreData}
                            </div> : ''
                }
            </div>
            {isShare ? <ShareCommonModel clickFun={() => getBlobPng(false)} dataList={shareData} shareObj={shareObj}  /> : ''}
        </div>
    )
}

export default withRouter(GoodsLists);
