import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { Input, Upload, Image, Button, Radio, Space, message, Cascader, DatePicker, Select, Modal } from 'antd';
import {baseUrl} from '../../service/request'
import intl from 'react-intl-universal';
import * as Api from '../../service/https'
import store from '../../utils/redux/index'
import moment from 'moment';
const {Option} = Select

const Perfect = (props)=>{

    useEffect(()=>{
        console.log(props)
        shopType()
        businessScale()
        addressOption()
    },[])

    const stepsList = [['企业主体信息'], ['法人代表信息'], ['资质证明文件']]
    const [stepsIndex, setStepsIndex] = useState(-1);

    const [information, setInformation] = useState({
        // logo:'', name:'', linkman:'', whatsApp:'', scale: '', operatingYears:'', cargoClassifications:[],
        // logo:'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/yyzz.png',
        uid: props.location.state.uid, shopType: null, businessLicense:'', creditCode:'', company:'',
        provinceList: [], province:'', city:'', district:'',
        address:'', establishDate:'', businessLicenseDate: null,
        workProvinceList: [], workProvince:'', workCity:'', workDistrict:'',
        workAddress: '', businessScale:null,

        legalPersonCardPositive:'', legalPersonCardSide:'', legalPerson:'',
        legalPersonCard:'', legalPersonCardDate:'', holdCardPositive:'',
        qualificationDocument:[]

    })
    const changeInfo = (key, val) =>{
        let obj = JSON.parse(JSON.stringify(information))
        obj[key] = val
        setInformation(obj)
    }

    const [tabList, setTabList] = useState([]);
    const shopType = ()=>{
        Api.shopType().then(res=>{
            if(res.code == 200){
                setTabList(res.data)
            }
        })
    }
    const [businessScaleList, setBusinessScaleList] = useState([]);
    const businessScale = ()=>{
        Api.businessScale().then(res=>{
            if(res.code == 200){
                setBusinessScaleList(res.data)
            }
        })
    }
    const [addressOptionList, setAddressOptionList] = useState([]);
    const addressOption = ()=>{
        Api.addressOption().then(res=>{
            if(res.code == 200){
                setAddressOptionList(res.data)
            }
        })
    }


    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState('true')
    const fileListsLogo = {
        name: 'multipartFiles',
        action: baseUrl + '/basic/img/upload',
        headers: {
            'x-token': localStorage.getItem('token'),
        },
        showUploadList: false,
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
            if (!isJpgOrPng) {
                message.error('请上传正确的图片！');
            }
            const isLt2M = file.size / 1024 / 1024 < 10;
            if (!isLt2M) {
                message.error('图片过大，请上传10MB以下的图片!');
            }
            return isJpgOrPng && isLt2M;
        },
        onChange({ file, fileList }) {
            if (file.status === 'uploading') {
                if(loading){
                    message.loading(`上传中...`, 0);
                    // console.log(file, fileList);
                }
                setLoading(false)
            }
            if (file.status === 'done') {
                // setTimeout(() => {
                    console.log(title)
                    if(file.response.code == 200){
                        if(title=='qualificationDocument'){
                            let imgList = information.qualificationDocument
                            imgList.push(file.response.data)
                            changeInfo(title, imgList)
                        }else{
                            changeInfo(title, file.response.data)
                        }
                        message.destroy()
                        setLoading(true)
                        message.success(`${file.name}上传成功！`);
                    }
                // }, 1000);
            } else if (file.status === 'error') {
                message.destroy()
                setLoading(true)
                message.error(`${file.name}上传失败！`);
            }
        }
    };

    const delImg = (index) => {
        let imgList = information.qualificationDocument
        imgList.splice(index,1)
        changeInfo('qualificationDocument', imgList)
    };

    const onChangeAddress = (value) => {
        console.log(value);
        changeInfo('provinceList', value == undefined ? null : value)
    };
    // useEffect(()=>{
    //     if(information.provinceList&&information.provinceList.length > 0){
    //         va
    //     }
    // },[information.provinceList])
    const onChangeBanAddress = (value) => {
        console.log(value);
        changeInfo('workProvinceList', value == undefined ? null : value)
    };


    const onBusinessLicenseDate = (date, dateString) => {
        console.log(date, dateString);
        var time = ''
        if(date){
            time = moment(date).format("YYYY-MM-DD")
        }
        changeInfo('businessLicenseDate', time)
    };
    const onEstablishDate = (date, dateString) => {
        console.log(date, dateString);
        var time = ''
        if(date){
            time = moment(date).format("YYYY-MM-DD")
        }
        changeInfo('establishDate', time)
    };
    const onLegalPersonCardDate = (date, dateString) => {
        console.log(date, dateString);
        var time = ''
        if(date){
            time = moment(date).format("YYYY-MM-DD")
        }
        changeInfo('legalPersonCardDate', time)
    };

    const [isSuccess, setIsSuccess] = useState(false)

    const isNext1 = ()=>{
        return information.uid && information.shopType && information.businessLicense && information.creditCode && information.company
        && information.provinceList.length > 0
        && information.address && information.establishDate
        && information.workProvinceList.length > 0
        && information.workAddress && information.businessScale
    }
    const isNext2 = ()=>{
        return information.legalPersonCardPositive && information.legalPersonCardSide && information.legalPerson
        && information.legalPersonCard.length == 18 && information.legalPersonCardDate && information.holdCardPositive
    }
    const isNext3 = ()=>{
        return true
        // return information.qualificationDocument.length > 0
    }
    const isNext = ()=>{
        return stepsIndex== 0 ? isNext1() : stepsIndex == 1 ? isNext1()&&isNext2() : isNext1()&&isNext2()&&isNext3()
    }
    const next = () => {
        var obj1 = information
        // if(stepsIndex == 0){
        //     [obj1.province, obj1.city, obj1.district] = obj1.provinceList
        //     [obj1.workProvince, obj1.workCity, obj1.workDistrict] = obj1.workProvinceList
        // }
        console.log(obj1);
        setStepsIndex(stepsIndex+1)
    };
    const subPost = () => {
        var obj1 = information;
        [obj1.province, obj1.city, obj1.district] = obj1.provinceList;
        [obj1.workProvince, obj1.workCity, obj1.workDistrict] = obj1.workProvinceList;
        console.log(obj1);
        Api.resource(obj1).then(res=>{
            if(res.code == 200){
                setIsSuccess(true)
            }
        })
    };

    return <div style={{width:'100%', background:'#F6F6F6', paddingTop:'86px'}}>
        <div style={{width:'100%', height:'66px', letterSpacing:'0.5px', userSelect:'none', backgroundColor: '#111', boxShadow: '0px 0px 10px 0px #F8F8F8',
            position: 'fixed', zIndex: 2, top: 0, left: 0, color:'#FFF', }}>

            <div style={{
                width: '1400px', height: '66px', display: 'flex', alignItems: 'center', margin:'0 auto',
                justifyContent:'space-between', padding:'0 10px'
            }}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <img style={{width:'190px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logo.png'} alt="" />
                    <span style={{marginLeft:'78px'}}>商家管理中心</span>
                </div>
                {/*<div onClick={()=>props.navigate('/login')} style={{width:'98px', height:'34px', */}
                {/*    border:'1px solid #F7F7F7',*/}
                {/*    borderRadius:'6px', color: '#FFFFFF', textAlign:'center', lineHeight:'32px', cursor:'pointer'}}>*/}
                {/*    卖家课堂*/}
                {/*</div>*/}

            </div>
        </div>

        <div style={{width: stepsIndex == -1 ? '960px' : '1400px', minHeight:'calc(100vh - 86px)', background:'#FFF',
        margin:'0 auto'}}>
            <div style={{textAlign:'center', paddingTop:'30px'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/yl.png" alt=""
                    style={{width:'26px', marginRight:'5px'}}/>
                    <span style={{fontSize:'16px', fontWeight:500, color:'#F7385B'}}>请继续完善入驻信息</span>
                </div>
                {stepsIndex == -1 ? <div>
                    <div style={{fontSize:'30px', fontWeight:600, marginTop:'10px'}}>大陆主体入驻</div>
                    <div style={{fontSize:'16px', fontWeight:500, color:'#666666'}}>针对大陆注册的企业，适合企业入驻，提供企业营业执照等即可开店</div>
                </div>:''}
            </div>

            {
                stepsIndex == -1 ? <div style={{width:'470px', margin:'0 auto', marginTop:'56px'}}>
                    <Radio.Group onChange={e=> changeInfo('shopType', e.target.value)} value={information.shopType}>
                        <Space direction="vertical">
                            {
                                tabList.map((item,index)=>{
                                    var isChecked = item.open&&information.shopType==item.code
                                    return <Radio value={item.code} key={index} disabled={!item.open}>
                                        <div style={{width:'430px', height:'72px', position:'relative', top:'-12px',
                                        borderLeft: isChecked?'4px solid #F7385B':'',
                                        border: isChecked?'':'1px solid #EEEEEE', borderRadius:'3px', fontWeight:500, paddingTop:'8px',
                                        paddingLeft:'40px', marginLeft:'4px', marginBottom:'8px', background:item.open?information.shopType==item.code?'#FFEEF1':'':'#F6F6F6'}}>
                                            <span style={{fontSize:'18px', color: isChecked?'#F7385B':'#111'}}>{item.desc}{item.open?'':'（暂未开放）'}</span>
                                            <div style={{color: isChecked?'#F7385B':'#888888'}}>{item.subDesc}</div>
                                        </div>
                                    </Radio>
                                })
                            }
                        </Space>
                    </Radio.Group>
                    <Button className='sub' disabled={information.shopType==null} style={{ width:'430px', height:'45px', borderRadius:'4px', border:0, marginLeft:'28px',
                        background: information.shopType==null?'#EEEEEE':'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:500, letterSpacing:'2px',
                        color:'#FFF'}}
                        onClick={()=>setStepsIndex(0)}>下一步
                    </Button>
                </div> :
                stepsIndex > -1 ? <div style={{width:'100%', padding:'0 160px', marginTop:'30px'}}>
                    <div style={{width:'70%', margin:'0 auto', display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'30px', paddingLeft:'40px'}}>
                        {
                            stepsList.map((item,index)=>{
                                return <div key={index} style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'20px', minWidth:'20px', height:'20px', borderRadius:'50%', background: index<=stepsIndex?'#F7385B':'#CCC',
                                        color:'#FFF', lineHeight:'20px', fontWeight:'bold', textAlign:'center'}}>{index+1}</div>
                                    <span style={{color: index==stepsIndex?'#333':index<stepsIndex?'#F7385B':'#CCC' ,marginLeft:'5px', marginRight:'45px', fontWeight: 'bold', fontSize:'18px'}}>{item}</span>
                                    {index<2?<img style={{width:'59px', marginRight:'8px'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/'+(index<stepsIndex?'youcai':'youhui')+'.png'} />:''}
                                </div>
                            })
                        }
                    </div>
                    {
                        stepsIndex == 0 ? <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                            <div id='perfect' style={{width:'739px', marginTop:'48px'}}>
                                <div>
                                    <div style={{width:'130px', minWidth:'130px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>营业执照信息</div>
                                    {information?.businessLicense ? <div style={{maxWidth:'220px', marginLeft:'15px', marginTop:'7px', position:'relative'}}>
                                        <Image src={information?.businessLicense} style={{maxWidth:'220px'}}/>
                                        <div style={{width:'16px', height:'16px', borderRadius:'50%', background:'#FFEEF1', color:'#F7385B', cursor:'pointer',
                                        boxShadow:'0px 0px 6px 0px #A9A9A9', position:'absolute', top:'-8px', right:'-8px', textAlign:'center', lineHeight:'14px',
                                        fontSize:'12px', fontWeight:600}}
                                        onClick={()=> changeInfo('businessLicense', '')}>
                                            x
                                        </div>
                                    </div>:
                                    <div style={{width:'100%', display:'flex', alignItems:'center', position:'relative', marginLeft:'15px', marginTop:'7px'}}>
                                        <div onClick={()=>setTitle('businessLicense')}>
                                            <Upload {...fileListsLogo}>
                                                <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                                    textAlign:'center', lineHeight:'38px', color: '#FFF', fontSize:'14px',
                                                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                                    {['上传营业执照']}
                                                </div>
                                            </Upload>
                                        </div>
                                        <div style={{color:'#B3B3B3', fontSize:'12px', marginLeft:'13px',
                                        top:'6px', left:'90px'}}>仅支持JPG、JPEG 、PNG格式</div>
                                    </div>}
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['统一社会信用代码']}</div>
                                    <Input onChange={e=>changeInfo('creditCode', e.target.value.trim())} value={information.creditCode}
                                    style={{width:'739px', height:'48px', borderRadius:'4px'}} placeholder={['请输入统一社会信用代码（请保证您填写的信息与营业执照信息一致）']} />
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['公司名称']}</div>
                                    <Input onChange={e=>changeInfo('company', e.target.value.trim())} value={information.company}
                                    style={{width:'739px', height:'48px', borderRadius:'4px'}} placeholder={['请输入公司名称（请保证您填写的信息与营业执照信息一致）']} />
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['营业执照地址']}</div>
                                    <div style={{width:'739px', display:'flex', justifyContent:'space-between'}}>
                                        <Cascader style={{width:'227px', height:'48px'}} onChange={onChangeAddress} value={
                                            information?.provinceList
                                        }
                                        options={addressOptionList} fieldNames={{
                                            label: 'areaName',
                                            value: 'areaName',
                                            children: 'childList',
                                        }}
                                        placeholder={'请选择省-市-区'}/>
                                        <Input onChange={e=>changeInfo('address', e.target.value.trim())} value={information.address}
                                        style={{width:'505px', height:'48px', borderRadius:'4px'}} placeholder={['请输入具体的街道门牌']} />
                                    </div>
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['公司成立日期']}</div>
                                    <DatePicker style={{width:'739px', height:'48px'}} value={
                                    information?.establishDate ?moment(information?.establishDate , 'YYYY-MM-DD'):''}
                                    onChange={onEstablishDate} placeholder={'请选择注册日期'} />
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['营业执照有效期']}</div>
                                    <Radio.Group value={information.businessLicenseDate == null ? 1 : 0}>
                                        <Space>
                                            <Radio value={0} >
                                                <DatePicker style={{width:'362px', height:'48px'}} value={
                                                information?.businessLicenseDate ?moment(information?.businessLicenseDate , 'YYYY-MM-DD'):''}
                                                onChange={onBusinessLicenseDate} placeholder={'请选择营业执照有效期'} />
                                            </Radio>
                                            <Radio value={1} onChange={e=> changeInfo('businessLicenseDate', null)}><span style={{fontWeight:600}}>长期有效</span></Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['办公地址']}</div>
                                    <div style={{width:'739px', display:'flex', justifyContent:'space-between'}}>
                                        <Cascader style={{width:'227px', height:'48px'}} onChange={onChangeBanAddress} value={
                                        information?.workProvinceList}
                                        options={addressOptionList} fieldNames={{
                                            label: 'areaName',
                                            value: 'areaName',
                                            children: 'childList',
                                        }}
                                        placeholder={'请选择省-市-区'}/>
                                        <Input onChange={e=>changeInfo('workAddress', e.target.value.trim())} value={information.workAddress}
                                        style={{width:'505px', height:'48px', borderRadius:'4px'}} placeholder={['请输入具体的街道门牌']} />
                                    </div>
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['经营规模']}</div>
                                    <Select allowClear placeholder={'请选择经营规模'} onChange={e=>changeInfo('businessScale', e)}
                                    style={{ width: 739, height: 48 }} value={information.businessScale}>
                                        {
                                            businessScaleList.map((item,index)=>{
                                                return <Option value={item.code} key={index}>{item.desc}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </div>

                            <div style={{width:'250px', marginTop:'78px'}}>
                                <div style={{ width:'100%', height:'50px', fontWeight:600, fontSize:'16px', textAlign:'center',
                                backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/steps1.png)',
                                backgroundSize:'100% 100%', lineHeight:'50px'}}>营业执照注意事项</div>
                                <div style={{width:'100%', border:'1px solid #EEEEEE', borderTop:0, color:'#666',
                                fontWeight:500, fontSize:'12px', padding:'16px'}}>
                                    <div style={{color:'#333', marginBottom:'2px'}}>请您按照以下要求填写新主体信息，如您没有符合填写要求，您的申请可能会被拒绝。</div>
                                    <div>1.请确保营业执照图片字体清晰，边角完整，无任何无关水印，不可故意遮盖二维码或信息若拍摄模糊建议上传扫描件。</div>
                                    <div>2.请您先自行在国家企业信用信息公示系统查询您填写的统一社会信用代码/注册号，确保能查询到您的主体信息，且登记状态为“存续(在营、开业、在册)”。</div>
                                    <div>3.请确保营业执照图片中的公司名称、统一社会信用代码、法定代表人与您填写的对应字段信息完全一致。</div>
                                    <div>4.若公司名称或法定代表人等信息发生过变更，请填写最新信息，上传最新核准日期的营业执照图片。</div>
                                    <div>
                                        <p style={{fontSize:'12px', fontWeight:600, marginTop:'19px', color:'#333'}}>示例图</p>
                                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/yyzz.png" alt=""
                                        style={{width:'100%'}}/>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        stepsIndex == 1 ? <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                            <div id='perfect' style={{width:'739px', marginTop:'48px'}}>
                                <div>
                                    <div style={{fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>法定代表人身份证照</div>
                                    <div style={{display:'flex', marginTop:'13px'}}>
                                        <div style={{width:'222px', textAlign:'center'}}>
                                            {information?.legalPersonCardPositive ? <div style={{width:'222px', height:'140px', marginLeft:'15px', marginTop:'7px', position:'relative',
                                            border:'1px solid #EEEEEE', padding:'7px 9px', borderRadius:'3px'}}>
                                                <Image src={information?.legalPersonCardPositive} style={{width:'204px', height:'126px'}}/>
                                                <div style={{width:'16px', height:'16px', borderRadius:'50%', background:'#FFEEF1', color:'#F7385B', cursor:'pointer',
                                                boxShadow:'0px 0px 6px 0px #A9A9A9', position:'absolute', top:'-8px', right:'-8px', textAlign:'center', lineHeight:'14px',
                                                fontSize:'12px', fontWeight:600}}
                                                onClick={()=> changeInfo('legalPersonCardPositive', '')}>
                                                    x
                                                </div>
                                            </div>:
                                            <div style={{width:'100%', height:'140px', position:'relative', marginTop:'7px', border:'1px solid #EEEEEE', borderRadius:'3px'}}>
                                                <div style={{}} onClick={()=>setTitle('legalPersonCardPositive')}>
                                                    <Upload {...fileListsLogo}>
                                                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/tou.png" alt=""
                                                        style={{width:'100%', height:'100%', cursor:'pointer'}}/>
                                                    </Upload>
                                                </div>
                                            </div>}
                                            <div style={{color:'#666666', fontSize:'12px', marginTop:'14px', fontWeight:500}}>点击上传身份证人像面</div>
                                        </div>
                                        <div style={{width:'222px', textAlign:'center', marginLeft:'19px'}}>
                                            {information?.legalPersonCardSide ? <div style={{width:'222px', height:'140px', marginLeft:'15px', marginTop:'7px', position:'relative',
                                            border:'1px solid #EEEEEE', padding:'7px 9px', borderRadius:'3px'}}>
                                                <Image src={information?.legalPersonCardSide} style={{width:'204px', height:'126px'}}/>
                                                <div style={{width:'16px', height:'16px', borderRadius:'50%', background:'#FFEEF1', color:'#F7385B', cursor:'pointer',
                                                boxShadow:'0px 0px 6px 0px #A9A9A9', position:'absolute', top:'-8px', right:'-8px', textAlign:'center', lineHeight:'14px',
                                                fontSize:'12px', fontWeight:600}}
                                                onClick={()=> changeInfo('legalPersonCardSide', '')}>
                                                    x
                                                </div>
                                            </div>:
                                            <div style={{width:'100%', position:'relative', marginTop:'7px', border:'1px solid #EEEEEE', borderRadius:'3px'}}>
                                                <div style={{}} onClick={()=>setTitle('legalPersonCardSide')}>
                                                    <Upload {...fileListsLogo}>
                                                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/hui.png" alt=""
                                                        style={{width:'100%', cursor:'pointer'}}/>
                                                    </Upload>
                                                </div>
                                            </div>}
                                            <div style={{color:'#666666', fontSize:'12px', marginTop:'14px', fontWeight:500}}>点击上传身份证国徽面</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{marginTop:'28px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['法定代表人姓名']}</div>
                                    <Input onChange={e=>changeInfo('legalPerson', e.target.value.trim())} value={information.legalPerson}
                                    style={{width:'739px', height:'48px', borderRadius:'4px'}} placeholder={['请输入法定代表人姓名']} />
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['法定代表人身份证号']}</div>
                                    <Input onChange={e=>changeInfo('legalPersonCard', e.target.value.trim())} value={information.legalPersonCard}
                                    style={{width:'739px', height:'48px', borderRadius:'4px'}} placeholder={['请输入法定代表人身份证号']} />
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['身份证有效期']}</div>
                                    <DatePicker style={{width:'739px', height:'48px'}} value={
                                    information?.legalPersonCardDate ?moment(information?.legalPersonCardDate , 'YYYY-MM-DD'):''}
                                    onChange={onLegalPersonCardDate} placeholder={'请选择身份证有效期'} />
                                </div>
                                <div style={{marginTop:'20px'}}>
                                    <div style={{fontWeight: 600, marginBottom:'6px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>{['法定代表人手持身份证照']}</div>
                                    <div style={{width:'222px', textAlign:'center'}}>
                                        {information?.holdCardPositive ? <div style={{width:'222px', height:'140px', marginLeft:'15px', marginTop:'7px', position:'relative',
                                        border:'1px solid #EEEEEE', padding:'7px 9px', borderRadius:'3px'}}>
                                            <Image src={information?.holdCardPositive} style={{width:'204px', height:'126px'}}/>
                                            <div style={{width:'16px', height:'16px', borderRadius:'50%', background:'#FFEEF1', color:'#F7385B', cursor:'pointer',
                                            boxShadow:'0px 0px 6px 0px #A9A9A9', position:'absolute', top:'-8px', right:'-8px', textAlign:'center', lineHeight:'14px',
                                            fontSize:'12px', fontWeight:600}}
                                            onClick={()=> changeInfo('holdCardPositive', '')}>
                                                x
                                            </div>
                                        </div>:
                                        <div style={{width:'100%', height:'140px', position:'relative', marginTop:'7px', border:'1px solid #EEEEEE', borderRadius:'3px'}}>
                                            <div style={{}} onClick={()=>setTitle('holdCardPositive')}>
                                                <Upload {...fileListsLogo}>
                                                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/ren.png" alt=""
                                                    style={{width:'100%', height:'100%', cursor:'pointer'}}/>
                                                </Upload>
                                            </div>
                                        </div>}
                                        <div style={{color:'#666666', fontSize:'12px', marginTop:'14px', fontWeight:500}}>点击上传手持身份证照</div>
                                    </div>
                                </div>
                            </div>

                            <div style={{width:'250px', marginTop:'78px'}}>
                                <div style={{ width:'100%', height:'50px', fontWeight:600, fontSize:'16px', textAlign:'center',
                                backgroundImage:'url(https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/steps1.png)',
                                backgroundSize:'100% 100%', lineHeight:'50px'}}>法人代表信息注意事项</div>
                                <div style={{width:'100%', border:'1px solid #EEEEEE', borderTop:0, color:'#666',
                                fontWeight:500, fontSize:'12px', padding:'16px'}}>
                                    <div style={{color:'#333', marginBottom:'2px'}}>请您按照以下要求填写新主体信息，如您没有符合填写要求，您的申请可能会被拒绝。</div>
                                    <div>1.请确保身份证图片字体清晰，边角完整，无任何无关水印，不可故意遮盖二维码或信息。若拍摄模糊建议上传扫描件。</div>
                                    <div>2.法人手持身份证图片确保人脸清晰，身份证信息清晰，如果模糊不清，或者身份证信息与法人不一致，您的申请会被拒绝。</div>
                                    <div>
                                        <p style={{fontSize:'12px', fontWeight:600, marginTop:'19px', color:'#333'}}>示例图</p>
                                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/ys.png" alt=""
                                        style={{width:'100%'}}/>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        stepsIndex == 2 ? <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                            <div id='perfect' style={{marginTop:'48px'}}>
                                <div>
                                    <div style={{width:'130px', minWidth:'130px', fontWeight: 600, paddingRight:'16px'}}><span style={{color:'#F7385B', marginRight:'9px'}}>*</span>营业执照信息</div>
                                    <div style={{width:'100%', display:'flex', alignItems:'center', position:'relative', marginLeft:'15px', marginTop:'7px'}}>
                                        <div onClick={()=>setTitle('qualificationDocument')}>
                                            <Upload {...fileListsLogo}>
                                                <div style={{width:'114px', height:'38px', borderRadius:'4px', cursor:'pointer',
                                                    textAlign:'center', lineHeight:'38px', color: '#FFF', fontSize:'14px',
                                                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', fontWeight:'blod' }} >
                                                    {['上传证明文件']}
                                                </div>
                                            </Upload>
                                        </div>
                                        {/* <div style={{color:'#B3B3B3', fontSize:'12px', marginLeft:'13px',
                                        top:'6px', left:'90px'}}>仅支持JPG、JPEG 、PNG格式</div> */}
                                    </div>
                                    {information?.qualificationDocument.length > 0 ? <div style={{width:'100%', display:'flex', flexWrap:'wrap'}}>
                                        {information?.qualificationDocument.map((item,index)=>{
                                            return <div key={index} style={{width:'220px', height:'140px', marginLeft:'15px', marginTop:'26px', position:'relative',
                                            marginRight: (index+1)%4==0?0:'45px'}}>
                                                <Image src={item} style={{width:'220px', height:'100%'}}/>
                                                <div style={{width:'16px', height:'16px', borderRadius:'50%', background:'#FFEEF1', color:'#F7385B', cursor:'pointer',
                                                boxShadow:'0px 0px 6px 0px #A9A9A9', position:'absolute', top:'-8px', right:'-8px', textAlign:'center', lineHeight:'14px',
                                                fontSize:'12px', fontWeight:600}} onClick={()=>delImg(index)}>
                                                    x
                                                </div>
                                            </div>
                                        })}
                                    </div>:''}
                                </div>
                            </div>
                        </div> : ''
                    }
                </div> : ''
            }

            {stepsIndex > -1 ? <div style={{display:'flex', paddingLeft: stepsIndex == 2 ? '404px' : '223px', marginTop:stepsIndex == 2 ? '120px':'40px'}}>
                <div onClick={()=>setStepsIndex(stepsIndex-1)} style={{width:'284px', height:'48px',
                    border:'1px solid #EEEEEE', marginRight:'25px',
                    borderRadius:'6px', color: '#111', textAlign:'center', lineHeight:'46px', cursor:'pointer'}}>
                    上一步
                </div>
                {stepsIndex < 2 ? <Button disabled={!isNext()} className='sub' style={{ width:'284px', height:'48px', borderRadius:'4px', border:0, marginLeft:'28px',
                    background: isNext()?'linear-gradient(180deg, #F7385B, #FF716E)':'#EEE', fontWeight:500, letterSpacing:'2px',
                    color:'#FFF'}}
                    onClick={()=>next()}>下一步
                </Button>:<Button disabled={!isNext()} className='sub'  style={{ width:'284px', height:'48px', borderRadius:'4px', border:0, marginLeft:'28px',
                    background: isNext()?'linear-gradient(180deg, #F7385B, #FF716E)':'#EEE', fontWeight:500, letterSpacing:'2px',
                    color:'#FFF'}}
                    onClick={()=>subPost()}>提交
                </Button>}
            </div> : ''}

            <div style={{height:'100px'}}></div>
        </div>

        <Modal centered visible={isSuccess} footer={null} onCancel={e => { setIsSuccess(false) }}
            // getContainer={() => document.getElementById('Status1')}
            // bodyStyle={{
            //     padding: '0', backgroundColor: '#FFFFFF',
            //     width: 462, height: 466,
            // }}
        >
            <div style={{ textAlign: 'center', paddingTop: '55px' }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/login/stepsSuccess.png'} alt=""
                    style={{ width: '104px', marginTop: '40px' }} />
                <h3 style={{ fontSize: '18px', fontWeight: 'bold', marginTop:'10px', marginBottom:'15px', lineHeight:'25px' }}>尊敬的{props.location.state.phone}用户<br/>您的信息提交成功！</h3>
                <div style={{fontWeight:600, lineHeight:'24px'}}>我们将对资料进行审核，请保持电话畅通，<br/>工作人员将<span style={{color:'#F7385B'}}>在3-5个工作日</span>内与您联系！</div>
                <div style={{
                    width: '95px', height: '30px', borderRadius: '15px', cursor: 'pointer',
                    textAlign: 'center', lineHeight: '30px', color: '#FFF', margin: '38px auto',
                    background: '#F7385B', fontWeight: 500,
                }}
                onClick={()=>props.navigate('/')} >
                    {['去首页']}
                </div>
            </div>
        </Modal>
    </div>
}
export default withRouter(Perfect);
