import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../utils/withRouter'
import { Input, Carousel, Image, Modal, Dropdown, Drawer } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {getStarList} from "../../service/https";
import * as Api from "../../service/https";
import Header from "../../components/Header";
import {Outlet} from "react-router-dom";

const StarSelection = (props)=>{

    useEffect(()=>{
        IsPC()
        window.addEventListener('resize', IsPC)
        return () => {
            window.removeEventListener('resize', IsPC)
        }
    },[])

    const [isWeb, setIsWeb] = useState(true)
    const IsPC = ()=> {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"
        ];
        // console.log(userAgentInfo)
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        let width = document.documentElement.clientWidth > 800
        setIsWeb(width && flag)
        
        const designWidth = 750;
        const windowWidth = document.documentElement.clientWidth < designWidth ? document.documentElement.clientWidth : designWidth;

        // *100 之后，则样式中rem的值就需要相应的缩小100倍
        // 即：设计稿中的20px，在样式中就要写成0.2rem
        const fontSize = windowWidth / designWidth * 100;
        document.documentElement.style.fontSize = `${fontSize}px`;
    }

    const [tabSearch, setTabs] = useState(['商品', '小店', '品牌'])
    const [tabSearchIndex, setTabsIndex] = useState(0)
    const [searchHistry, setSearchHistry] = useState(['零食','粮油米面','方便速食','乳品','酒水','生活日用','家庭清洁','身体清洁','护肤品','彩妆'])
    const [bannerList, bannerListSet] = useState([1])
    const changeTab = index=>{
        setTabsIndex(index)
    }


    const carouselRef = useRef(null);
    const [carouselIndex, setCarouselIndex] = useState(0)
    const onCarouselChange = (currentSlide) => {
        setCarouselIndex(currentSlide)
    };
    const [isMouse, setIsMouse] = useState(false)


    const [dayTopList, setDayTop] = useState(['商品', '小店', '品牌','1'])

    const [tagsIndex, setTagsIndex] = useState(-1)
    const changeTags = index => {
        setTagsIndex(index)
    };



    const [visible, setVisible] = useState(false)
    const [checkedList, setCheckedList] = useState([])
    const checkTags = (index) => {
        let item = searchHistry[index]
        let list = JSON.parse(JSON.stringify(checkedList))
        let listIndex = list.indexOf(item)
        if(listIndex > -1){
            list.splice(listIndex, 1)
        }else{
            list.push(item)
        }
        console.log(list)
        setCheckedList(list)
    };



    const getMore = () => {
        showMoreSet(true)
        // let searchHistrys = searchHistry.concat(searchHistry)
        // setSearchHistry(searchHistrys)
    };

    const [searchParams,searchParamsSet] = useState({})
    const searchChange = (value,key) => {
        let t = Object.assign({},searchParams)
        t[key] = value
        searchParamsSet(t)
    }

    const filterTable = () => {
        let t = [...foreverList].filter(vm =>  { if(vm?.productDetail?.productName.includes(searchParams?.title)) return vm  } )
        tableListSet(t)
    }

    const [tableList,tableListSet] = useState([])
    const [foreverList,foreverListSet] = useState([])
    const [hourList,hourListSet] = useState([])
    const [total,totalSet] = useState(0)
    const [showMore,showMoreSet] = useState(false)
    const getTableList = () => {
        Api.getStarList({
            current:1,
            size:100,
            title:searchParams?.title || ''
        }).then(res=>{
            if(res.code === 200){
                tableListSet(res.data.records)
                foreverListSet(res.data.records)
                hourListSet(res.data.records.slice(0,4))
            }
        })
    }

    useEffect(()=>{
        getTableList()
    },[])



    const items = [
        {
          key: '1',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              1st menu item
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
              2nd menu item
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
              3rd menu item
            </a>
          ),
        },
    ];

    const [tags, setTagsList] = useState(['零食',
        // '方便速食',
        '乳品',
        '酒水',
        '生活日用',
        '护肤品',
        '彩妆'])
    const [tagIdList, setTagIdList]= useState([])
    const checkedTag = item=>{
        var data = JSON.parse(JSON.stringify(tagIdList))
        var index = data.indexOf(item)
        if(index>-1){
            data.splice(index,1)
        }else{
            data.push(item)
        }
        setTagIdList(data)
    }
    const [isDrawer, setDrawer]= useState(false)

    return isWeb ? <div>
        {
            props.location.pathname!='/drainage' && props.location.pathname!='/perfect' &&
            props.location.pathname!='/login' ?
                <Header /> :''
        }
        {props.location.pathname =='/' ? <div>
        <nav style={{width:'100%', position:'relative'}}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/sousuoBack.png" alt=""
            style={{width:'100%', height:'256px'}}/>
            <div style={{width:'100%', position:'absolute', top:0, left:0}}>
                <div style={{width:'1084px', margin:'19px auto'}}>
                    <div style={{display:'flex', paddingLeft:'15px'}}>
                        {
                            tabSearch.map((item,index)=>{
                                return <div key={index} style={{width:'88px', height:'33px',
                                background: tabSearchIndex==index?'#F7385B':'none', color:tabSearchIndex==index?'#FFF':'#F7385B',
                                borderTopLeftRadius:'5px', borderTopRightRadius:'5px', textAlign:'center',
                                lineHeight:'33px', fontWeight:500, cursor:'pointer'}}
                                onClick={()=>changeTab(index)}>{item}</div>
                            })
                        }
                    </div>
                    <div style={{width:'100%', height:'48px', borderRadius:'5px', padding:'2px',
                    background: 'linear-gradient(180deg, #F7385B, #FF716E)', }}>
                        <div style={{width:'100%', height:'100%', display:'flex', justifyContent:'space-between', background:'#FFF',
                        borderRadius:'4px'}}>
                            <Input placeholder="" onChange={e => searchChange(e.target.value,'title')} bordered={false} />
                            <div style={{width:'152px', height:'100%', background:'linear-gradient(180deg, #F7385B, #FF716E)',
                            display:'flex', alignItems:'center', justifyContent:'center', color:'#FFF', fontSize:'18px',
                            fontWeight:600, cursor:'pointer'}} onClick={filterTable}>
                                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/sousuo.png" alt=""
                                style={{width:'20px', marginRight:'8px'}}/>
                                <span>搜索</span>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', color:'#888888', fontWeight:500, marginTop:'11px'}}>
                        {
                            searchHistry.map((item,index)=>{
                                return <span key={index} style={{marginRight:'20px'}}>{item}</span>
                            })
                        }
                    </div>
                </div>
            </div>
        </nav>

        <div style={{width:'100%', height:'454px', background:'#F6F6F6'}}>
            <div style={{width:'1400px', margin:'0 auto',}}>

                <div style={{width:'1400px', height:'250px', marginTop:'-103px', position:'relative',borderRadius:'15px'}}
                onMouseEnter={()=>setIsMouse(true)} onMouseLeave={()=>setIsMouse(false)}>
                    <Carousel ref={carouselRef} autoplay={true} autoplaySpeed={5000} dots={false} afterChange={onCarouselChange} style={{borderRadius:'15px'}}>
                        {
                            bannerList.map((item,index)=>{
                                return <div key={index} style={{borderRadius:'15px'}}>
                                    <div key={index} style={{width:'1400px', height:'250px', background:'#FFFFFF',
                                    borderRadius:'15px'}}>
                                        <Image preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/homeImg/navB.png'></Image>
                                    </div>
                                </div>
                            })
                        }
                    </Carousel>
                    {bannerList?.length > 1 ? <div style={{width:'100%', display:'flex', justifyContent:'center', position:'absolute', bottom:'12px', left:0}}>
                        {
                            bannerList.map((item,index)=>{
                                return <span key={index} style={{marginRight:'9px', border:'1px solid #FFF', borderRadius:'4.5px',
                                width:'9px', height:'9px', background:carouselIndex==index?'#FFF':'', cursor:'pointer'}}
                                onClick={()=>carouselRef.current.goTo(index)}></span>
                            })
                        }
                    </div> : ''}
                    {bannerList?.length > 1 && isMouse?<img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/left-circle.png" alt=""
                    style={{width:'44px', height:'44px', position:'absolute', left:'40px', top:'103px', cursor:'pointer'}}
                    onClick={()=>carouselRef.current.prev()} />:''}
                    {bannerList?.length > 1 && isMouse?<img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/right-circle.png" alt=""
                    style={{width:'44px', height:'44px', position:'absolute', right:'40px', top:'103px', cursor:'pointer'}}
                    onClick={()=>carouselRef.current.next()} />:''}
                </div>

                <div style={{marginTop:'23px'}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <h3 style={{fontSize:'20px', fontWeight:600}}>24小时销量榜</h3>
                        {/*<span style={{color:'#888888', fontWeight:500, cursor:'pointer'}}>{'查看更多榜单>'}</span>*/}
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        {
                            hourList?.map((item,index)=>{
                                return <div key={index} style={{width:'341px', height:'118px', background:'#FFF', padding:'8px 12px',
                                display:'flex', justifyContent:'space-between'}}>
                                    <Image src={item?.productDetail?.productImgUrls[0]}
                                    width={102} height={102} preview={false}/>
                                    <div style={{width:'200px'}}>
                                        <div className='over1' style={{fontWeight:'600', color:'#111111', lineHeight:'18px'}}>{item?.productDetail?.productName}</div>
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'1px'}}>
                                            <span style={{color:'#F7385B', fontSize:'16px', fontWeight:600}}>${item?.productPrice}</span>
                                            <span style={{color:'#888888', fontSize:'12px', fontWeight:500}}>销量 {item?.salesVolume}</span>
                                        </div>
                                        <div style={{display:'flex', marginTop:'5px'}}>
                                            <div style={{display:'flex', alignItems:'center', background:'rgb(252,239,241)', padding:'0 5px', borderRadius:'3px'}}>
                                                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/huo.png" alt=""
                                                style={{width:'12px', height:'12px'}}/>
                                                <span className='over1' style={{color:'#F7385B', fontSize:'12px'}}>${item?.productPrice}内商品榜第一名</span>
                                            </div>
                                        </div>
                                        { item?.commissionPercent ? <div style={{width:'100%', borderTop:'1px dashed rgb(243,243,243)', marginTop:'10px', paddingTop:'5px',
                                        fontSize:'12px', color:'#333', fontWeight:500}}>高佣: {item?.commissionPercent}%</div> : '' }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>

            </div>
        </div>

        <div style={{padding:'33px 0'}}>
            <div style={{width:'1400px', margin:'0 auto', display:'flex', alignItems:'center'}}>
                <span style={{fontSize:tagsIndex == -1 ? '20px' :'16px', fontWeight:500,
                marginRight:'50px', borderBottom: tagsIndex == -1 ? '4px solid #F7385B':'', cursor:'pointer',
                lineHeight:tagsIndex == -1 ? '28px':'', color:tagsIndex == -1 ? '#111':'#888888', }}
                onClick={()=>changeTags(-1)}>推荐</span>
                {/*<div style={{width:'1100px', overflowX: searchHistry.length > 10 ? 'scroll':''}}>*/}
                {/*    <div style={{width: (searchHistry.length * 110)+'px'}}>*/}
                {/*    {*/}
                {/*        searchHistry.map((item,index)=>{*/}
                {/*            return <span key={index} style={{fontSize:tagsIndex == index ? '20px' :'16px', fontWeight:500,*/}
                {/*            marginRight:'50px', borderBottom: tagsIndex == index ? '4px solid #F7385B':'', cursor:'pointer',*/}
                {/*            lineHeight:tagsIndex == index ? '28px':'', color:tagsIndex == index ? '#111':'#888888', }}*/}
                {/*            onClick={()=>changeTags(index)}>{item}</span>*/}
                {/*        })*/}
                {/*    }*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div style={{background:'rgb(252,239,241)', padding:'3px 6px', borderRadius:'3px', color:'#F7385B',*/}
                {/*fontSize:'12px', fontWeight:'500', cursor:'pointer'}}*/}
                {/*onClick={()=>setVisible(true)}>+自定义选品方向</div>*/}
            </div>

            <div style={{width:'1400px', margin:'0 auto', display:'flex', flexWrap:'wrap', marginTop:'14px'}}>
                {
                    tableList?.map((item,index)=>{
                        return <div key={index} style={{width:'268px', height:'366px', border:'1px solid #EEEEEE', borderRadius:'3px', padding:'15px',
                        marginRight: (index+1)%5==0?0:'15px', marginBottom:'15px'}}>
                            <Image src={item?.productDetail?.productImgUrls[0]}
                            width={238} height={238} preview={false}/>
                            <div style={{width:'100%', marginTop:'12px'}}>
                                <div className='over1' style={{fontWeight:'600', color:'#111111', lineHeight:'18px'}}>{item?.productDetail?.productName}</div>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'5px'}}>
                                    <span style={{color:'#F7385B', fontSize:'16px', fontWeight:600}}>${item?.productPrice}</span>
                                    <span style={{color:'#888888', fontSize:'12px', fontWeight:500}}>{item?.distributionUserCount || 0}人在带</span>
                                </div>
                                <div style={{width:'100%', borderTop:'1px dashed rgb(243,243,243)', marginTop:'8px', paddingTop:'10px',
                                display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    <div style={{fontSize:'12px', color:'#333', fontWeight:500, }}>佣金 {item?.commissionPercent}% { item?.commissionPercent ? <span>（赚${(item?.productPrice * 100 * item?.commissionPercent / 10000).toFixed(2)}）</span> : '' }</div>
                                    <div style={{display:'flex', alignItems:'center', background:'rgb(252,239,241)', padding:'0 7px', borderRadius:'3px'}}>
                                        <span className='over1' style={{color:'#F7385B', fontSize:'12px'}}>最新上架</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            {
                showMore ? <div style={{display:'flex', alignItems:'center', background:'rgb(252,239,241)', borderRadius:'3px',
                    margin:'40px auto', width:'107px', height:'28px', justifyContent:'center', fontWeight:'600', cursor:'pointer'}}
                     onClick={()=>getMore()}>
                    <span className='over1' style={{color:'#F7385B', fontSize:'12px'}}>展开更多商品</span>
                </div> : <div style={{textAlign:'center', marginTop:10}}>已经到底啦~</div>
            }
        </div>



        <Modal visible={visible} onCancel={e => { setVisible(false) }} centered={true} footer={null} destroyOnClose={true}
            // getContainer={() => document.getElementById('VideoDemand')}
            width={581}
            >
            <div style={{ padding: '0 30px', width: '100%',}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: '20px', fontWeight: '600', color:'#111', marginRight:'17px' }}>自定义选品方向</span>
                    <div style={{ fontSize: '14px', fontWeight: '500', color:'#333' }}>当前已选择<span style={{ fontSize: '18px', fontWeight: '600', color:'#F7385B' }}>{checkedList.length}</span>个</div>
                </div>
                <div style={{marginTop:'20px', display:'flex', flexWrap:'wrap'}}>
                    {
                        searchHistry.map((item,index)=>{
                            let isChecked = checkedList.indexOf(item) > -1
                            return <div key={index} style={{width:'107px', height:'28px', borderRadius:'3px',
                            background: isChecked?'rgb(252,239,241)':'#EEEEEE', color:isChecked?'#F7385B':'#666666', textAlign:'center', lineHeight:'28px',
                            fontSize:'12px', marginRight: (index+1)%4==0?0:'14px', marginBottom:'14px',
                            cursor:'pointer', fontWeight:500}}
                            onClick={()=>checkTags(index)}>{item}</div>
                        })
                    }
                </div>
                <div style={{
                    width: '96px', height: '30px', background: '#F7385B',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '14px',
                    borderRadius: '15px', cursor: 'pointer', margin:'25px auto', fontWeight:500
                }} onClick={e => setVisible(false)}>
                    选好了
                </div>
            </div>
        </Modal>
       </div>
         : <Outlet />}
    </div> :
    <div style={{width:'100%', backgroundColor:'#FFF', minHeight:'100vh', overflow:'hidden'}}>
        <nav style={{width:'100%', position:'relative'}}>
            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/navBack.png" alt="" 
            style={{width:'100%'}}/>
            <div style={{width:'100%', position:'absolute', top:0,left:0, padding:'0.30rem', paddingTop:'0.78rem'}}>
                <div style={{width:'100%', height:'0.58rem', border:'1px solid #F7385B', borderRadius:'35px',
                display:'flex', alignItems:'center', padding:'0 0 0 0.27rem'}}>
                    <Dropdown menu={{ items, }} placement="bottomLeft" >
                        <div style={{fontSize:'0.24rem', color:'#333'}}>商品 <DownOutlined /></div>
                    </Dropdown>
                    <div style={{width:'1px', height:'0.4rem', background:'linear-gradient(180deg, #FFFFFF, #CFCFCF, #FFFFFF)',
                    marginLeft:'0.16rem'}}></div>
                    <Input style={{width:'4.5rem'}} bordered={false} placeholder="请输入关键词搜索" />
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/shu.png" alt="" style={{width:'0.01rem'}} />
                    <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/sousuo.png" alt="" style={{width:'0.24rem',
                    marginLeft:'0.16rem'}} />
                    <div style={{fontSize:'0.24rem', color:'#8F8F8F', background: 'linear-gradient(180deg, #F7385B 0%, #FF716E 100%)',
                    WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', marginLeft:'0.08rem'}}>搜索</div>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', margin:'0.15rem 0', padding:'0 0.27rem'}}>
                    {
                        tags.map((item,index)=>{
                            return <span key={index} style={{padding:'0 0.12rem', color:'#F7385B', background:'#FFEEF1',
                            borderRadius:'0.06rem', fontSize:'0.18rem'}}>{item}</span>
                        })
                    }
                </div>
                <div>
                    <Carousel autoplay dots={false}>
                        <div>
                            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/nav.png" alt="" style={{width:'100%'}} />
                        </div>
                        <div>
                            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/nav.png" alt="" style={{width:'100%'}} />
                        </div>
                    </Carousel>
                </div>
            </div>
        </nav>

        <div style={{width:'100%', height:'2.25rem', background:'#F8F8F8', padding:'0.25rem 0'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'0 0.3rem'}}>
                <h3 style={{fontSize:'0.26rem', color:'#111', fontWeight:'bold'}}>24小时销量榜</h3>
                <span style={{fontSize:'0.16rem', color:'#888'}}>{'查看更多榜单>'}</span>
            </div>
            <div style={{width:'100%', overflowX:'auto'}}>
                <div style={{display:'flex', justifyContent:'space-between', padding:'0 0.3rem'}}>
                    {
                        hourList?.map((item,index)=>{
                            return <div key={index} style={{width:'3.41rem', height:'1.18rem', background:'#FFF', padding:'0.08rem 0.12rem',
                            display:'flex', justifyContent:'space-between', marginRight:'0.12rem'}}>
                                <Image src={item?.productDetail?.productImgUrls[0]} preview={false}
                                style={{width:'1.02rem', height:'1.02rem', marginRight:'0.11rem'}}/>
                                <div style={{width:'2rem'}}>
                                    <div className='over1' style={{fontWeight:'600', color:'#111111', lineHeight:'0.22rem', fontSize:'0.14rem'}}>{item?.productDetail?.productName}</div>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                        <span style={{color:'#F7385B', fontSize:'0.16rem', fontWeight:600}}>${item?.productPrice}</span>
                                        <span style={{color:'#888888', fontSize:'0.12rem', fontWeight:500}}>销量 {item?.salesVolume}</span>
                                    </div>
                                    <div style={{display:'flex', marginTop:'0.02rem'}}>
                                        <div style={{display:'flex', alignItems:'center', background:'rgb(252,239,241)', padding:'0 0.05rem', borderRadius:'3px'}}>
                                            <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/huo.png" alt=""
                                            style={{width:'0.16rem', height:'0.16rem'}}/>
                                            <span className='over1' style={{color:'#F7385B', fontSize:'0.12rem'}}>${item?.productPrice}内商品榜第一名</span>
                                        </div>
                                    </div>
                                    {/* { item?.commissionPercent ? <div style={{width:'100%', borderTop:'1px dashed rgb(243,243,243)', marginTop:'0.1rem', paddingTop:'0.05rem',
                                    fontSize:'0.12rem', color:'#333', fontWeight:500}}>高佣: {item?.commissionPercent}%</div> : '' } */}
                                </div>
                            </div>
                        })
                    }
                    <div style={{width:'0.3rem', opacity:0}}>1</div>
                </div>
            </div>
        </div>

        <div style={{paddingTop:'0.27rem'}}>
            <div style={{width:'100%', margin:'0 auto', display:'flex', alignItems:'center', padding:'0 0.3rem', 
            justifyContent:'space-between'}}>
                <span style={{fontSize:tagsIndex == -1 ? '0.26rem' :'0.16rem', fontWeight:600,
                marginRight:'50px', borderBottom: tagsIndex == -1 ? '0.04rem solid #F7385B':'', cursor:'pointer',
                lineHeight:tagsIndex == -1 ? '0.30rem':'', color:tagsIndex == -1 ? '#111':'#888888', }}
                onClick={()=>changeTags(-1)}>推荐</span>
                <div style={{background:'#FFEEF1', padding:'0.05rem 0.15rem', borderRadius:'4px', color:'#F43B5E',
                fontSize:'0.18rem'}}
                onClick={()=>setDrawer(true)}>+自定义选品方向</div>
            </div>
            <div style={{width:'100%', margin:'0 auto', display:'flex', flexWrap:'wrap', marginTop:'14px', justifyContent:'space-between',
            padding:'0 0.3rem'}}>
                {
                    tableList?.map((item,index)=>{
                        return <div key={index} style={{width:'3.36rem', border:'1px solid #EEEEEE', borderRadius:'3px', padding:'0.17rem',
                        marginBottom:'0.19rem'}}>
                            <Image src={item?.productDetail?.productImgUrls[0]} preview={false}
                            style={{width:'2.98rem', height:'2.98rem'}}/>
                            <div style={{width:'100%', marginTop:'8px'}}>
                                <div className='over1' style={{fontWeight:'600', color:'#111111', lineHeight:'18px'}}>{item?.productDetail?.productName}</div>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'5px'}}>
                                    <span style={{color:'#F7385B', fontSize:'16px', fontWeight:600}}>${item?.productPrice}</span>
                                    <span style={{color:'#888888', fontSize:'12px', fontWeight:500}}>{item?.distributionUserCount || 0}人在带</span>
                                </div>
                                <div style={{width:'100%', borderTop:'1px dashed rgb(243,243,243)', marginTop:'8px', paddingTop:'10px',
                                display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    <div style={{fontSize:'0.12rem', color:'#333', fontWeight:500, }}>佣金 {item?.commissionPercent}% { item?.commissionPercent ? <span>（赚${(item?.productPrice * 100 * item?.commissionPercent / 10000).toFixed(2)}）</span> : '' }</div>
                                    <div style={{width:'1.03rem', display:'flex', alignItems:'center', background:'rgb(252,239,241)', padding:'0 0.07rem', borderRadius:'3px'}}>
                                        <span className='over1' style={{color:'#F7385B', fontSize:'0.12rem'}}>最新上架</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>



        <Drawer
            title={`自定义选品方向`}
            placement="right" 
            // style={{width:'100px'}}
            // size={'default'}
            onClose={()=>setDrawer(false)}
            open={isDrawer}
            extra={
            <div>
                
            </div>
            }
        >
            <div style={{fontSize:'0.24rem'}}>当前已选择<span style={{color:'#F43B5E'}}>{tagIdList.length}</span>个</div>
            <div style={{display:'flex', flexWrap:'wrap', marginTop:'0.22rem'}}>
                {
                    tableList.map((item,index)=>{
                        var i = tagIdList.indexOf(index)
                        return <div key={index} style={{width:'1.68rem', height:'0.44rem', 
                        background: i > -1 ?'#FFEEF1':'#EEEEEE', marginBottom:'0.34rem',
                        borderRadius:'4px',
                        textAlign:'center', lineHeight:'0.44rem',color: i > -1 ?'#F43B5E':'#666666', 
                        fontSize:'0.17rem', marginRight:(index+1)%2==0?0:'0.22rem'}}
                        onClick={()=>checkedTag(index)}>家纺布艺</div>
                    })
                }
            </div>
            <div style={{width:'1.44rem', height:'0.44rem', background:'#F43B5E', margin:'0 auto', marginBottom:'0.34rem',
            borderRadius:'14px', color:'#FFF',
            textAlign:'center', lineHeight:'0.44rem', fontSize:'0.2rem'}} 
            onClick={()=>setDrawer(false)}>选好了</div>
        </Drawer>

    </div>
}
export default withRouter(StarSelection);
