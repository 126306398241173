import React, { useState, useEffect } from 'react'
import withRouter from '../../../utils/withRouter'
import Status1 from '../../../components/LivingDemand/Status1'
import MCNLiveStatus from '../../../components/LivingDemand/MCNLiveStatus'
import '../../../asset/css/LivingDemand.scss'
import store from '../../../utils/redux/index'
import intl from 'react-intl-universal';
import { DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import * as Api from '../../../service/https';
import { Checkbox, Input  } from 'antd';
import {debounce} from '../../../utils/debounce'
import Enum from '../../../utils/Enum/Enum'

const LivingDemand = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('DemandHall'))
    const [textJsonR, setTextJsonR] = useState(intl.get('ReleaseDemand'))
    const [status, setStatus] = useState(-1)
    const [statusList, setStatusList] = useState([])
    const [type, setType] = useState(-1)
    const [showIndex, setShowIndex] = useState(-1)
    const [userType, setUserType] = useState(2)
    const a = [
        {
            id: -1,
            text: lang == 'zh' ?'全部':'All',
        },
        {
            id: 1,
            text: textJson['待合作']
        },
        {
            id: 2,
            text: textJson['合作中']
        },
        {
            id: 3,
            text: textJson['待确认']
        },
        {
            id: 4,
            text: textJson['合作成功']
        },
        {
            id: 5,
            text: textJson['失效需求']
        }
    ]
    const b = [
        {
            id: -1,
            text: lang == 'zh' ?'全部':'All',
        },
        {
            id: 6,
            text: textJson['商家指派'],
        },
        {
            id: 1,
            text: textJson['已申请'],
        },
        {
            id: 2,
            text: textJson['合作中'],
        },
        {
            id: 3,
            text: textJson['待确认'],
        },
        {
            id: 4,
            text: textJson['合作成功'],
        },
        {
            id: 5,
            text: textJson['失效申请']
        }
    ]

    const [statusFList, setStatusFList] = useState([
        {
            id: -1,
            value: lang == 'zh' ?'全部':'All',
        },
        {
            id: 3,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[3].val]
        },
        // {
        //     id: 1,
        //     value: intl.get('ReleaseDemand')[Enum.propertiesTarget[1].val]
        // },
        {
            id: 2,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[2].val]
        },
        {
            id: 4,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[4].val]
        },
        {
            id: 5,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[5].val]
        },
        {
            id: 6,
            value: intl.get('ReleaseDemand')[Enum.propertiesTarget[6].val]
        },
    ])
    const [statusF, setStatusF] = useState(-1)
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    useEffect(()=>{
        getCargo()
    },[])


    useEffect(() => {
        if (state.userInfo?.userType)
            if (state.userInfo?.userType === 1) {
                setStatusList(a)
                setStatus(-1)
            } else {
                setStatusList(b)
                setStatus(-1)
            }
    }, [state.userInfo?.userType])



    const [classList, setClassList] = useState([])
    const [classIndex, setClassIndex] = useState(0)
    const [classShow, setClassShow] = useState(false)
    const sortClassIndex = (index) => {
        // setPageIndex(1)
        setClassIndex(index)
    }
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                all.unshift({id: 0, goodsName: '全部', goodsEnglishName: 'All'})
                setClassList(all)
            }
        })
    }

    const [samplesT, setIsSamplesT] = useState(false)
    const [samplesF, setIsSamplesF] = useState(false)
    
    const [orderWay, setOrderWay] = useState(2)
    const [sortWay, setSortWay] = useState(2)
    const [sortTimeWay, setSortTimeWay] = useState(2)

    const [searchValue,setSearchValue] = useState('')
    const handleSearch = debounce((value) => {
        setSearchValue(value==''?null:value)
    }, 500)

    return (
        <div id='LivingDemand'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt=""
                        style={{ width: '190px' }} />
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{textJson['我发布的直播需求']}</span>
                </div>
            </nav>
            <div style={{ backgroundColor: '#fff', padding: '0px 31px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', padding: '11px 0' }}>
                    {
                        statusList?.map((item, index) =>
                            <div key={index} className={status === item.id ? 'LDactive' : ''} style={{
                                cursor: 'pointer', borderRadius: '5px', marginRight: lang=='zh'?'':'25px', padding:lang=='zh'?'':'0 10px',
                                height: '45px', lineHeight: '45px', width: lang=='zh'?'120px': '', minWidth:'50px', textAlign:'center'
                            }} onClick={e => { setStatus(item.id) }}>{item.text}</div>
                        )
                    }
                </div>
            </div>

            <div style={{
                width: '100%', backgroundColor: '#FFF', paddingTop: '24px', paddingLeft: '48px', paddingBottom: '23px',
                fontWeight: 500, marginTop:'10px'
            }}>
                {/* 所属行业 */}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <label style={{ width: state.language == 'zh' ? '102px' : '150px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('ReleaseDemand')['所属行业']}：</label>
                    <div style={{
                        width: 'calc(100% - 70px)', height: classShow ? 'auto' : '39px', display: 'flex', flexWrap: 'wrap',
                        overflow: classShow ? '' : 'hidden'
                    }}>
                        {
                            classList?.map((item, index) => {
                                return <span key={index} className='tabItem' style={{
                                    padding: '6px 10px',
                                    background: index == classIndex ? '#FFE7E9' : 'none', marginBottom: '5px',
                                    color: index == classIndex ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => sortClassIndex(index)}>{lang == 'zh' ?item.goodsName:item.goodsEnglishName}</span>
                            })
                        }
                    </div>
                    <div style={{ width: '45px' }}>
                        {!classShow ?
                            <DownSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                onClick={() => { setClassShow(true) }} />
                            :
                            <UpSquareTwoTone style={{ fontSize: '18px', marginTop: '8px', cursor: 'pointer' }} twoToneColor='#CCCCCC'
                                onClick={() => setClassShow(false)} />}
                    </div>
                </div>
                {/* 需求目标 */}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop:'10px' }}>
                    <label style={{ width: state.language == 'zh' ? '99px' : '145px', marginTop: '6px', textAlign: 'right', paddingRight: '26px' }}>{intl.get('ReleaseDemand')['需求目标']}：</label>
                    <div style={{
                        width: 'calc(100% - 70px)', height: '39px', display: 'flex'
                    }}>
                        {
                            statusFList?.map((item, index) => {
                                return <span key={index} className='tabItem' style={{
                                    padding: '6px 10px',
                                    background: statusF === item.id ? '#FFE7E9' : 'none', marginBottom: '5px',
                                    color: statusF === item.id ? '#E94753' : '#333', borderRadius: '4px', marginRight: '10px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    if(item.id == -1) setOrderWay(2)
                                    setStatusF(item.id)
                                }}>{item.value}</span>
                            })
                        }
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#fff', height:'56px', padding: '0px 48px', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div style={{ display: 'flex' }}>
                    {statusF > -1 ? <div style={{
                        color: orderWay === 3 ? '#F7385B' : '#333', display:'flex', alignItems:'center',
                        lineHeight: '56px',
                        cursor: 'pointer',
                        marginRight: '35px'
                    }}
                        onClick={e => { setOrderWay(3); setSortTimeWay(sortTimeWay==1?2:1) }}>{textJsonR['时长']}
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/up' + (sortTimeWay === 1 && orderWay === 3 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '4px' }} />
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortTimeWay === 2 && orderWay === 3 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '2px' }} />
                    </div>:''}
                    <div style={{
                        color: orderWay === 2 ? '#F7385B' : '#333', lineHeight: '56px', cursor: 'pointer', marginRight: '8px',
                        display:'flex', alignItems:'center',
                    }}
                        onClick={e => { setOrderWay(2); setSortWay(sortWay==1?2:1) }}>{intl.get('ReleaseDemand')['红人直播日期']}
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/up' + (sortWay === 1 && orderWay === 2 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '4px' }} />
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/jiantou' + (sortWay === 2 && orderWay === 2 ? 's' : '') + '.png'} alt=""
                            style={{ marginLeft: '2px' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems:'center' }}>
                    <div style={{ marginRight: '30px' }}>
                        <Checkbox className='active' checked={samplesT} onChange={(e)=>{
                            if(e.target.checked){
                                setIsSamplesF(false)
                            }
                            setIsSamplesT(e.target.checked)
                        }}>
                            <span style={{ color: samplesT ? '#F7385B' : '' }}>{intl.get('LivingDemand')['支持样品寄送']}</span>
                        </Checkbox>
                    </div>
                    <div style={{}}>
                        <Checkbox className='active' checked={samplesF} onChange={(e)=>{
                            if(e.target.checked){
                                setIsSamplesT(false)
                            }
                            setIsSamplesF(e.target.checked)
                        }}>
                            <span style={{ color: samplesF ? '#F7385B' : '' }}>{intl.get('LivingDemand')['不支持样品寄送']}</span>
                        </Checkbox>
                    </div>
                    {state.userInfo?.userType == 1 ?<div id='login' style={{marginLeft:'40px', display:'flex', alignItems:'center'}}>
                        <Input onChange={e=>handleSearch(e.target.value)} placeholder={intl.get('ReleaseDemand')['请输入红人昵称或账号搜索']} 
                        style={{borderRadius:'4px', width:'230px'}} allowClear={true}/>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/search.png'} 
                        style={{width:'20px', marginLeft:'-28px', marginTop:'-1px', position:'relative'}} alt="" />
                    </div>:''}
                </div>
            </div>

            {state.userInfo?.userType == 3 ? <MCNLiveStatus target={statusF} merchantCooperationEnum={status} classification={classIndex} sendSample={samplesT?1:samplesF?2:-1} sortWay={sortWay} sortTimeWay={sortTimeWay} orderWay={orderWay} filterName={searchValue} media={-1} /> :
            <Status1 target={statusF} merchantCooperationEnum={status} classification={classIndex} sendSample={samplesT?1:samplesF?2:-1} sortWay={sortWay} sortTimeWay={sortTimeWay} orderWay={orderWay} filterName={searchValue} media={-1} />}
        </div>
    )
}
export default withRouter(LivingDemand);