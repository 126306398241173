import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import StatusVideoDetail from '../../../components/DemandHall/StatusVideoDetail'
import StatusLive from '../../../components/DemandHall/StatusLive'
import '../../../asset/css/VideoDemand.scss'
import { Spin, Image, Modal, Pagination, notification, message } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import * as Api from '../../../service/https'
import store from '../../../utils/redux/index'
import intl from 'react-intl-universal';
import QRCode from 'qrcode.react';
import toKMB from '../../../utils/KMB'
import PkModel from '../../../components/PkModel'
import Enum from '../../../utils/Enum/Enum'

const VideoDemandDetail = (props) => {
    console.log(props);
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('ReleaseDemand'))
    const [textJsonL, setTextJsonL] = useState(intl.get('LivingDemand'))
    const [otheList, setOtheList] = useState([1, 2, 3, 4, 5, 6])
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [successVisible, setSuccessVisible] = useState(false)

    const [bloggerTotal, setBloggerTotal] = useState(0)
    const [bloggerCurrent, setBloggerCurrent] = useState(1)
    const [demandId, setDemandId] = useState()
    const [applicationId, setApplicationId] = useState(-1)
    const [payUrl, setPayurl] = useState('')
    const [reload, setReload] = useState(false)
    const [payload, setPayload] = useState(false)
    const [payType, setPayType] = useState(0)
    const [cost, setCost] = useState({})
    const [listId, setListId] = useState()
    let interval = useRef()

    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    useEffect(() => {
        // console.log(props)
        getCargo()
    }, [])
    useEffect(() => {
        if(state.userInfo){
            getMarketDetail()
            if(state.userInfo?.userType == 1)getBloggerLists()
        }
    }, [state.userInfo])

    const [classList, setClassList] = useState([])
    const getCargo = ()=>{
        Api.getCargo().then(res=>{
            if(res.code == 200){
                let all = res.data
                setClassList(all)
            }
        })
    }

    const params = props.location.state
    const [marketDetail, setMarketDetail] = useState()
    const [marketBloggerList, setMarketBloggerList] = useState([])
    const getMarketDetail = ()=>{
        if(state.userInfo?.userType == 1){
            Api.marketDetail(props.location.state).then(res=>{
                // console.log(res)
                if(res.code == 200){
                    setMarketDetail(res.data)
                }
            })
            
        }else if(state.userInfo?.userType == 2){
            Api.marketBloggerDetail(props.location.state).then(res=>{
                // console.log(res)
                if(res.code == 200){
                    setMarketDetail(res.data)
                }
            })
        }else{
            Api.marketMcnDetail(props.location.state).then(res=>{
                // console.log(res)
                if(res.code == 200){
                    setMarketDetail(res.data)
                }
            })
        }
    }
    
    useEffect(() => {
        if(state.userInfo?.userType == 1){
            getBloggerLists()
        }
    }, [bloggerCurrent])
    const getBloggerLists = ()=>{
        let objS = props.location.state
        objS.current = 1
        objS.size = 100
        Api.marketBloggerApply(objS).then(res=>{
            // console.log(res)
            if(res.code == 200){
                setMarketBloggerList(res.data.demandInfos)
                setBloggerTotal(res.data.total)
                setListId(objS.listId)
                insertHistory()
            }
        })
    }



    const [walletBalance, setWalletBalance] = useState(0)
    useEffect(() => {
        if (applicationId !== -1 && applicationId){
            Api.getWallet().then(res=>{
                if(res.code == 200){
                    setWalletBalance(res.data.walletBalance || res.data.walletBalanceCNY)
                    setIsModalVisible(true)
                }
            })
        }
    }, [applicationId, payType])

    const cooperate = () => {
        if(walletBalance >= cost.application?.cost){
            setPayload(true)
            let data = {
                target: cost.target,
                media: cost.media,
                cooperationWay: cost.cooperationWay,
                listId: listId,
                demandId: demandId,
                applicationId: applicationId,
                payType: 'Balance',
                asettleWay: cost.asettleWay,
            }
            Api.payCooperate(data).then(res => {
                setPayload(false)
                if (res.code === 200) {
                    if (cost.asettleWay === 1) {
                        openNotificationWithIcon('success', '合作成功！')
                        setTimeout(()=>{
                            window.location.reload()
                        },1000)
                        return
                    }
                    if (res.data.payStatus === 1) {
                        setSuccessVisible(true)
                        setIsModalVisible(false)
                    }
                }
            }).catch(res=>{
                setPayload(false)
            })
        }else{
            message.warning('余额不足，请充值！')
        }
    }


    const setHezuo = (item)=>{
        // console.log(item)
        setApplicationId(item.application.id); 
        setCost(item);
        setDemandId(item.application.demandId);
    }




    const [isPk, setIsPk] = useState(false)
    // 获取PK
    const [imgList, setImgList] = useState([])
    const insertHistory = () => {
        Api.insertHistory().then(res => {
            if (res.code == 200) {
                notification.destroy()
                setImgList(res.data)
            }
        })
    }
    useEffect(() => {
        if (imgList.length > 0 && !isPk) {
            openNotification()
        }
    }, [isPk])
    useEffect(() => {
        if (imgList.length > 0) {
            openNotification()
        }
    }, [imgList])

    const openNotification = () => {
        notification.open({
            duration: null,
            getContainer: () => document.getElementById('VideoDemand'),
            placement: 'bottomLeft',
            message: <div style={{ fontWeight: 500 }}>{intl.get('BloggerComparison').BloggerComparison} <span style={{ color: '#888888' }}>{imgList.length}/6</span> </div>,
            description: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                    {
                        imgList?.map((item, index) => {
                            return <img key={index} src={item.avatarMedium} alt=""
                                style={{
                                    width: '40px', height: '40px', borderRadius: '20px', marginRight: '-12px',
                                    border: '3px solid #FFF', position: 'relative', zIndex: 10 - index
                                }} />
                        })
                    }
                </div>
                <div style={{
                    width: '80px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                    borderRadius: '4px', cursor: 'pointer'
                }}
                    onClick={() => pkResult()}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pkdb.png'} alt=""
                        style={{ width: '14px', marginRight: '4px', }} />{intl.get('BloggerComparison').Contrast}
                </div>
            </div>,
            className: 'custom-class',
            style: {
                width: 350,
            },
            closeIcon: <div>{intl.get('BloggerComparison').Empty}</div>,
            onClose: () => {
                notification.destroy()
                Api.delAllPK().then(res => {
                    if (res.code == 200) {
                        insertHistory()
                    }
                })
            }
        });
    };

    const [pkResultList, setPkResultList] = useState([])

    const pkResult = () => {
        let obj = {
            bloggerList: imgList, type: 1
        }
        Api.bloggerCompare(obj).then(res => {
            if (res.code == 200) {
                setPkResultList(res.data)
                setIsPk(true)
            }
        })
    }

    const addPK = (e, item, index) => {
        e.stopPropagation();
        if (imgList.length < 6 && item.userInfo) {
            let obj = {
                bloggerId: item.userInfo.id, uniqueId: item.userInfo.tiktok, nickname: item.userInfo.nickname,
                avatarMedium: item.userInfo.avatarMedium
            }
            Api.insertPk(obj).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }

    const isChecked = (item) => {
        let bools = false
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.userInfo?.id == e.bloggerId) {
                bools = true
                break
            }
        }
        return bools
    }

    const delPkItem = (e, item) => {
        e.stopPropagation()
        let delIndex = -1
        for (let index = 0; index < imgList.length; index++) {
            const e = imgList[index]
            if (item.id == e.bloggerId) {
                delIndex = index
                break
            }
        }
        if (delIndex > -1) {
            Api.delItemPK({ inventoryId: imgList[delIndex].id }).then(res => {
                if (res.code == 200) {
                    insertHistory()
                }
            })
        }
    }

    const delMarket = () => {
        console.log(marketDetail)
        let obj = marketDetail
        obj.operationCode = 2
        Api.marketSubmit(obj).then(res => {
            if (res.code == 200) {
                openNotificationWithIcon('success', '删除成功！')
                setTimeout(()=>{
                    props.navigate(-1)
                },1000)
            }
        })
    }

    const openNotificationWithIcon = (type, message = '')=> {
        notification[type]({
          description: message, top: 100, duration: 3
        });
    };

    const goToRelease = ()=>{
        let obj = props.location.state
        if(marketDetail.sendWay == 1){
            obj.blogger = marketBloggerList[0]?.userInfo
            if(marketBloggerList[0]?.userInfo)props.navigate('/index/releaseDemand', { state: obj })
        }else{
            props.navigate('/index/releaseDemand', { state: obj })
        }
    }


    return (
        marketDetail ? <div id='VideoDemand'>
            <nav style={{ width: '100%', position: 'relative', fontWeight: 500 }}>
                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/nav.png'} alt=""
                    style={{ width: '100%' }} />
                <div style={{
                    width: '100%', height: '100%', position: 'absolute', top: 0, left: 0,
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}>
                    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logoB.png'} alt="" 
                    style={{width:'190px'}}/>
                    <div style={{
                        width: '10px', height: '10px', backgroundColor: 'black', borderRadius: '5px',
                        margin: '0 20px'
                    }}></div>
                    <span style={{ fontSize: '20px' }}>{marketDetail.cooperationWay == 1 ? textJson['短视频需求']:textJson['直播需求']}-{intl.get('ReleaseDemand')[Enum.propertiesTarget[marketDetail.target].val]}</span>
                </div>
            </nav>
            
                   
            <div style={{backgroundColor:'#FFF', paddingBottom:'300px'}}>
                {marketDetail ? 
                <StatusVideoDetail marketDetail={marketDetail} Refresh={getMarketDetail} marketBloggerList={marketBloggerList} /> 
                :''}

                {marketBloggerList.length>0 && (marketDetail.merchantCooperationEnum == 1 ||  marketDetail.merchantCooperationEnum == 0) ? <div style={{ paddingLeft: '222px', borderTop: '1px dashed #eee', marginTop: '24px' }}>
                    <div style={{ margin: '20px 0', color: '#888888', display: 'flex', justifyContent: 'space-between' }}>
                        <div>{marketDetail.sendWay == 2? textJsonL["请在以下申请合作的博主中选择一位进行合作，一旦合作将无法修改"]:
                            textJsonL['您已指定想与该红人合作，请耐心等待红人的反馈结果，一旦红人同意合作将无法修改']}</div>
                        {/* <Pagination simple current={bloggerCurrent} total={bloggerTotal} pageSize={4} /> */}
                    </div>
                    <div style={{ display: 'flex', overflow: 'auto', marginBottom:'30px' }}>
                        {
                            marketBloggerList?.map((item, index) =>
                                <div key={index} style={{ width: '318px', marginRight: '14px', flexShrink: 0, position: 'relative' }}>
                                    <img onClick={(e) => isChecked(item) ? delPkItem(e, item, index) : addPK(e, item, index)}
                                        src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/pk' + (isChecked(item) ? 's' : '') + '.png'} style={{ position: 'absolute', top: 14, right: 24, cursor: 'pointer' }} alt="" />
                                    <div className='blogerBox'>
                                        <div style={{ width: '60px', height: '60px', borderRadius: '60px' }}>
                                            <Image src={item.userInfo?.avatar || item.userInfo?.avatarMedium || ''} style={{borderRadius:'50%'}}
                                                width={60} height={60} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'} />
                                        </div>
                                        <div style={{ marginLeft: '15px' }}>
                                            <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{item.userInfo?.nickname}</div>
                                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {item.userInfo?.tiktok}</div>
                                            <div style={{ color: '#F7385B', fontSize: '12px', lineHeight: '14px' }}>
                                                {textJsonL['合作佣金']}： $<span style={{ fontSize: 12, fontWeight: '800', fontFamily: 'neue' }}>
                                                    {item.application?.costDollar
                                                        || (item.cooperationWay == 1 && item.item?.sendSample === 1 && item.asettleWay != 1 ? Math.floor(item.item?.duration / 60) * (item?.userInfo?.videoQuotationDollar || 0) : 0)
                                                        ||(item.cooperationWay == 2 && item.live?.sendSample === 1 && item.asettleWay != 1 ? Math.floor(item.live?.durationH) * (item?.userInfo?.liveBroadcastQuotationDollar || 0) : 0)
                                                        || 0}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', padding:'0 10px',
                                        border: '1px solid #eee', borderBottom:0, paddingTop:'20px' }}>
                                        <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:'bold', lineHeight:'15px'}}>{toKMB(item.userInfo?.followerCount, 2)}</div>
                                            <span style={{fontSize:'12px', color:'#888888'}}>{intl.get('BloggerRanking').FansNum}</span>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:'bold', lineHeight:'15px'}}>${item.userInfo?.videoQuotationDollar}/{intl.get('NewLogin')['60s内']}</div>
                                            <span style={{fontSize:'12px', color:'#888888'}}>{intl.get('Index')['视频单价']}</span>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                            <div style={{fontWeight:'bold', lineHeight:'15px'}}>${item.userInfo?.liveBroadcastQuotationDollar}/{intl.get('NewLogin')['1h内']}</div>
                                            <span style={{fontSize:'12px', color:'#888888'}}>{intl.get('Index')['直播单价']}</span>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #eee', padding: '20px 23px', borderTop:0 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                            <div style={{ width: '128px', height: '32px', borderRadius: '5px', cursor: 'pointer', border: '1px solid #eee', color: '#888888', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/zhuye.png'} style={{ height: '12px', width: '12px' }} alt="" />
                                                <div style={{ fontSize: '12px', marginLeft: '5px' }}
                                                    onClick={e => window.open('https://www.tiktok.com/@' + item.userInfo?.tiktok)}>{textJsonL["博主主页"]}</div>
                                            </div>
                                            {marketDetail.sendWay == 2? <div style={{ width: '128px', height: '32px', borderRadius: '5px', color: '#fff', background: 'linear-gradient(180deg, #F7385B, #FF716E)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={e =>setHezuo(item)}>
                                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/cooperation.png'} style={{ height: '12px', width: '12px' }} alt="" />
                                                <div style={{ fontSize: '12px', marginLeft: '5px' }}>{textJsonL["与TA合作"]}</div>
                                            </div> :
                                            <div style={{ width: '128px', height: '32px', borderRadius: '5px',border: '1px solid #eee', color: item.application?.status == 6||item.application?.status == 0?'#888888':'#fff', background: item.application?.status == 6||item.application?.status == 0?'#FFF':'linear-gradient(180deg, #F7385B, #FF716E)', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {item.application?.status == 6||item.application?.status == 0?<img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/hz.png'} style={{ width: '12px' }} alt="" /> :
                                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/cooperation.png'} style={{ height: '12px', width: '12px' }} alt="" />}
                                                <div style={{ fontSize: '12px', marginLeft: '5px' }}
                                                    onClick={e =>item.application?.status == 1 ?setHezuo(item): null}>{(item.application?.status == 6||item.application?.status == 0)?'待红人接受':item.application?.status == 1?'已接受，待付款':''}</div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>:''}


                <div style={{width:'calc(100% - 63px - 30px)', borderTop:'1px dashed #CCCCCC', marginLeft:'63px',
                    }}>
                    {marketDetail.cooperationWay == 1 ? <div className='VideoDemandDetail' style={{width:'1100px', margin:'0 auto'}}>
                        <div style={{display:'flex', alignItems:'center', marginTop:'37px',
                            fontSize:'16px', fontWeight:'bold'}}>
                            <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                                marginRight:'16px'}}></div> 
                            {textJson['制作要求']}
                        </div> 
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['镜头要求']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.item.viewDemand}</div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['口播要求']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.item.mouthDemand}</div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['标题要求']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.item.titleDemand}</div>
                            </div>
                        </div>
                        {
                            otheList?.map(item=>{
                                return marketDetail.item['other'+item+'Demand'] ? <div key={item} style={{paddingLeft:'40px', marginTop:'20px'}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['其他要求']}{item}</div>
                                        <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                            lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.item['other'+item+'Demand']}</div>
                                    </div>
                                </div> : ''
                            })
                        }
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600, marginTop:'13px'}}>{textJson['示例视频']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'25px', padding:'12px 15px'}} >
                                    {marketDetail.item.demos}
                                </div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600, marginTop:'13px'}}>{textJson['补充说明']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >
                                    {marketDetail.item.tips}
                                </div>
                            </div>
                            {
                                marketDetail.item.tipFiles?.map((item,index)=>{
                                    return <div key={index} style={{marginLeft:'105px', color:'#F7385B', cursor:'pointer'}}
                                        onClick={()=>window.location.href = item.url}>
                                        <LinkOutlined style={{color:'#333', marginRight:'10px', marginTop:'10px'}} />{item.name}
                                    </div>
                                })
                            } 
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'60px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['博主截稿日期']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.item.bloggerPubDeadline?.substring(0,10)}</div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['期望保留时长']}</div>
                                <div style={{minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.item.retainDay==-1?textJson['不要求']:marketDetail.item.retainDay}{marketDetail.item.retainDay==-1?'':textJson['天']}</div>
                            </div>
                        </div>
                    </div>: 
                    // 
                    marketDetail.cooperationWay == 2 ? <div className='VideoDemandDetail' style={{width:'1100px', margin:'0 auto'}}>
                        <div style={{display:'flex', alignItems:'center', marginTop:'37px',
                            fontSize:'16px', fontWeight:'bold'}}>
                            <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                                marginRight:'16px'}}></div> 
                            {textJson['制作要求']}
                        </div> 
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['直播要求']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.live.liveDemand}</div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600, marginTop:'13px'}}>{textJson['补充说明']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >
                                    {marketDetail.live.tips}
                                </div>
                            </div>
                            {
                                marketDetail.live.tipFiles?.map((item,index)=>{
                                    return <div key={index} style={{marginLeft:'105px', color:'#F7385B', cursor:'pointer'}}
                                        onClick={()=>window.location.href = item.url}>
                                        <LinkOutlined style={{color:'#333', marginRight:'10px', marginTop:'10px'}} />{item.name}
                                    </div>
                                })
                            } 
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'60px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['博主截稿日期']}</div>
                                <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.live.bloggerPubTime?.substring(0,10)}</div>
                            </div>
                        </div>
                    </div>: ''}
                    {marketDetail.sendWay == 2 ? <div className='VideoDemandDetail' style={{width:'1100px', margin:'0 auto'}}>
                        <div style={{paddingLeft:'40px', marginTop:'60px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['红人擅长分类']}</div>
                                <div style={{width:'450px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px', display:'flex'}} >
                                        {
                                            marketDetail.cargoClassification.split(',')?.map((item,index)=>{
                                                return <div key={index}>
                                                    {
                                                        classList?.map((itemS,indexS)=>{
                                                            return item == itemS.id ? <span key={indexS} style={{marginRight:'5px', minWidth: itemS.goodsName!=''?'100px':''}}>{lang=='zh'?itemS.goodsName:itemS.goodsEnglishName}</span> : ''
                                                        })
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'40px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['红人所属国籍']}</div>
                                <div style={{width:'450px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                    lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.nationality?.val}</div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'15px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['红人粉丝范围']}</div>
                                <div style={{width:'450px', display:'flex', alignItems:'center'}}>
                                    <div style={{width:'204px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                        lineHeight:'20px', padding:'12px 15px'}} >{toKMB(marketDetail.followerCountFrom >= 0 ? marketDetail.followerCountFrom:'')}</div> <span style={{margin:'0 14px'}}>～</span>
                                    <div style={{width:'204px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                        lineHeight:'20px', padding:'12px 15px'}} >{toKMB(marketDetail.followerCountTo>0?marketDetail.followerCountTo:'')}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingLeft:'40px', marginTop:'27px'}}>
                            <div style={{display:'flex'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['智能派发']}</div>
                                <div style={{width:'450px', height:'48px', borderRadius:'4px',
                                    lineHeight:'20px', padding:'0 15px'}} >
                                        {marketDetail.intelligent == 0 ? textJson['授权智能派发']:textJson['不授权智能派发']}
                                    <div style={{height:'48px', borderRadius:'4px', color:'#CCCCCC', fontSize:'12px',
                                        lineHeight:'20px'}} >
                                        {marketDetail.intelligent == 0 ? textJson['系统将根据筛选条件智能调度更多博主参与，可能扩招到圈选范围外的博主'] :textJson['系统将严格遵守您圈选范围内的博主参与，不会智能调度其他博主']}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:''}
                </div>
                {
                    marketDetail.application && marketDetail.application?.status!=6 ? <div style={{width:'calc(100% - 63px - 30px)', borderTop:'1px dashed #CCCCCC', marginLeft:'63px',
                        marginTop:'43px'}}>
                        <div className='VideoDemandDetail' style={{width:'1100px', margin:'0 auto'}}>
                            <div style={{display:'flex', alignItems:'center', marginTop:'37px',
                                fontSize:'16px', fontWeight:'bold'}}>
                                <div style={{display:'inline-block', width:'4px', height:'20px', backgroundColor:'#F7385B',
                                    marginRight:'16px'}}></div> 
                                {textJsonL['样品寄送地址']}
                            </div> 
                            <div style={{display:'flex'}}>
                                <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['收件人']}</div>
                                        <div className='application' style={{width:'360px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                            lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.application?.consigneeName}</div>
                                    </div>
                                </div>
                                <div style={{paddingLeft:'73px', marginTop:'20px'}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['手机号码']}</div>
                                        <div className='application' style={{width:'360px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                            lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.application?.consigneePhone}</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{intl.get('InfluencerAccount')['邮编']}</div>
                                    <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                        lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.application?.consigneePostCode}</div>
                                </div>
                            </div>
                            <div style={{paddingLeft:'40px', marginTop:'20px'}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}>{textJson['收件地址']}</div>
                                    <div style={{width:'900px', minHeight:'48px', borderRadius:'4px', border:'1px solid #EEEEEE',
                                        lineHeight:'20px', padding:'12px 15px'}} >{marketDetail.application?.consigneeSpecificAddress}</div>
                                </div>
                            </div>
                        </div>
                    </div>:''
                }
                {state.userInfo?.userType == 1 && (marketDetail.merchantCooperationEnum == 1 || marketDetail.merchantCooperationEnum == 0) ? <div style={{width:'calc(100% - 63px - 30px)', marginLeft:'63px',}}>
                    <div className='VideoDemandDetail' style={{width:'1100px', margin:'0 auto'}}>
                        <div style={{paddingLeft:'40px', marginTop:'40px'}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'105px', minWidth:'105px', fontWeight: 600}}></div>
                                <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', background: 'linear-gradient(180deg, #F7385B, #FF716E)', color: '#FFF' }}
                                    onClick={delMarket}>{textJsonL['删除需求']}</div>
                                {marketBloggerList.length == 0 || marketDetail.merchantCooperationEnum == 0 ? <div style={{ width: '160px', height: '38px', lineHeight: '36px', borderRadius: '5px', textAlign: 'center', cursor: 'pointer', marginTop: '14px', border: '1px solid #eee', color: '#666666', marginLeft:'18px' }}
                                    onClick={() => goToRelease()}>{textJsonL['编辑需求']}</div>:''}
                            </div>
                        </div>
                    </div>
                </div>:''}
            </div>

            <div id='BloggerRanking'>
                {isPk ? <PkModel clickFun={() => setIsPk(false)} pkResultList={pkResultList} /> : ''}    
            </div>


            <Modal visible={isModalVisible} onCancel={e => { clearInterval(interval.current); setApplicationId(-1); setIsModalVisible(false); }} centered={true} footer={null} destroyOnClose={true}
                getContainer={() => document.getElementById('VideoDemand')}>
                <Spin spinning={payload}>
                    <div style={{ padding: '36px', width: '602px' }}>
                        <div style={{ fontWeight: '800', fontSize: '18px', marginBottom: '43px', textAlign: 'center' }}>{textJsonL["支付博主佣金与TA合作"]}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ width: '90px', color: '#666666' }}>{textJsonL["合作主播"]}</div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                                    <Image src={cost.userInfo?.avatarMedium} width={60} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{cost.userInfo?.nickname}</div>
                                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                                    </div>
                                    <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {cost.userInfo?.tiktok}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '43px' }}>
                            <div style={{ width: '90px', color: '#666666' }}>{textJsonL["内容形式"]}</div>
                            <div style={{ fontWeight: '800' }}>
                                {marketDetail.cooperationWay ==1 ? textJsonL["短视频"]:intl.get('ReleaseDemand')["直播"]} — {marketDetail.target === 1 ? textJsonL["电商卖货"] : marketDetail.target === 3 ? intl.get('ReleaseDemand')["商品种草"] : intl.get('ReleaseDemand')["品牌传播"]}
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems:'center', marginTop: '30px' }}>
                            <div style={{ width: '90px', flexShrink: '0', color: '#666666' }}>{textJson["内容形式"]}</div>
                            <div>
                                账户余额（¥{walletBalance}）
                            </div>
                            <div style={{
                                width: '86px', height: '30px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                                borderRadius: '4px', cursor: 'pointer', marginLeft:'10px'
                            }} onClick={()=> props.navigate('/index/accountRecharge')}>
                                立即充值
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px', borderTop: '1px dashed #eee', paddingTop: '20px' }}>
                            <div style={{ color: '#666' }}>
                                {textJsonL["总计费用："]}<span style={{ color: '#F7385B', fontSize: '20px', fontFamily: 'neue', fontWeight: '800' }}>${cost.application?.costDollar}</span>({textJsonL["博主佣金"]}: <span style={{ fontFamily: 'neue' }}>${cost.application?.costDollar - cost.application?.payTipDollar}</span> + {textJsonL["平台手续费"]}: <span style={{ fontFamily: 'neue' }}>${cost.application?.payTipDollar}</span>)
                            </div>
                            <div style={{
                                width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '14px',
                                borderRadius: '4px', cursor: 'pointer', marginTop: '40px'
                            }} onClick={cooperate}>
                                确定支付建立合作
                            </div>
                            <div style={{ textAlign: 'center', width: '440px', fontSize: '12px', color: '#888', marginTop: '12px' }}>{textJsonL["温馨提示：支付的佣金会暂存在本平台上，当博主完成合作后才会转入博主账户； 如需开票请支付成功后联系客服，如遇到付款问题请联系客服"]}</div>
                        </div>
                    </div>
                </Spin>
            </Modal>

            <Modal visible={successVisible} onCancel={e => { setSuccessVisible(false) }} centered={true} footer={null} destroyOnClose={true}
                getContainer={() => document.getElementById('VideoDemand')}>
                <div style={{ padding: '16px', width: '443px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/suc.png'} style={{ width: 52, height: 49 }} alt="" />
                        <div style={{ fontSize: '18px', fontWeight: '800' }}>{textJsonL["恭喜您与该博主达成合作！"]}</div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '36px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px', height: '60px', overflow: 'hidden', borderRadius: '60px' }}>
                            <Image src={cost.userInfo?.avatarMedium} width={60} preview={false} fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/userM.jpg'} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ fontSize: '14px', color: '#333333', fontWeight: '800', fontFamily: 'neue' }}>{cost.userInfo?.nickname}</div>
                                <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/yj_gerenrenzheng.png'} style={{ width: 14, height: 14, marginLeft: '6px' }} alt="" />
                            </div>
                            <div style={{ fontSize: 12, color: '#888888', fontFamily: 'neue' }}>TikTok: {cost.userInfo?.tiktok}</div>
                        </div>
                    </div>
                    <div style={{ width: 294, textAlign: 'center', marginTop: '24px', fontWeight: '500' }}>{textJsonL["请耐心等待，博主将于2022-04-01 18:30:00前上传短视频作品，届时请您注意查看"]?.replace(/2022-04-01 18:30:00/, cost.item?.bloggerPubDeadline || cost.live?.bloggerPubTime)}</div>
                    <div style={{
                        width: '210px', height: '40px', background: 'linear-gradient(180deg, #F7385B, #FF716E)',
                        display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF', fontSize: '12px',
                        borderRadius: '4px', cursor: 'pointer', marginTop: '50px'
                    }} onClick={e => { setTimeout(()=>{
                        window.location.reload()
                    },1000); setSuccessVisible(false) }}>
                        {textJsonL["我知道啦"]}
                    </div>
                </div>
            </Modal>





        </div> : <Spin tip="数据加载中..." spinning={true}><div style={{width:'100%', height:'100vh'}}></div></Spin>
    )
}
export default withRouter(VideoDemandDetail);