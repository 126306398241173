import React, { useState, useEffect, useRef } from 'react'
import withRouter from '../../../utils/withRouter'
import { Input, message, Select, Checkbox, Image, Spin, Pagination, Divider, Popconfirm, Tooltip } from 'antd';
import * as Api from '../../../service/https';
import '../../../asset/css/MyGoods.scss'
import intl from 'react-intl-universal';
import openNotificationWithIcon from "../../../components/Notification";

const suffix = (
    <img src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/search.png'} style={{ width: '20px' }} alt="" />
);
const { Option } = Select

const MyGoods = (props) => {
    const [active_index, active_indexSet] = useState(-1)
    const [searchParams, searchParamsSet] = useState({})
    const [list, listSet] = useState([])
    const [selectList, selectListSet] = useState([])
    const [id_list, id_listSet] = useState([])
    const [open_status, open_statusSet] = useState(-1)
    const [isSpinning, isSpinningSet] = useState(false)
    const [focus, focusSet] = useState(-1)
    const [loading, setLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [sortFansIndex, setRangeFansNumDto] = useState(-1)
    const [total, totalSet] = useState(0)
    const [goodsType, goodsTypeSet] = useState([])
    const [sortNumList, setItems] = useState([
        { start: 0, key: '<', end: 100 },
        { start: 100, key: '~', end: 200 },
        { start: 200, key: '~', end: 500 },
        { start: 500, key: '~', end: 1000 },
        { start: 1000, key: '<', end: 0 },
        { start: '', key: '~', end: '' },
    ]);

    useEffect(e => {
        getCategoryAll()
    }, [])

    useEffect(() => {
        getMyProduct()
    }, [searchParams, pageIndex, active_index])

    const getCategoryAll = e => {
        Api.getCategoryAll().then(res => {
            if (res.code === 200) {
                goodsTypeSet(res.data)
            }
        })
    }

    const getMyProduct = e => {
        setLoading(true)
        let data = {
            productStatus: active_index,
            current: pageIndex,
            size: 10,
            priceMin: 0,
            priceMax: 0,
            monthMin: 0,
            monthMax: 0,
            orderField: active_index === 3 ? 3 : 2,
            sortWay: 2
        }
        Api.getMyProduct({ ...data, ...searchParams }).then(res => {
            if (res.code === 200) {
                let t = res.data.items.map(item => {
                    return item?.productDetail?.productId
                })
                let vmList = res.data.items.map(item => {
                    item.showCommissionInput = item?.productDetail?.commissionPercent ? false : true
                    item.vmCommissionPercent = item?.productDetail?.commissionPercent || 0
                    return item
                })
                setIndeterminate(false)
                setCheckAll(false)
                selectListSet([])
                setCheckedList([])
                id_listSet(t)
                listSet(pageIndex <= 1 ? vmList : [...vmList,...list])
                totalSet(res.data.total)
                setLoading(false)
                product_my_stat()
            }
        })
    }

    const [stat_total, stat_totalSet] = useState({})

    const product_my_stat = e => {
        Api.product_my_stat().then(res => {
            if (res.code === 200) {
                stat_totalSet(res.data)
            }
        })
    }

    const tabsChange = e => {
        setPageIndex(1)
        open_statusSet(-1)
        active_indexSet(e)
    }

    const searchChange = (e, id) => {
        let t = JSON.parse(JSON.stringify(searchParams))
        t[id] = e
        searchParamsSet(t)
    }

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (e) => {
        setCheckedList(e);
        setIndeterminate(!!e.length && e.length < list.length);
        setCheckAll(e.length === list.length);
        let s = []
        list.map((item, index) => {
            if (e.indexOf(item?.productDetail?.productId) !== -1) {
                s.push(item?.productDetail?.productId)
            }
        })
        selectListSet(s)
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? id_list : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const copyItemData = item => {
        Api.copySupplyProductDetail({productId: item?.productDetail?.productId}).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', '复制成功！')
                getMyProduct()
            }
        })
    }

    const copyFun = (value, e) => {
        e.stopPropagation()
        // for ie
        if (window.clipboardData) {
            window.clipboardData.clearData();
            window.clipboardData.setData('text', value);
            message.success("复制成功~")
        }
        // for modern browser
        else if (document.execCommand) {
            var element = document.createElement('SPAN');
            element.textContent = value;
            document.body.appendChild(element);
            if (document.selection) {
                var range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                var range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            document.execCommand('copy');
            element.remove ? element.remove() : element.removeNode(true);
            message.success("复制成功~")
        } else {
            message.warn("您所用的浏览器不支持快捷复制，请手动复制！")
        }
    }

    const [goods_var, goods_varSet] = useState([])
    const [commissionIndex, setCommissionIndex] = useState(-1)
    const [commission, setCommission] = useState('')

    const openEditCommission = (e, item, index) => {
        // setCommission(item?.productDetail?.commissionPercent || 0)
        let t = JSON.parse(JSON.stringify(list))
        t[index].showCommissionInput = true
        listSet(t)
        setCommissionIndex(index)
    }

    const changeCommission = (value,index) => {
        // setCommission(value < 0 ? 0 : value > 80 ? 80 : value)
        let t = JSON.parse(JSON.stringify(list))
        t[index].vmCommissionPercent = value < 0 ? 0 : value > 80 ? 80 : value
        listSet(t)
    }

    const blurCommissionInput = (e,item,index) => {
        let t = JSON.parse(JSON.stringify(list))
        t[index].vmCommissionPercent = item.vmCommissionPercent < 0 ? 0 : Number(item.vmCommissionPercent)
        listSet(t)
        setCommissionIndex(index)
    }

    const saveCommission = (e,item,index) =>{
        e.preventDefault()
        let vmList = JSON.parse(JSON.stringify(list))
        let data = Object.assign({},item.productDetail)
        data.commissionPercent = Number(item.vmCommissionPercent)
        setLoading(true)
        Api.editMyGoods(data).then(res => {
            if (res.code === 200) {
                openNotificationWithIcon('success', '修改成功！')
                vmList[index].productDetail.commissionPercent = Number(item.vmCommissionPercent)
                vmList[index].vmCommissionPercent = Number(item.vmCommissionPercent)
                vmList[index].showCommissionInput = false
                listSet(vmList)
                setCommissionIndex(-1)
                setLoading(false)
            }
        })
    }

    const openStatusChange = (e, item) => {
        focusSet(-1)
        if (e === open_status) {
            open_statusSet(-1)
        } else {
            let t = item.productDetail.variationList
            goods_varSet(t)
            open_statusSet(e)
            isSpinningSet(true)
            setTimeout(() => {
                isSpinningSet(false)
            }, 1000);
        }
    }

    const goods_varChange = (e, i, id) => {
        let t = JSON.parse(JSON.stringify(goods_var))
        t[i][id] = e
        goods_varSet(t)
    }

    const save = e => {
        let data = {
            productId: e,
            variationList: goods_var
        }
        let isFloors = true
        for (let index = 0; index < goods_var.length; index++) {
            const element = goods_var[index];
            if(element.price ==0 || element.quantity ==0){
                isFloors = false
                break
            }

        }
        if(JSON.stringify(goods_var).indexOf(('""')) === -1 && isFloors){
            Api.variation_listSubmit(data).then(res => {
                if (res.code === 200) {
                    if (pageIndex === 1) {
                        getMyProduct()
                    } else {
                        setPageIndex(1)
                    }
                    open_statusSet(-1)
                    message.success('保存成功！')
                }
            })
        }else{
            message.info('不能为空或0！')
        }
    }

    const changeRangeFansNumDto = (index) => {
        let s = searchParams
        setRangeFansNumDto(index == undefined ? -2 : index)
        if (index == undefined) {
            let v = JSON.parse(JSON.stringify(sortNumList))
            v[5] = { start: '', key: '~', end: '' }
            setItems(v)
            s['priceMin'] = 0
            s['priceMax'] = 0
        } else {
            s['priceMin'] = sortNumList[index].start
            s['priceMax'] = sortNumList[index].end
        }
        searchParamsSet(s)
        if (pageIndex === 1) {
            getMyProduct()
        } else {
            setPageIndex(1)
        }
    }

    const changeMinInput = (index, value) => {
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].start = value
        setItems(v)
    }

    const changeMaxInput = (index, value) => {
        let v = JSON.parse(JSON.stringify(sortNumList))
        v[index].end = value
        setItems(v)
    }

    const clear = e => {
        changeRangeFansNumDto(undefined)
        searchParamsSet({})
    }

    const productMstatus = (e, status) => {
        let data = {
            productIds: e || checkedList,
            productStatus: status
        }
        Api.productMstatus(data).then(res => {
            if (res.code === 200) {
                if (pageIndex === 1) {
                    getMyProduct()
                } else {
                    setPageIndex(1)
                }
            }
        })
    }

    let img_url = 'https://zc-tk.oss-cn-beijing.aliyuncs.com/obj/tos-maliva-p-0068/8612e395c85047809c4348ea6754ab4b.jpg'

    const goToRelease = (productId = false) => {
        if (productId) {
            // store.dispatch({ type: 'changeRouter', value: `/releaseProducts&&/products` })
            props.navigate('/index/releaseProducts', { state: { productId } })
        } else {
            props.navigate('/index/releaseProducts')
        }
    }

    const delMyGoods = (e, status) => {
        let data = {
            productIds: e || checkedList,
            productStatus: status
        }
        Api.delMyGoods(data).then(res => {
            if (res.code === 200) {
                if (pageIndex === 1) {
                    getMyProduct()
                } else {
                    setPageIndex(1)
                }
            }
        })
    }

    const toDetail = (item, e) => {
        e.stopPropagation()
        window.open(`/index/GoodsDetail/${item.productDetail.productId}`)
    }

    const initEditCommission = () =>{
        let t = JSON.parse(JSON.stringify(list))
        t[commissionIndex].showCommissionInput = false
        t[commissionIndex].vmCommissionPercent = t[commissionIndex]?.productDetail.commissionPercent
        listSet(t)
        setCommissionIndex(-1)
    }

    useEffect(()=>{
        if(commissionIndex >= 0) {
            window.addEventListener('click', initEditCommission)
        }
        return ()=>{
            window.removeEventListener('click',initEditCommission)
        }
    },[commissionIndex])

    const gotoDetail = productId => {
        if (productId) {
            // store.dispatch({ type: 'changeRouter', value: `/releaseProducts&&/products` })
            props.navigate('/index/releaseProducts', { state: { productId } })
        } else {
            props.navigate('/index/releaseProducts')
        }
    }

    return (
        <div id='MyGoods'>
            <div style={{ display: 'flex', alignItems:'center' }}>
                <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/before.png" alt=""
                style={{width:'4px'}}/>
                <div style={{fontSize:'20px', fontWeight:600, marginLeft:'9px'}}>管理店铺商品</div>
            </div>
            <div className='tabs'>
                <div className={active_index === -1 ? 'active' : ''} onClick={e => tabsChange(-1)}>全部({stat_total?.total || 0})</div>
                <div className={active_index === 1 ? 'active' : ''} onClick={e => tabsChange(1)}>生效中({stat_total?.totalValid || 0})</div>
                <div className={active_index === 2 ? 'active' : ''} onClick={e => tabsChange(2)}>已下架({stat_total?.totalInvalid || 0})</div>
                <div style={{ borderLeft: '1px solid #888', height: '13px', cursor: 'default', marginTop:'5px' }}></div>
                <div className={active_index === 3 ? 'active' : ''} onClick={e => tabsChange(3)}>草稿({stat_total?.totalDraft || 0})</div>
                <div className={active_index === 4 ? 'active' : ''} onClick={e => tabsChange(4)}>已删除({stat_total?.totalDeleted || 0})</div>
                <div onClick={() => goToRelease()} className='btn_top' style={{ width: '110px', height: '38px', lineHeight: '38px', position: 'absolute', right: 0, top: '-45px', margin:0, borderRadius: '5px' }}>添加商品</div>
            </div>
            <div id='fans' className='search'>
                <div id='login'>
                    <Input onChange={e => searchChange(e.target.value, 'productNameOrNo')} value={searchParams.productNameOrNo} style={{ width: '300px', height: '30px' }} placeholder={'按照商品名称、编号搜素'} suffix={suffix} allowClear />
                </div>
                {/* <div style={{ margin: '0 10px 0 30px' }}>价格</div> */}
                <Select
                    onClear={e => changeRangeFansNumDto(undefined)}
                    allowClear={true}
                    dropdownRender={menu => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <div id='login' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px', paddingBottom: '5px' }}>
                                <Input value={sortNumList[5].start} onChange={e => changeMinInput(5, e.target.value)} style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="min" />
                                ~
                                <Input value={sortNumList[5].end}
                                    onChange={e => changeMaxInput(5, e.target.value)}
                                    style={{ width: '50px', paddingLeft: '4px', paddingRight: '4px', textAlign: 'center' }} placeholder="max" />
                                <span style={{
                                    border: parseInt(sortNumList[5].end)>= 0 || parseInt(sortNumList[5].start)>= 0 ? '' : '1px solid #EEEEEE', borderRadius: '4px', padding: '5.5px 11px', marginLeft: '5px', cursor: 'pointer',
                                    color: parseInt(sortNumList[5].end)>= 0 || parseInt(sortNumList[5].start)>= 0 ? '#FFF' : '#CCCCCC',
                                    background: parseInt(sortNumList[5].end)>= 0 || parseInt(sortNumList[5].start)>= 0 ? '#F7385B' : '#FFF', fontSize: '12px'
                                }}
                                    onClick={() => parseInt(sortNumList[5].end)>= 0 || parseInt(sortNumList[5].start)>= 0 ? changeRangeFansNumDto(5) : null}>确定</span>
                            </div>
                        </>
                    )}
                    value={
                        (sortFansIndex == -1 || sortFansIndex == -2) ?
                            <span>{intl.get('TopicList').PleaseSelect}</span>
                            :
                            (
                                (
                                    sortNumList[sortFansIndex].start > 0 ?
                                        '$' + sortNumList[sortFansIndex].start
                                        :
                                        ''
                                ) + ' ' + sortNumList[sortFansIndex].key + ' ' + (
                                    sortNumList[sortFansIndex].end > 0 ?
                                        '$' + sortNumList[sortFansIndex].end
                                        :
                                        ''
                                )
                            )
                    }
                    style={{ width: 200, borderRadius: '26px', marginLeft: '10px' }}
                    getPopupContainer={() => document.getElementById('MyGoods')}
                    placeholder={'选择价格区间'}>
                    {
                        sortNumList.map((item, index) => (
                            index < 5 ?
                                <Option key={index}>
                                    <div onClick={e => changeRangeFansNumDto(index)}>
                                        {item.start > 0 ? '$' + item.start : ''} {item.key} {item.end > 0 ? '$' + item.end : ''}
                                    </div>
                                </Option>
                                :
                                ''
                        ))
                    }
                </Select>
                <div style={{ margin: '0 10px 0 10px' }}></div>
                <Select onChange={e => searchChange(e, 'categoryId1')} value={searchParams.categoryId1} placeholder={'选择类目'} style={{ width: 200 }} allowClear>
                    {
                        goodsType.map((item, index) => (
                            <Option key={index} value={item.id}>{item.name}</Option>
                        ))
                    }
                </Select>
                <div style={{ color: '#F7385B', backgroundColor: '#FFEEF1', border: '1px solid #F7385B', padding: '4px 22px', borderRadius: '5px', marginLeft: '30px', cursor: 'pointer' }} onClick={e => clear()}>重置</div>
            </div>
            <div className='goods_main'>
                <div style={{ paddingTop: 24 }}>
                    <div style={{ marginRight: '30px' }}>已选：<span style={{color:'#F7385B'}}>{checkedList.length}</span></div>
                    <div className={checkedList.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === -1 || active_index === 2 ? '' : 'none', cursor:'pointer' }} onClick={e => checkedList.length > 0 ? productMstatus('', 1) : ''}>上架</div>
                    <div className={checkedList.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === -1 || active_index === 1 ? '' : 'none', cursor:'pointer' }} onClick={e => checkedList.length > 0 ? productMstatus('', 2) : ''}>下架</div>
                    <Popconfirm
                        title="确认删除已选中的商品?"
                        disabled={!checkedList.length}
                        onConfirm={e => checkedList.length > 0 ? active_index === 3 ? delMyGoods('', 4) : productMstatus('', 4) : ''}
                        okText="确定"
                        cancelText="取消">
                        <div className={checkedList.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === 4 ? 'none' : '', cursor:'pointer' }}>删除</div>
                    </Popconfirm>
                    <div className={checkedList.length > 0 ? 'btn_active' : 'btn'} style={{ marginRight: '16px', display: active_index === 4 ? '' : 'none', cursor:'pointer' }} onClick={e => checkedList.length > 0 ? productMstatus('', 2) : ''}>恢复</div>
                </div>
                <div style={{fontWeight:500, fontSize:'13px', color:'#666'}}>
                    <Checkbox style={{ width: '18px' }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}></Checkbox>
                    <div style={{ width: '270px', marginLeft: '15px' }}>商品</div>
                    <div style={{ width: '80px'}}>SKU</div>
                    <div style={{ width: '60px' }}>库存量</div>
                    <div style={{ width: '60px' }}>库存地</div>
                    <div style={{ width: '60px' }}>供货价</div>
                    <div style={{ width: '80px' }}>物流成本</div>
                    <div style={{ width: '90px' }}>建议零售价</div>
                    <div style={{ width: '60px' }}>佣金率</div>
                    <div style={{ width: '80px' }}>可售地区</div>
                    <div style={{ width: '145px' }}>更新时间</div>
                    <div style={{ width: '70px' }}>状态</div>
                    <div style={{ width: '60px' }}>操作</div>
                </div>
            </div>
            <Spin tip="数据加载中..." spinning={loading}>
                <Checkbox.Group value={checkedList} style={{ width: '100%' }} onChange={onChange}>
                    <div className='list'>
                        {
                            list.length > 0 ?
                                list.map((item, index) => {
                                    return (
                                        <div key={index} style={{fontWeight:500,background:'#FFF', borderRadius:'5px',
                                            width:'100%', height:'100px', padding:'20px 24px', display:'flex', alignItems:'center'}}>
                                            <Checkbox value={item?.productDetail?.productId} style={{ width: '18px' }}></Checkbox>
                                            <div style={{ width: '270px', marginLeft: '15px', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                                <Image src={ item?.productDetail?.productImgUrls ? item?.productDetail?.productImgUrls[0] : ''}
                                                       style={{width:'60px', height:'60px', borderRadius:'4px'}}
                                                       fallback={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/coverM.png'}/>
                                                <div style={{marginLeft:'12px', marginRight:'30px'}}>
                                                    <Tooltip placement="top" title={<span style={{fontSize:'12px'}}>{item?.productDetail?.productName}</span>}>
                                                        <div className='over' style={{fontWeight:600, lineHeight:'15px'}}>{item?.productDetail?.productName || '-'}</div>
                                                    </Tooltip>
                                                    <div style={{fontSize:'12px', color:'#888888', marginTop:'8px', lineHeight:'14px'}}>
                                                        ID: {item?.productDetail?.productNo || '-'}
                                                        <img src='https://zc-tk.oss-cn-beijing.aliyuncs.com/fuzhi.png' style={{ width: 9, height: 10, marginLeft: 5, cursor: 'pointer', marginTop: -1 }} onClick={e => copyFun(item?.productDetail?.productNo, e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ width: '80px'}}>
                                                {
                                                    item?.productDetail?.variationList.length > 1 ?
                                                        '多个属性'
                                                        :
                                                        item?.productDetail?.variationList.length === 0 ?
                                                            '--'
                                                            :
                                                            (item?.productDetail?.variationList[0]?.var1 || '' + item?.productDetail?.variationList[0]?.var2 || '' + item?.productDetail?.variationList[0]?.var3 || '')
                                                }
                                            </div>
                                            <div style={{ width: '60px' }}>{item.productQuantity || '0'}</div>
                                            <div style={{ width: '60px' }}>{item?.productDetail?.repertoryCountry?.val}</div>
                                            <div style={{ width: '60px' }}>${item?.productPrice || '0'}</div>
                                            <div style={{ width: '80px' }}>-</div>
                                            <div style={{ width: '90px' }}>${item?.productDetail?.retailPrice || '0'}</div>
                                            <div style={{ width: '60px' }}>{item?.commissionPercent ? item?.commissionPercent + '%' : '-'}</div>
                                            <div style={{ width: '80px' }}>{item?.productDetail?.salesCountry?.val}</div>
                                            <div style={{ width: '145px' }}>{item?.updateTime || '-'}</div>
                                            <div style={{ width: '70px' }}>
                                                 <span style={{background: item?.productDetail?.productStatus === 1 ? '#DBFFEB' : '#F6F6F6', fontSize:'12px', borderRadius:'6px', color: item?.productDetail?.productStatus === 1 ? '#40D782' : '#888888', padding:'1px 6px'}}>
                                                {
                                                    item?.productDetail?.productStatus === 1 ?
                                                       intl.get('MyGoods')['生效中']
                                                        :
                                                        item?.productDetail?.productStatus === 2 ?
                                                            intl.get('MyGoods')['已下架']
                                                            :
                                                            item?.productDetail?.productStatus === 3 ?
                                                                intl.get('MyGoods')['草稿']
                                                                :
                                                                item?.productDetail?.productStatus === 4 ?
                                                                    intl.get('MyGoods')['已删除'] : ''
                                                }</span>
                                            </div>
                                            <div style={{ width: '60px',color:'#F7385B' }}>
                                                { item?.productDetail?.productStatus !== 4 ? <div style={{cursor:'pointer'}} onClick={()=>{copyItemData(item)}}>复制</div> : ''}
                                                <div style={{cursor:'pointer', color:'#1B9EFF'}} onClick={()=>{gotoDetail(item?.productDetail?.productId)}}>编辑</div>
                                                { item?.productDetail?.productStatus !== 4 ?
                                                    <Popconfirm
                                                        title="确认删除当前商品?"
                                                        onConfirm={()=>{productMstatus([item?.productDetail?.productId], 4)}}
                                                        okText="确定"
                                                        cancelText="取消">
                                                        <div style={{cursor:'pointer'}}>删除</div>
                                                    </Popconfirm>
                                                    : ''}
                                           </div>
                                        </div>
                                    )
                                })
                                :
                                <div className='flex-acjc' style={{ width: '100%', flexDirection: 'column', paddingTop: '100px' }}>
                                    <Image width={105} height={100} preview={false} src='https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/none1.png' alt='' />
                                    <div style={{ marginTop: '23px' }}>暂无数据</div>
                                </div>
                        }
                    </div>
                </Checkbox.Group>
            </Spin>
            <Pagination style={{ float: 'right', margin: '20px 0' }} current={pageIndex} total={total} showTotal={(total) => `共 ${total} 条`} onChange={e => { setPageIndex(e) }} />
        </div>
    )
}
export default withRouter(MyGoods);
