import React, { useState, useEffect } from 'react'
import withRouter from '../../utils/withRouter'
import { Image, Input, Checkbox, Select, Spin, notification, Form, Button } from 'antd';
import intl from 'react-intl-universal';
import store from "../../utils/redux";
const { Option } = Select;

const Login = (props)=>{
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);
    useEffect(()=>{
        console.log(666);
    },[])

    const imgUrl = 'https://zc-tk.oss-cn-beijing.aliyuncs.com/front'


    return <div style={{width:'100%'}}>
        <nav style={{width:'100%', position:'relative'}}>
            <img style={{width:'100%', height:'calc(100vh - 66px)'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/homeBack.jpg'} />
            {/* <img style={{width:'100%', height:'calc(100vh - 66px)'}} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/homeMian.png'} /> */}
            <div style={{width:'100%', height:'calc(100vh - 66px)', position:'absolute', top:'0', left: '0', 
            textAlign:'center', paddingTop:'20vh'}}>
                <h1 style={{color: 'white', fontSize:'60px', lineHeight:'58px'}}>跨境兴趣电商供应链服务平台</h1>
                <div style={{display:'flex', justifyContent:'center', marginTop:'80px'}}>
                    <div style={{boxShadow: '0px 0px 10px 0px #111111', borderRadius:'6px', fontSize:'16px',fontFamily: 'PingFang SC',
                        width:'150px', height:'50px', background:'rgba(255,255,255,0.5)', color:'#FFF', fontWeight:'500',
                        cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}
                         onClick={()=>props.navigate('/login')}>
                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shangjia.png" alt=""
                             style={{width:'25px', marginRight:'9px'}}/> 商家登录
                    </div>
                    <div style={{width:'66px'}}></div>
                    <div style={{boxShadow: '0px 0px 10px 0px #111111', borderRadius:'6px', fontSize:'16px',fontFamily: 'PingFang SC',
                        width:'150px', height:'50px', background:'rgba(255,255,255,0.5)', color:'#FFF', fontWeight:'500',
                        cursor:'pointer', display:'flex', alignItems:'center', justifyContent:'center'}}
                         onClick={()=>window.open('https://master.brandgogo.com/login')}>
                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/daren.png" alt=""
                             style={{width:'29px', marginRight:'7px'}}/> 达人登录
                    </div>
                </div>
                <img style={{width:'308px', marginTop:'26vh'}} src={imgUrl+'/img/logo.png'} />
                <p style={{color:'white', fontWeight: 500, fontSize:'16px', marginTop:'5px'}}>链接优质创作者，帮助中国品牌货通全球</p>
            </div>
        </nav>
    </div>
}
export default withRouter(Login);
