import React, { useState, useEffect, Suspense } from 'react'
import { Link, NavLink, Outlet, Route } from "react-router-dom";
import withRouter from '../../utils/withRouter'
import { Menu, Select, Spin, message, BackTop, Image, Popover, Modal, Button } from 'antd';
import { CaretDownOutlined, HomeOutlined, DiffOutlined, EditOutlined } from '@ant-design/icons';
import store from '../../utils/redux/index'
import { debounce } from '../../utils/debounce'
import Language from '../../components/Language';
import intl from 'react-intl-universal';
import * as Api from '../../service/https'
import '../../asset/css/index.scss'

const { SubMenu } = Menu;

const { Option } = Select;


const Index = (props) => {
    const lang = localStorage.getItem('locale') || localStorage.getItem("defaultLng") ||  'zh'
    const [textJson, setTextJson] = useState(intl.get('ReleaseDemand'))
    const [textJsonH, setTextJsonH] = useState(intl.get('Header'))
    const [state, setSate] = useState(store.getState());
    //监听store发生改变（订阅！！）
    const storeChange = () => {
        setSate(store.getState())
    };
    store.subscribe(storeChange);

    useEffect(() => {
        // console.log(props)
        // console.log(props.location.pathname.split('/')[2])
        setCurrentHover(props.location.state)
        setCurrent(props.location.state)
    }, [])
    const location = props.location;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const [collapsed, changeCollapsed] = useState(false)

    const toggleCollapsed = () => {
        changeCollapsed(!collapsed)
    };
    useEffect(() => {
        var action = {
            type: 'collapsed',
            value: collapsed ? 1 : 0
        }
        store.dispatch(action)
    }, [collapsed])

    const [currentsHover, setCurrentHover] = useState(props.location.state) //鼠标移入切换图片
    const [currents, setCurrent] = useState(props.location.state) //选中切换图片
    const urlList = [
        {
            url: '/workbench', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/home.png',
            icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/homes.png', title: '首页'
        },
        {
            url: '/products', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/good.png',
            icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/goods.png', title: '商品',
            children: [
                { url: '/MyGoods', title: '管理店铺商品' },
                { url: '/releaseProducts', title: '新增商品' },
                { url: '/goodsComment', title: '商品评价' },
                // { url: '/releaseProducts', title: '库存管理' },
                // { url: '/releaseProducts', title: '批量工具' },
            ]
        },
        {
            url: '/order', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/order.png',
            icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/orders.png', title: '订单',
            children: [
                { url: '/myOrder', title: '订单管理' },
            ]
        },
        {
            url: '/data', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/fenxi.png',
            icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/fenxis.png', title: '数据分析',
            children: [
                { url: '/dataOverview', title: '数据概览' },
                // { url: '/MyGoods', title: '订单分布' },
            ]
        },
        {
            url: '/shopWallets', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/caiwu.png',
            icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/caiwus.png', title: '财务结算',
            children: [
                { url: '/shopWallet', title: '账单' },
                { url: '/withdrawal', title: '提现' },
            ]
        },
        {
            url: '/p', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/myShop.png',
            icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/myShops.png', title: '我的店铺',
            children: [
                { url: '/shopAccount', title: '店铺资料' },
                { url: '/subAccount', title: '子账号管理' },
                { url: '/shopLogs', title: '店铺日志' },
                { url: '/shopInquiry', title: '询盘管理' },
            ]
        },
        // {
        //     url: '/products', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/xuexi.png',
        //     icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/xuexis.png', title: '学习中心',
        //     children: [
        //         { url: '/MyGoods', title: '常见问题' },
        //         { url: '/releaseProducts', title: '公告' },
        //     ]
        // },
        // {
        //     url: '/products', icon: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/phone.png',
        //     icons: 'https://zc-tk.oss-cn-beijing.aliyuncs.com/SupplyChain/shopHome/phones.png', title: '联系平台运营',
        // },
    ]

    const [isShowS, setIsShowS] = useState(true)
    const [selectedKeys, setselectedKeys] = useState([`/${props.location.pathname.split('/')[2]}`])
    const goto = (item, parent = false) => {
        setCurrentHover(parent.url || item.url)
        setselectedKeys([item.url])
        props.navigate('/index' + item.url, { state: parent.url || item.url })
        // setIsShowS(item.url == '/EnterpriseAccount' ? false : true)
    };
    // 其它方法跳转左侧导航选中
    useEffect(() => {
        if (state.changeRouter) {
            // console.log(state.changeRouter)
            goto({ url: state.changeRouter?.split('&&')[0] }, {url: state.changeRouter?.split('&&')[1]})
            store.dispatch({ type: 'changeRouter', value: null })
        }
    }, [state.changeRouter])

    const scrollTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }



    const [leaderboardCategoryLabel, setLeaderboardCategoryLabel] = useState('1')
    const changeSele = value => {
        setLeaderboardCategoryLabel(value)
    };

    const [fetching, setFetching] = useState(false)
    const [valueSearch, setValueSearch] = useState(null)
    const [searchList, setSearchList] = useState([])
    const handleSearch = debounce((value) => {
        setValueSearch(value == '' ? null : value)
    }, 500)

    useEffect(() => {
        if (valueSearch) {
            getSearch()
        } else {
            setSearchList([])
        }
    }, [valueSearch])
    const getSearch = () => {
        setFetching(true)
        Api.getSearch({ keyword: valueSearch, leaderboardCategoryLabel: leaderboardCategoryLabel }).then(res => {
            setFetching(false)
            if (res.code == 200) {
                setSearchList(res.data.data)
            }
        })
    }
    const changeSearch = (index) => {
        let item = searchList[index]
        if (leaderboardCategoryLabel == '1') {
            window.open(`/index/bloggerDetail/${item.uniqueId}`)
        }else if (leaderboardCategoryLabel == '2') {
            window.open(`/index/videoDetail/${item.video.id}/${item.author}`)
        }else if (leaderboardCategoryLabel == '3') {
            window.open(`/index/TopicDetail/${item.id}/${item.title}`)
        }else if (leaderboardCategoryLabel == '4') {
            window.open(`/index/GoodsRankingDetail/${item.id}/${item.productId}`)
        }else if (leaderboardCategoryLabel == '5') {
            window.open(`/index/SmallShopDetail/${item.id}`)
        }else if (leaderboardCategoryLabel == '6') {
            window.open(`/index/MusicRankingDetail/${item.id}`)
        }
        setValueSearch(null)
    }

    const [showWx, setShowWx] = useState(false)
    const [showPhone, setShowPhone] = useState(false)
    const [showEmial, setShowEmail] = useState(false)

    const searchTypeObj = {
        1:'博主',
        2:'视频',
        3:'话题',
        4:'商品',
        5:'小店',
        6:'音乐'
    }

    return <div style={{ width: '100%', minHeight: '100vh', paddingTop: '82px', backgroundColor: '#F6F6F6', }}>
        <div style={{
            width: '100%', height: '66px', position: 'fixed',
            top: 0, left: 0, backgroundColor: '#333333', boxShadow: '0px 0px 10px 0px #F8F8F8', color: '#FFF',
            zIndex: 3000
        }}>
            <div style={{width:'1400px', height:'100%', margin:'0 auto', display: 'flex',justifyContent: 'space-between',alignItems: 'center', }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div onClick={() => props.navigate('/')} style={{ cursor: 'pointer' }}>
                        <img style={{ width: '210px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/logo.png'} alt="" />
                    </div>
                    <div style={{
                        height: '30px', paddingLeft: '37px', display: 'flex',
                        alignItems: 'center'
                    }}>
                        <span style={{ fontWeight: 500 }}>
                            商家管理中心
                        </span>
                        <span style={{ marginLeft: '80px', fontWeight: 600, fontSize:'16px', cursor: 'pointer' }}>
                            {/*学习中心*/}
                        </span>
                    </div>

                </div>

                <Language />
            </div>

        </div>
        <div style={{width:'1400px', margin:'0 auto', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: collapsed ? '79px' : '206px' }}>
                <div id='menus' style={{
                    width: '206px', transition: '0.3s width', paddingBottom: '120px', zIndex: '100'
                }}>

                    <Menu
                        defaultSelectedKeys={[`/${props.location.pathname.split('/')[2]}`]}
                        defaultOpenKeys={['/rangKing', '/order', '/my', '/shopWallets', '/p', '/data', '/products']}
                        mode="inline"
                        selectedKeys={selectedKeys}
                        inlineCollapsed={collapsed}
                        items={
                            urlList?.map((item, index) => {
                                return item.children ? {
                                    key: item.url,
                                    icon: <img src={currentsHover == item.url || currents == item.url ? item.icons : item.icon} style={{width:'16px'}} />,
                                    label: <div onMouseEnter={() => setCurrent(item.url)} onMouseLeave={() => setCurrent('')}>{item.title}</div>,
                                    children: item.children?.map((itemS, indexS) => {
                                        return {
                                            key: itemS.url,
                                            label: itemS.title,
                                            onClick: () => goto(itemS, item)
                                        }
                                    })
                                } : {
                                    key: item.url,
                                    icon: <img src={currentsHover == item.url || currents == item.url ? item.icons : item.icon} style={{width:'16px'}} />,
                                    label: <div onMouseEnter={() => setCurrent(item.url)} onMouseLeave={() => setCurrent('')}>{item.title}</div>,
                                    onClick: () => goto(item)
                                }
                            })
                        }
                    >
                    </Menu>
                </div>
            </div>
            <div style={{
                width: 'calc(100% - 206px - 12px)', transition: '0.3s width',
                minWidth: '1098px', position: 'relative'
            }}>
                <Suspense fallback={<div>Loading...</div>}>
                <Outlet />
                </Suspense>


                {/* <div style={{ width: '48px', position: 'fixed', right: '8px', bottom: '100px', textAlign: 'center' }}>
                    {lang == 'zh' ? <div>
                        <div>
                            <Popover
                                content={
                                    <div>
                                        <img src="https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/homeImg/BrandGoGOcode.png" style={{ width: 150, height: 150 }} alt="" />
                                    </div>
                                }
                                title=""
                                trigger="hover"
                                placement='left'
                                onVisibleChange={e => setShowWx(e)}
                            >
                                <div>
                                    {
                                        showWx ?
                                            <div style={{ marginLeft:'3px', color: '#fff', width: 42, height: 42, background: 'linear-gradient(180deg, #F7385B, #FF716E)', borderRadius: '2px', lineHeight: '42px', textAlign: 'center', fontSize: 12, cursor: 'pointer', marginTop: '6px' }}>微信号</div>
                                            :
                                            <img style={{ width: '42px', cursor: 'pointer', marginTop: '6px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/wx.png'} alt="" />

                                    }
                                </div>
                            </Popover>
                        </div>
                        <div>
                            <Popover
                                content={<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>服务电话</div>
                                    <div>（工作日9:00-18:00）</div>
                                    <div style={{ color: '#F7385B', fontSize: '16', fontWeight: '600' }}>15531089395</div>
                                </div>}
                                title=""
                                trigger="hover"
                                placement='left'
                                onVisibleChange={e => setShowPhone(e)}
                            >
                                <div>
                                    {
                                        showPhone ?
                                            <div style={{ marginLeft:'3px', color: '#fff', width: 42, height: 42, background: 'linear-gradient(180deg, #F7385B, #FF716E)', borderRadius: '2px', fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '6px', cursor: 'pointer' }}>服务<br />电话</div>
                                            :
                                            <img style={{ width: '42px', cursor: 'pointer', marginTop: '6px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/dh.png'} alt="" />
                                    }
                                </div>
                            </Popover>
                        </div>
                    </div> :
                    <div>
                        <div>
                            <Popover
                                content={
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{fontWeight:800, fontSize:'17px'}}>Whatsapp</div>
                                        <div style={{ color: '#F7385B', fontSize: '16', fontWeight: '600' }}>+86 19951025256 </div>
                                        <div style={{ color: '#F7385B', fontSize: '16', fontWeight: '600' }}>+86 19906232856 </div>
                                    </div>
                                }
                                title=""
                                trigger="hover"
                                placement='left'
                                onVisibleChange={e => setShowWx(e)}
                            >
                                <div>
                                    <img style={{ width: '42px', cursor: 'pointer', marginTop: '6px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/homeImg/whatsapp.png'} alt="" />
                                </div>
                            </Popover>
                        </div>
                        <div>
                            <Popover
                                content={<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{fontWeight:800, fontSize:'17px'}}>Email</div>
                                    <div style={{ color: '#F7385B', fontSize: '16', fontWeight: '600' }}> jerelinn@brandgogo.com</div>
                                    <div style={{ color: '#F7385B', fontSize: '16', fontWeight: '600' }}> chenjiayi@brandgogo.com</div>
                                </div>}
                                title=""
                                trigger="hover"
                                placement='left'
                                onVisibleChange={e => setShowPhone(e)}
                            >
                                <div>
                                    <img style={{ width: '42px', cursor: 'pointer', marginTop: '6px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/homeImg/email.png'} alt="" />
                                </div>
                            </Popover>
                        </div>
                    </div>}
                    <img style={{ width: '48px', cursor: 'pointer', marginTop: '2px', marginRight:'12px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/top.png'} alt="" />
                    <BackTop visibilityHeight={0} style={{ right: '16px', bottom: '110px' }}>
                        <img style={{ width: '48px', cursor: 'pointer', marginTop: '2px' }} src={'https://zc-tk.oss-cn-beijing.aliyuncs.com/front/img/top.png'} alt="" />
                    </BackTop>
                </div> */}

            </div>
        </div>


    </div>
}
export default withRouter(Index);
